import { react2angular } from "react2angular";
import { s3 } from "fine-uploader/lib/s3";
import { IStateParamsService, IStateService } from "angular-ui-router";
import { getService } from "react-in-angularjs";

import { withReduxProvider } from "../../services/withReduxProvider";
import config from "../../../app/configuration";
import { useAppSelector } from "../../hooks/useAppSelector";
import { isFetchBaseQueryError, useGetCollectionQuery, useGetCollectionsMetaQuery, useGetCollectionsQuery, useSaveCollectionMutation } from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { CollectionType, CollectionTypes } from "../../types/CollectionTypes";
import { ICollection } from "../../types/ICollection";
import { Checkbox } from "../../design/Checkbox";
import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import { FoundUploadItems } from "fine-uploader/lib/core";
import { Button } from "../../design/Button";
import { SqlEditor } from "./SqlEditor";
import { IndexesCreator } from "./IndexesCreator";
import { SmallLoadingIndicator } from "../../design/SmallLoadingIndicator";
import { SaveAsMasterModal } from "./SaveAsMasterModal";
import { OverwriteMergeModal } from "./OverwriteMergeModal";
import { useDynamicFormik } from "../../hooks/useDynamicFormik";
import { baseConfig } from "../../utils/fineUploader";
import { validationSchema } from "../../data/collection";

const userDefinedPropertyTypes = [
  { label: "Text", type: "text" },
  { label: "Integer", type: "int8" },
  { label: "Decimal", type: "numeric" },
];

export const CollectionsCreateEdit = () => {
  const $stateParams = getService("$stateParams") as IStateParamsService;

  const initialCollectionValues = {
    name: "",
    type: CollectionType.Master,
    refreshEnabled: false,
  };

  const { data: collection, error: collectionError } = useGetCollectionQuery({ ID: $stateParams.collectionID }, { skip: !$stateParams.collectionID });

  if ($stateParams.collectionID && !collection) {
    if (collectionError) {
      return <div>{JSON.stringify(collectionError)}</div>;
    } else {
      return <div id="collection-create">Loading...</div>;
    }
  }

  return <CollectionsForm collection={collection || initialCollectionValues} />;
};

export const AngularCollectionsCreateEdit = react2angular(withReduxProvider(CollectionsCreateEdit));

const CollectionsForm = ({ collection }: { collection: Partial<ICollection> }) => {
  const $stateParams = getService("$stateParams") as IStateParamsService;
  const $state = getService("$state") as IStateService;

  const indexesError = "";

  const [saveCollection, { isLoading: isSavingCollection }] = useSaveCollectionMutation();

  const [errorMsg, setErrorMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [prettyProgress, setPrettyProgress] = useState("");
  const [showMoreFiles, setShowMoreFiles] = useState(false);
  const [showAggregates, setShowAggregates] = useState(false);
  const [uploads, setUploads] = useState<Record<string, string>>({});

  const [shouldMerge, setShouldMerge] = useState<boolean>();

  const [isSaveAsMasterOpen, setIsSaveAsMasterOpen] = useState(false);
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);

  const areInProgressFiles = useMemo(() => Object.values(uploads).some((s) => ["uploading", "retrying upload", "queued"].includes(s)), [uploads]);

  const initialValues: Partial<ICollection> & { files?: FileList } = { ...collection, properties: collection.properties?.filter((p) => !p.system) };
  const { entityID } = useAppSelector((state) => state.app);

  const { data: { fileTypesForHumans, acceptFiles } = {} } = useGetCollectionsMetaQuery();
  const { data: collections } = useGetCollectionsQuery();

  // show aggregates if the collection has an aggSql
  useEffect(() => {
    if (collection?.aggSql) {
      setShowAggregates(true);
    }
  }, [collection]);

  const { values, errors, isSubmitting, isValid, handleChange, handleSubmit, setFieldValue } = useDynamicFormik({
    initialValues,
    validationSchema: validationSchema(collections ?? [], collection),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.files?.length) {
        if (collection.ID && typeof shouldMerge === "undefined") {
          setIsMergeDialogOpen(true);
          return;
        }
        const fileIDs: string[] = [];
        const uploader: s3.FineUploaderBasic = new s3.FineUploaderBasic({
          ...baseConfig,
          callbacks: {
            onStatusChange: (id, oldStatus, newStatus) => {
              setUploads((prev) => ({ ...prev, [id]: newStatus }));
            },
            onError: (_id: unknown, _name: unknown, errorReason: string) => setErrorMsg(errorReason),
            onComplete: (_id: unknown, _name: unknown, response: { success?: boolean; error?: string; data: { ID: string } }) => {
              if (response.success) {
                setErrorMsg("");
                fileIDs.push(response.data.ID);
              } else {
                setErrorMsg(response.error ?? "");
              }
            },
            onSubmitted: () => {
              const netUploads = uploader.getNetUploads();
              const uploads = uploader.getUploads();
              setUploadComplete(netUploads > 0 && netUploads === (Array.isArray(uploads) ? uploads.length : 1));
            },
            onTotalProgress: (totalUploadedBytes: number, totalBytes: number) => {
              const rawProgress = totalUploadedBytes / totalBytes;
              setProgress(rawProgress);
              setPrettyProgress(numeral(rawProgress).format("0.0%"));
            },
            onAllComplete: async () => {
              if (values.type === CollectionType.Master) {
                if (!collection.ID && uploader.getNetUploads() === 0) {
                  return;
                }
                const uploads: FoundUploadItems[] = Array.isArray(uploader.getUploads())
                  ? (uploader.getUploads() as FoundUploadItems[])
                  : [uploader.getUploads() as FoundUploadItems];

                if (values.files && values.files?.length > 0 && uploader.getNetUploads() !== uploads.filter((u) => u.status === "upload successful").length) {
                  return;
                }
              }

              setErrorMsg("");
              try {
                await saveCollection({ ...values, merge: shouldMerge, files: fileIDs }).unwrap();
                $state.go("data");
              } catch (e) {
                if (isFetchBaseQueryError(e)) {
                  setErrorMsg(((e.data ?? {}) as { status?: { message?: string } }).status?.message ?? "An error occurred");
                } else {
                  setErrorMsg("An error occurred");
                }
              }
            },
          },
          validation: {
            acceptFiles: acceptFiles,
          },
          signature: {
            version: 4,
            endpoint: `${config.API_ROOT}/mapping/collections/upload/sign?entityID=${entityID}`,
          },
          uploadSuccess: {
            endpoint: `${config.API_ROOT}/mapping/collections/upload?entityID=${entityID}`,
          },
          objectProperties: {
            region: config.S3_REGION,
            key: function (id) {
              let directory = `entity_${entityID}/mapping/collections/`;

              return directory + uploader.getUuid(id) + `_` + uploader.getName(id);
            },
          },
        });

        uploader.addFiles(values.files);
        setSubmitting(false);
      } else {
        try {
          await saveCollection({ ...values, files: undefined }).unwrap();
          $state.go("data");
        } catch (e) {
          if (isFetchBaseQueryError(e)) {
            setErrorMsg(((e.data ?? {}) as { status?: { message?: string } }).status?.message ?? "An error occurred");
          } else {
            setErrorMsg("An error occurred");
          }
        }
      }
    },
  });

  const close = () => $state.go("data");

  const cancelFiles = () => {
    setFieldValue("files", undefined);
    setUploadComplete(false);
    setProgress(0);
    setPrettyProgress("");
    setShowMoreFiles(false);
    setErrorMsg("");
  };

  return (
    <div id="collection-create">
      {$stateParams.collectionID ? <h3 title={`Collection ID: ${collection?.ID}`}>Edit '{collection?.name}'</h3> : <h3>Create Collection</h3>}
      <form className="form-horizontal" name="create" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="name">
            Name:
          </label>
          <input
            className={classNameMapper({ invalid: Boolean(errors.name) }, "col-md-6")}
            type="text"
            placeholder="Collection Name"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
          {errors.name && <span className="text-danger">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="type">
            Type:
          </label>
          <div className="select-holder">
            <select
              name="type"
              value={values.type}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("refreshEnabled", false);
                setFieldValue("indexes", []);
              }}
              disabled={Boolean(collection?.ID)}
            >
              {CollectionTypes.map(({ type, name }) => (
                <option key={type} value={type}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {values.type === "transform" && (
            <span>
              <Checkbox name="refreshEnabled" id="refreshEnabled" checked={values.refreshEnabled} onChange={handleChange} label="Automatically Refresh" />
            </span>
          )}
        </div>
        {values.type === "master" && (
          <section>
            <div className="form-group">
              <fieldset>
                <legend>Files</legend>
                <div className="filenames">
                  <p className="text-danger error-message">{errorMsg}</p>
                  {prettyProgress && <p>{prettyProgress}</p>}
                  {(!values.files || values.files?.length === 0) && (
                    <>
                      <p>[no file(s) selected]</p>
                      <p>Supported file types:</p>
                      <ul>
                        {fileTypesForHumans?.map((fileType, i) => (
                          <li key={`file-type-${i}`}>{fileType}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {values.files && (
                    <>
                      {values.files.length > 0 &&
                        Array.from(values.files)
                          ?.slice(0, 2) // only show the first two here
                          .map((file, i) => <p key={`file-${i}`}>{file.name}</p>)}

                      {values.files?.length >= 3 && !showMoreFiles && (
                        <p className="show-more" onClick={() => setShowMoreFiles(true)}>
                          show {values.files?.length - 2} more...
                        </p>
                      )}
                      {values.files?.length >= 3 && showMoreFiles && (
                        <p className="show-more" onClick={() => setShowMoreFiles(false)}>
                          show less...
                        </p>
                      )}
                      {showMoreFiles &&
                        values.files.length > 0 &&
                        Array.from(values.files)
                          ?.slice(2)
                          .map((file, i) => <p key={`file-show-more-${i}`}>{file.name}</p>)}
                    </>
                  )}
                  {uploadComplete && <span className="text-success">File uploaded</span>}
                </div>
                {values.files?.length && <span className="clear-files btn del" onClick={cancelFiles} title={`Clear File${values.files.length > 1 ? "s" : ""}`} />}
              </fieldset>
              <div className="file-controls">
                {areInProgressFiles || (Object.keys(uploads).length && isSubmitting) || isSavingCollection ? (
                  <>
                    <p>{progress !== 1 ? "Uploading..." : "Processing..."}</p>
                    <div className={classNameMapper({ "progress-striped active": progress === 1 }, "progress")}>
                      <div
                        className={classNameMapper({ "progress-bar-success": progress === 1 }, "progress-bar")}
                        role="progressbar"
                        style={{ minWidth: "0px", width: `${progress * 100}%` }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {values.files?.length && <span className="btn orange disabled">{collection.ID ? "Select New File" : "Select File"}</span>}
                {(!values.files || values.files?.length === 0) && (
                  <span className="btn orange">
                    <span style={{ cursor: "pointer" }}>{collection.ID ? "Select New File" : "Select File"}</span>
                    <input
                      accept={acceptFiles?.join(", ")}
                      type="file"
                      multiple
                      title="Select a collection file to upload"
                      onChange={(e) => setFieldValue("files", e.target.files)}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                )}
                {errors.files && <span className="text-danger">{errors.files}</span>}
              </div>
            </div>
          </section>
        )}
        {values.type === CollectionType.Writable && (
          <section className="user-defined-properties">
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="srid">
                SRID:
              </label>
              <input className="col-md-6" type="number" placeholder="SRID (integer)" name="srid" value={collection.srid} onChange={handleChange} list="srid-suggestions" />
              <datalist id="srid-suggestions">
                <option value="2163" label="2163: US National Atlas Equal Area. Unit: Meter (IOGP deprecated)"></option>
                <option value="2784" label="2784: NAD83(HARN) / Hawaii zone 3. Unit: Meter"></option>
                <option value="2784" label="3857: Web Mercator. Unit: Meter"></option>
                <option value="4269" label="4269: NAD83. Unit: Degree"></option>
                <option value="4326" label="4326: WGS 84 / World Geodetic System 1984. Unit: Degree"></option>
                <option value="9311" label="9311: NAD27 / US Equal Area. Unit: Meter (Replaces 2163)"></option>
              </datalist>
            </div>
            <div className="form-group">
              <h4>Extra Properties</h4>

              {(!values.properties || values.properties?.length === 0) && <p>No User Defined Properties</p>}
              {values.properties?.map((property, i) => (
                <div className="property" key={i} title={property.name}>
                  <input
                    type="text"
                    value={property.name ?? ""}
                    name={`properties.${i}.name`}
                    onChange={handleChange}
                    placeholder="Property name"
                    title="User facing name for the property"
                  />
                  <div className="select-holder">
                    <select value={property.type} name={`properties.${i}.type`} onChange={handleChange} disabled={Boolean(collection?.ID) && !!property.column}>
                      {userDefinedPropertyTypes.map((type) => (
                        <option key={type.type} value={type.type}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="property-options">
                    <div className="property-option">
                      <Checkbox
                        checked={property.required ?? false}
                        label="Required"
                        id={`properties.${i}.required`}
                        name={`properties.${i}.required`}
                        onChange={(e) => setFieldValue(`properties.${i}.required`, e.target.checked)}
                        title="If true, the property is marked as required for map actions"
                      />
                    </div>
                    <div className="property-option">
                      <Checkbox
                        checked={property.hidden ?? false}
                        label="Hidden"
                        id={`properties.${i}.hidden`}
                        name={`properties.${i}.hidden`}
                        onChange={(e) => setFieldValue(`properties.${i}.hidden`, e.target.checked)}
                        title="If true, the property should be hidden from the user"
                      />
                    </div>
                    <div className="property-option">
                      <Checkbox
                        checked={property.protected ?? false}
                        label="Protected"
                        id={`properties.${i}.protected`}
                        name={`properties.${i}.protected`}
                        onChange={(e) => setFieldValue(`properties.${i}.protected`, e.target.checked)}
                        title="If true, users will not be able to edit this property"
                      />
                    </div>
                  </div>
                  <input type="text" value={property.column} disabled placeholder="Property column" title="Name of the column for the property in the database" />
                  <button
                    className="btn del"
                    onClick={() =>
                      setFieldValue(
                        "properties",
                        values.properties?.filter((_, j) => j !== i)
                      )
                    }
                    title="Delete the property. Warning: all maps using this collection will be broken until a browser refresh."
                  ></button>
                  {errors.properties && errors.properties[i] && (
                    <span className="text-danger">
                      {Object.values(errors.properties[i]).map((e) => (
                        <span>{e}</span>
                      ))}
                    </span>
                  )}
                </div>
              ))}
              <div className="add-property" onClick={() => setFieldValue("properties", [...(values.properties ?? []), { type: "text" }])}>
                <button className="btn add"></button>
                <span>Add Property</span>
              </div>
            </div>
          </section>
        )}
        {values.type && [CollectionType.Transformation, CollectionType.Dynamic].includes(values.type) && (
          <>
            <SqlEditor sql={values.sql ?? ""} onChange={(sql) => setFieldValue("sql", sql)} />
            {errorMsg || errors.sql ? <p className="text-danger error-message">{errorMsg || errors.sql}</p> : <></>}
          </>
        )}
        {values.type !== CollectionType.Master && <p className="text-danger error-message">{errorMsg}</p>}
        {values.type === CollectionType.Dynamic && (
          <div className="date-column form-group">
            <div className="col-md-3 control-label">
              <label htmlFor="dateColumn">Date Column:</label>
            </div>
            <div className="col-md-9">
              <input id="dateColumn" type="text" name="dateColumn" value={values.dateColumn ?? ""} onChange={handleChange} />
            </div>
          </div>
        )}
        <div className="text-danger">{indexesError}</div>
        {collection.ID && values.type != "dynamic" && (
          <IndexesCreator
            collection={collection as ICollection}
            indexes={values.indexes ?? []}
            onChange={(indexes) => {
              setFieldValue("indexes", indexes);
            }}
          />
        )}
        {collection.ID && values.type !== CollectionType.Writable && (
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="idColName">
              ID Column:
            </label>
            <div className="select-holder">
              <select name="idColName" id="idColName" value={values.idColName ?? ""} onChange={handleChange}>
                <option value="">None</option>
                {collection.properties?.map((prop) => (
                  <option key={prop.column} value={prop.column}>
                    {prop.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="col-md-3 control-label">
            <label htmlFor="noun">Noun (singular—for action messages):</label>
          </div>
          <div className="col-md-9">
            <input id="noun" type="text" name="noun" value={values.noun} onChange={handleChange} />
          </div>
        </div>
        <div>
          <Checkbox checked={showAggregates} onChange={() => setShowAggregates((v) => !v)} label="Add Aggregate Values" />
          {showAggregates && (
            <div className="aggregates">
              <SqlEditor sql={values.aggSql ?? ""} onChange={(sql) => setFieldValue("aggSql", sql)} />
            </div>
          )}
        </div>
        <div className="controls">
          <span className="btn plain" onClick={close}>
            Close
          </span>
          {collection.ID && values.type === CollectionType.Transformation && (
            <span className="btn" onClick={() => setIsSaveAsMasterOpen(true)}>
              Save as Master
            </span>
          )}
          <Button
            type="submit"
            className="btn orange"
            disabled={isSubmitting || !isValid || areInProgressFiles || (Object.keys(uploads).length > 0 && isSubmitting) || isSavingCollection}
          >
            <span>{(collection?.ID ? "Updat" : "Creat") + (isSubmitting ? "ing" : "e")}</span>
            {isSubmitting && <SmallLoadingIndicator />}
          </Button>
        </div>
        {collection.ID && isSaveAsMasterOpen && <SaveAsMasterModal collectionID={collection.ID} onClose={() => setIsSaveAsMasterOpen(false)} />}
        {collection.ID && isMergeDialogOpen && (
          <OverwriteMergeModal
            onCancel={() => setIsMergeDialogOpen(false)}
            onClose={(merge) => {
              setShouldMerge(merge);
              setIsMergeDialogOpen(false);
              handleSubmit();
            }}
          />
        )}
      </form>
    </div>
  );
};
