/**
 * Directive for the right sliding panel
 */

angular.module("common").directive("rightWrap", function ($state, $rootScope, $timeout, $window) {
  return {
    restrict: "AEC",
    replace: true,
    transclude: true,
    templateUrl: "/directives/epi-right.html",
    link: function (scope, element, attrs) {
      var currentState;

      // Keep track of what is currently being animated
      var animate = { panel: false, left: false };

      if (!rightInState($state.current)) element.hide();

      var leftSide = element.prev("[left-side]");

      scope.$watch(attrs.show, function epiRightWatchShow(value, oldVal) {
        showHide(null, $state.current);
      });

      /**
       * Checks to see if a state object has a right view
       * @param  {object} stateObject ui router $state object
       * @return {boolean}
       */
      function rightInState(stateObject) {
        if (!stateObject) return false;

        // First, check to make sure that there *is* a views object
        if ("views" in stateObject) {
          // The regex to test the key against
          var regex = /right(@)?/gi;

          // Loop through the keys & check them
          for (var view in stateObject.views) {
            // If it matches, return true
            if (view.match(regex)) {
              currentState = stateObject.name;
              return true;
            } else if ($state.includes(currentState)) {
              return true;
            }
          }
        }

        // If this executes, either there were no views, or the regex didn't match
        // So return false
        return false;
      }

      /**
       * Hides the panel
       * @param  {Object} element
       * @return {void}
       */
      function hidePanel(element) {
        animate.panel = true;
        $timeout(function () {
          element.hide("blind", {
            direction: "right",
            queue: false,
            complete: function () {
              animate.panel = false;
            },
          });
        });
      }

      /**
       * Shows the panel
       * @param  {Object} element
       * @return {void}
       */
      function showPanel(element) {
        element.css({ width: findRightWidth() });
        animate.panel = true;
        $timeout(function () {
          element.show("blind", {
            direction: "right",
            queue: false,
            complete: function () {
              animate.panel = false;
            },
          });
        });
      }

      /**
       * Finds the width that the right side should be
       * @return {integer}
       */
      function findRightWidth() {
        var viewChildren = element.children("[ui-view]").children(":first"),
          children = element.children(":first"),
          isSmall = $window.innerWidth <= 768,
          attr = isSmall ? "right-side-width-small" : "right-side-width";

        var width = viewChildren.attr(attr) || children.attr(attr);

        if (!width && isSmall) width = viewChildren.attr("right-side-width") || children.attr("right-side-width");

        return width;
      }

      /**
       * Squishes the left side element
       * @param  {object} element
       * @return {void}
       */
      function squishLeft(element) {
        var rightWidth = findRightWidth();
        if (!element || !rightWidth) return;

        rightWidth = rightWidth.replace("%", "");
        var leftWidth = 100 - rightWidth;

        animate.left = true;
        $timeout(function () {
          element.animate(
            { width: leftWidth + "%" },
            {
              queue: false,
              complete: function () {
                animate.left = false;
              },
            }
          );
        });
      }

      /**
       * Expands the left side element
       * @param  {object} element
       * @return {void}
       */
      function expandLeft(element) {
        if (!element) return;

        animate.left = true;
        $timeout(function () {
          element.animate(
            { width: "100%" },
            {
              queue: false,
              complete: function () {
                animate.left = false;
              },
            }
          );
        });
      }

      /**
       * Shows/hides the left/right side
       * @param  {object} event
       * @param  {object} toState
       * @param  {object} toParams
       * @param  {object} fromState
       * @param  {object} fromParams
       * @return {void}
       */
      function showHide(event, toState, toParams, fromState, fromParams) {
        // If the to state has the right view,
        // Or…the event is a state start, check to see if the fromState has the right view
        var stateHasRight = rightInState(toState) || (!_.isEmpty(event) && event.name == "$stateChangeStart" && rightInState(fromState));

        // If the panel shouldn't be open in this state,
        // Or the show attribute is truthy,
        if (stateHasRight || scope[attrs.show]) {
          // If the element is visible, return, since nothing needs to be done
          if (element.is(":visible")) return;

          // Only animate if they are not already being animated
          if (!animate.left) squishLeft(leftSide);

          if (!animate.panel) showPanel(element);
        } else {
          // Close everything and
          // only animate if they are not already being animated
          if (!animate.left) expandLeft(leftSide);

          if (!animate.panel) hidePanel(element);
        }
      }

      /**
       * Opens the right side
       * @return {void}
       */
      $rootScope.$on("$stateChangeSuccess", showHide);

      /**
       * Closes the right side
       * @return {void}
       */
      $rootScope.$on("$stateChangeStart", showHide);
    },
  };
});
