export default angular.module("configuration.restangular", ["restangular"]).config(function (RestangularProvider, API_ROOT) {
  // setup Restangular
  RestangularProvider.setBaseUrl(API_ROOT);
  RestangularProvider.setRestangularFields({
    id: "ID",
  });

  RestangularProvider.addFullRequestInterceptor(function (element, operation, route, url, headers, params) {
    if (operation == "remove") element = undefined;

    return {
      headers: headers,
      params: params,
      element: element,
      httpConfig: {},
    };
  });

  RestangularProvider.setResponseExtractor(function (response, operation, what, url) {
    var newResponse;
    if (operation == "getList") {
      newResponse = response.data;
      newResponse.meta = response.meta;
    } else newResponse = response.data;

    return newResponse;
  });

  RestangularProvider.setOnElemRestangularized(function (elem, isCollection, what, Restangular) {
    if (!isCollection) return elem;

    /**
     * find an item in a collection by id or other column
     * @param  {int} id
     * @param {string} column
     * @return {object|null}
     */
    elem.find = function (id, column) {
      if (!column) column = "ID";

      for (var i = 0; i < elem.length; ++i) {
        if (elem[i][column] == id) return elem[i];
      }

      return null;
    };

    /**
     * returns the index of a ID
     * @param  {int} id
     * @return {int|false}
     */
    elem.indexOfID = function (id) {
      for (var i = 0; i < elem.length; ++i) {
        if (elem[i].ID == id) return i;
      }

      return false;
    };

    return elem;
  });

  return;
}).name;
