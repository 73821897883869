/**
 * TruTerritory collections controller
 */

const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

angular.module("routes:data").controller("data:collections", function () {
  store.dispatch(
    updateGlobalState({
      title: "TruTerritory Collections",
      h1: "Data",
      activePage: "data",
    })
  );
});
