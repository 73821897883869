/**
 * @name HistorySlider
 * @memberOf routes:mapping
 * @ngdoc service
 * @description The Leaflet component behind the historySlider directive (for single point selection)
 * @see {@link routes_mapping.historySlider}
 * @returns {Function} a factory method to create a new instance
 */
angular.module("routes:mapping").factory("HistorySlider", function ($window, $compile, $rootScope, DateRange, Interval) {
  let L = $window.L;

  let HistorySlider = L.Control.extend({
    includes: L.Evented.prototype,
    options: {
      minDate: null,
      maxDate: null,
      range: {
        // this is the maximum range the slider can cover
        minDate: null,
        maxDate: null,
      },
      interval: null, // an integer representation of the interval
      mode: null, // 1 | 2
      position: "bottomleft",
    },
    intervals: Interval.getNouns(),
    presets: DateRange.getRanges(true),
    initialize(options) {
      if (options) L.setOptions(this, options);

      if (!this.options.interval) this.options.interval = 1;

      // Setup the slider
      this._setSliderMode(this.options.mode);
      this._createLabels();

      // If there's a map then it's okay to proceed with everything else
      if (this._map) {
        // Set step interval
        this._setSteps();

        // Update everything
        this._updateSlider();
        this._createPresets();

        // And fire change!
        // this._fireChange()
      }
    },

    onAdd(map) {
      // Create the divs to hold the slider, and the slider
      var div = L.DomUtil.create("div", "leaflet-control-history-slider");
      this.slider = L.DomUtil.create("div", "slider", div);

      // Disable the click propagation so that the slider events aren't caught by the map
      L.DomEvent.disableClickPropagation(this.slider);

      // Create a jQuery UI slider
      $(this.slider).dragslider({
        orientation: "horizontal",
        slide: (event, ui) => {
          let data = "values" in ui ? { minValue: ui.values[0], maxValue: ui.values[1] } : { minValue: ui.value };
          this._updateSlider(data);
        },

        // Only fire change on stop so that change isn't firing while sliding
        stop: (event, ui) => {
          // Set the preset selector to custom when the slider handles are moved
          this._setPresetDisplay("custom");

          this._fireChange();
        },
      });

      // Set the initial mode and labels
      this._setSliderMode(this.options.mode);
      this._createLabels();

      // After creating everything, return the div (which now contains all the built HTML), so that Leaflet can add it to the map
      return div;
    },

    /**
     * Fire a change event to anyone listening
     * @return {void}
     */
    _fireChange: function () {
      this.fire("change", { range: { minDate: this.options.minDate, maxDate: this.options.maxDate } });
    },

    /**
     * Update the slider with newly calculated dates & labels
     * @return {void}
     */
    _updateSlider(values) {
      // First get the actual slider values
      let { minValue, maxValue } = values || this._getSliderValues();

      // Set the min date
      this.options.minDate = this._calculateDate(this.options.range.minDate, minValue, "minDate");

      // If this is a two point slider, set it exactly like the min date
      if (this.options.mode == 2) this.options.maxDate = this._calculateDate(this.options.range.minDate, maxValue, "maxDate");
      // Else calculate the max date (for a one point slider)
      else this.options.maxDate = this._calculateMaxDate();

      // Update handle text
      let handles = this._getSliderHandles();
      for (let key in handles) {
        this._updateHandle(handles[key], this.options[key]);
      }
    },

    /**
     * Create and set the labels for each of the handles
     * @return {void}
     */
    _createLabels() {
      // Get the handles and loop over them
      let handles = this._getSliderHandles();
      for (let key in handles) {
        let label = this._createHandleLabel(this.options[key]);

        // Bind a double click handler to the label for the date picker
        label.dblclick((e) => {
          // Keep the events from propagating and cancel clicks as necessary
          e.stopPropagation();
          e.preventDefault();
          this._cancelClicks();

          // The date picker only needs to show up on daily sliders
          if (this.getInterval() == "day") this._onDatePopupToggle(e, key, this.options[key]);
          return false;
        });
        handles[key].html(label);
      }
    },

    /**
     * Switch the slider mode between one and two point
     * @param {void} mode integer either 1 or 2
     */
    _setSliderMode(mode) {
      if (mode == 1) {
        $(this.slider).dragslider("option", "range", false);
        $(this.slider).dragslider("option", "rangeDrag", false);
      } else if (mode == 2) {
        $(this.slider).dragslider("option", "range", true);
        $(this.slider).dragslider("option", "rangeDrag", true);
      }
    },

    /**
     * Get the string representation of the set interval
     * @return {string}
     */
    getInterval() {
      return this.intervals[this.options.interval];
    },

    /**
     * Get the slider values from the jQuery element
     * @return {object} {minValue: moment, [maxValue: moment]}
     */
    _getSliderValues() {
      let values = $(this.slider).dragslider("values");
      if (values.length) return { minValue: values[0], maxValue: values[1] };
      else return { minValue: $(this.slider).dragslider("value") };
    },

    /**
     * Get the slider handles as jQuery elements
     * @return {object} {minDate: moment, [maxDate: moment]}
     */
    _getSliderHandles() {
      let handles = $(this.slider).children(".ui-slider-handle");
      let data = { minDate: handles.eq(0) };
      if (handles.get(1)) data.maxDate = handles.eq(1);
      return data;
    },

    /**
     * Calculate a date, given a date, interval and type
     * @param  {moment}  date
     * @param  {integer} intervalCount the number of intervals to add to the starting date
     * @param  {string}  dateType      minDate or maxDate
     * @return {moment}
     */
    _calculateDate(date, intervalCount, dateType) {
      let interval = this.getInterval();

      let returnDate = date.clone().add(intervalCount, interval);

      if (dateType == "minDate") returnDate.startOf(interval);
      else if (dateType == "maxDate") returnDate.endOf(interval);

      return returnDate;
    },

    /**
     * Calculate the max date for a single point slider
     * @param  {integer} intervalCount the number of intervals to add to the starting date
     * @return {moment}
     */
    _calculateMaxDate(intervalCount) {
      return this.options.minDate.clone().endOf(this.getInterval());
    },

    /**
     * Set the step setting for the slider
     */
    _setSteps() {
      let interval = this.getInterval();

      // Put the min date at the beginning of the interval; the max at the end
      let periodStart = this.options.range.minDate.clone().startOf(interval);
      let periodEnd = this.options.range.maxDate.clone().endOf(interval);

      // Calculate the step setting for the slider
      let lastStep = periodEnd.diff(periodStart, interval);

      $(this.slider).dragslider("option", "step", 1);
      $(this.slider).dragslider("option", "min", 0);
      $(this.slider).dragslider("option", "max", lastStep);

      let min = this.options.minDate.diff(periodStart, interval),
        max = this.options.maxDate.diff(periodStart, interval);

      if (this.options.mode == 2) $(this.slider).dragslider("option", "values", [min, max]);
      else $(this.slider).dragslider("option", "value", min);
    },

    /**
     * Update a given handle with the correct text, tooltip and position
     * @param  {element} the handle element
     * @param  {moment} date
     * @return {void}
     */
    _updateHandle(element, date) {
      let label = element.children("label");
      label.text(this._getDisplayLabel(date));
      label.css(this._getLabelPosition(label));
      label.attr("title", this._getTooltipLabel(date));
    },

    /**
     * Create a handle label given an intial date (or not)
     * @param  {moment} [date=null]
     * @return {element}
     */
    _createHandleLabel(date = null) {
      // Create the label with the date formatted and set the html of the element
      return $(`<label title="${this._getTooltipLabel(date)}" >${this._getDisplayLabel(date)}</label>`);
    },

    /**
     * Returns a formatted tooltip label given a particular date
     * @param  {moment} [date=null]
     * @return {string} the formatted date
     */
    _getTooltipLabel(date = null) {
      if (!date) return "";

      let format = "ddd MMM Do, YYYY";

      if (this.options.mode == 1) return this.options.minDate.format(format) + " - " + this.options.maxDate.format(format) + ` (${Interval.getAdverbs()[this.options.interval]})`;
      else if (this.options.mode == 2) return date.format(format);
    },

    /**
     * Returns a formatted display label given a particular date
     * @param  {moment} [date=null]
     * @return {string} the formatted date
     */
    _getDisplayLabel(date = null) {
      if (!date) return "";

      if (this.options.mode === 2) return date.format("l");

      let format;

      switch (this.getInterval()) {
        case "day":
          format = "l";
          break;
        case "week":
          format = "[Week of] MM/DD/YYYY";
          break;
        case "month":
          format = "MMM YYYY";
          break;
        case "quarter":
          format = "[Q]Q YYYY";
          break;
        case "year":
          format = "YYYY";
          break;
      }

      return date.format(format);
    },

    /**
     * Calculates a position for a given label
     * @param  {element} label
     * @return {object} {left: <value>}
     */
    _getLabelPosition(label) {
      // Left side
      if (label.parent().is("span:first-child")) {
        return { left: -(label.width() + 20) };

        // Right side
      } else {
        return { left: 10 };
      }
    },

    /**
     * Creates the select for date presets
     * @param  {element} div
     * @return {void}
     */
    _createPresets: function () {
      $(this._container).children(".select-holder").remove();

      if (this.options.mode != 2 || this.options.interval > 3)
        // only show for monthly and smaller
        return;

      let selectHolder = $(L.DomUtil.create("div", "select-holder"));
      let htmlString = "<select>";

      let value = "custom";
      if (this._isAll()) value = "all";

      this.presets.forEach((preset) => {
        let { minDate, maxDate } = this._getPresetRange(preset.value);
        let legalRange = this.options.range;
        let isEnabled = minDate <= legalRange.maxDate && maxDate >= legalRange.minDate && minDate <= maxDate;

        if (preset.value == "all" || preset.value == "custom") isEnabled = true;

        if (minDate.format() == this.options.minDate.format() && maxDate.format() == this.options.maxDate.format()) value = preset.value;

        htmlString += `\n  <option value="${preset.value}" ${isEnabled ? "" : "disabled"}>${preset.name}</option>`;
      });

      htmlString += "\n</select>";
      selectHolder.html(htmlString);

      selectHolder.on("change", this._onPresetChange.bind(this));

      // Set the element on `this` so it's available
      this.presetSelect = selectHolder[0];
      this._setPresetDisplay(value);

      this._container.appendChild(selectHolder[0]);
    },

    /**
     * Bound to the preset selector and fires when its value changes
     * @param  {Event} event
     * @return {void}
     */
    _onPresetChange(event) {
      // If the preset is custom, there's nothing to do
      if (event.target.value == "custom") return;

      $(this.presetSelect).find("[value=custom]").removeAttr("selected", "selected");

      // Figure out which date range to work on
      let { minDate, maxDate } = event.target.value == "all" ? this.options.range : this._getPresetRange(event.target.value);
      let interval = this.getInterval();
      let periodStart = this.options.range.minDate.clone().startOf(interval);

      // Calculate the actual slider value for each date
      let minDateValue = minDate.diff(periodStart, interval);
      let maxDateValue = maxDate.diff(periodStart, interval);

      let sliderElem = $(this.slider);

      if (sliderElem.dragslider("values")) {
        sliderElem.dragslider("values", 0, minDateValue);
        sliderElem.dragslider("values", 1, maxDateValue);
      } else sliderElem.dragslider("value", minDateValue);

      this._updateSlider();
      this._fireChange();
    },

    /**
     * Gets a particular range for a preset value
     * @param  {string} range a preset string as defined by `DateRange.getRanges()`
     * @return {object} a range object, including minDate and maxDate as Moment objects, and the range string
     */
    _getPresetRange: function (range) {
      let { minDate, maxDate } = DateRange.getRange(range);

      // Moment-ify the dates before sending them out
      return { minDate: moment(minDate), maxDate: moment(maxDate), range };
    },

    /**
     * Check if selected dates match full legal range
     * @return {bool}
     */
    _isAll() {
      let interval = this.getInterval(),
        min = this.options.range.minDate.clone().startOf(interval),
        max = this.options.range.maxDate.clone().endOf(interval);

      return this.options.minDate.format() == min.format() && this.options.maxDate.format() == max.format();
    },

    /**
     * Set which preset is displayed in the HTML select
     * @param {string} value
     */
    _setPresetDisplay(value) {
      if (this.presetSelect) {
        this.presetSelect.value = value;
        $(this.presetSelect)
          .find("[value=" + value + "]")
          .attr("selected", "selected");
      }
    },

    /**
     * An array to hold the IDs of timeout handlers (used in `this._cancelClicks`)
     * @type {Array}
     */
    _clickCancelers: [],

    /**
     * Cancels any of the click timeouts contained in `this._clickCancelers
     * @return {void}
     */
    _cancelClicks: function () {
      // Loop through all of the clickCancelers, running `clearTimeout` on each of the IDs
      for (var i = 0; i < this._clickCancelers.length; i++) {
        clearTimeout(this._clickCancelers[i]);
      }

      // Reset everything
      this._clickCancelers = [];
    },

    /**
     * Triggered when double clicking to open the date popup
     * @param  {jQuery.Event} e
     * @return {void}
     */
    _onDatePopupToggle: function (e, key, value) {
      // Get the label and add the date popup to it
      let label = $(e.target);
      let slider = $(this.slider);
      label.parent().prepend(
        this._createDatePopup(moment(value), (date) => {
          let calculatedStep = date.diff(this.options.range.minDate, this.getInterval());

          if (slider.dragslider("values").length) {
            let index = key == "minDate" ? 0 : 1;
            slider.dragslider("values", index, calculatedStep);
          } else {
            slider.dragslider("value", calculatedStep);
          }

          this._updateSlider();
        })
      );
    },

    /**
     * Creates the date picker popup
     * @param  {Moment}   date a moment object
     * @param  {Function} cb   the callback to call when the date changes
     * @return {element}       the datepicker element
     */
    _createDatePopup: function (date, cb) {
      // Create a new isolate scope
      var scope = $rootScope.$new(true);

      // Add to the scope
      Object.assign(scope, {
        date: date.toDate(),
        minDate: this.options.range.minDate.toDate(),
        maxDate: this.options.range.maxDate.toDate(),
        dateDidChange: (date) => {
          cb(moment(date));
          element.remove();
        },
        closeDatePicker: () => {
          element.remove();
        },
      });

      // Compile and return the (now Angular-ized) element so it can be displayed
      var element = $compile(
        '<div uib-datepicker ng-model="date" min-date="minDate" max-date="maxDate" off-click="closeDatePicker()" ng-change="dateDidChange(date)"></uib-datepicker>'
      )(scope);
      return element;
    },
  });

  // Return a factory method that creates a new `HistorySlider`
  return function createHistorySlider(options) {
    return new HistorySlider(options);
  };
});
