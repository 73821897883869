import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, SortableContextProps, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ReactNode } from "react";

export const SortableUl = ({
  items,
  id,
  children,
  onDragEnd,
  onDragStart,
  className,
}: {
  items: SortableContextProps["items"];
  id: SortableContextProps["id"];
  onDragEnd?: (e: DragEndEvent) => void;
  onDragStart?: (e: DragStartEvent) => void;
  children?: ReactNode;
  className?: string;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={onDragStart} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
      <SortableContext items={items} strategy={verticalListSortingStrategy} id={id}>
        <ul className={className}>{children}</ul>
      </SortableContext>
    </DndContext>
  );
};
