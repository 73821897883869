import { useEffect, useRef } from "react";
import "spectrum-colorpicker";

export interface ISpectrumColorPickerOptions {
  showInput: boolean;
  showInitial: boolean;
  clickoutFiresChange: boolean;
  preferredFormat: "hex";
  allowEmpty: boolean;
  showAlpha: boolean;
}

export const SpectrumColorpicker = ({
  value,
  format = "hex",
  onChange,
  className,
  options,
}: {
  value: string;
  format?: "hex" | "rgb" | "hsv" | "hsl";

  /**
   * NOTE: THIS METHOD GETS THE CONTEXT CHANGED INSIDE THE SPECTRUM COLOR PICKER.
   *  IT WILL RESULT IN WEIRD BEHAVIOR, EVEN IF YOU USE BIND.
   */
  onChange?: (value: string) => void;
  className?: string;
  options?: ISpectrumColorPickerOptions;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    ($(inputRef.current) as any).spectrum({
      value,
      ...options,
      change: (color?: { toHexString: () => string; toRgbString: () => string; toHsvString: () => string; toHslString: () => string }) => {
        let colorString;
        if (!color) return onChange?.("");
        switch (format) {
          case "hex":
            colorString = color.toHexString();
            break;
          case "rgb":
            colorString = color.toRgbString();
            break;
          case "hsv":
            colorString = color.toHsvString();
            break;
          case "hsl":
            colorString = color.toHslString();
            break;
        }
        onChange?.(colorString);
      },
    });
  }, []);

  useEffect(() => {
    if (!inputRef.current) return;
    ($(inputRef.current) as any).spectrum("set", value);
  }, [value]);

  return (
    <span className={className ?? ""}>
      <input ref={inputRef} className="input-small" />
    </span>
  );
};
