import { IUser, IUserPut } from "../types/IUser";
import axios from "../utils/axios";

export function save({ ID, ...user }: IUserPut) {
  return axios.put(`/users/${ID}`, user);
}

export function logout() {
  return axios.get("/logout");
}
