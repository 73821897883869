/**
 * Directive for toggling sliding up/down
 */

angular.module("common").directive("slideAway", function ($parse, $timeout) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var callback = $parse(attrs.slideAwayCallback) || function () {};

      scope.$watch(attrs.slideAway, function (val, oldVal) {
        if (val == oldVal && !!val == false) {
          element.hide();
          return;
        }

        if (!!val == true) {
          $timeout(function () {
            element.slideDown({
              complete: function () {
                callback(scope, { $position: "down" });
              },
            });
          }, 0);
        } else {
          element.slideUp({
            complete: function () {
              callback(scope, { $position: "up" });
            },
          });
        }
      });
    },
  };
});
