"use strict";

import { bootstrap } from "../src/services/datadog";

import "./imports";

/*
|--------------------------------------------------------------------------
| Epiphany Analytics UI
|--------------------------------------------------------------------------
|
| This is where it's all at! All the important stuff happens here.
|
*/

// start datadog
bootstrap();

angular.module("app", [
  // Load the third-party libraries
  "ajoslin.promise-tracker",
  "angularSpectrumColorpicker",
  "checklist-model",
  "ngAnimate",
  "ngGeolocation",
  "ngProgressLite",
  "restangular",
  "ui.bootstrap",
  "ui.router",
  "ui.utils",
  "xeditable",

  require("./views").default,
  require("../src/components/index").default,
  require("../src/design/index").default,
  require("../src/pages/index").default,

  // Load the app-wide configuration
  require("./configuration/setup").default,
  require("./configuration/exception-handler").default,
  require("./configuration/http").default,
  require("./configuration/other").default,
  require("./configuration/progress-bar").default,
  require("./configuration/restangular").default,
  require("./configuration/storage").default,

  // Bootstrap the app
  require("./bootstrap/events").default,
  require("./bootstrap/google-analytics").default,
  require("./bootstrap/http").default,
  require("./bootstrap/menu").default,
  require("./bootstrap/progress-bar").default,
  require("./bootstrap/xeditable").default,
  require("./bootstrap/version").default,

  // Load all the routes, modules and common code
  // These are the important modules
  require("./common/modules").default,
  require("./common").default,
  require("./routes").default,
]);
