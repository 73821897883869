/**
 * Directive for creating filters (for searching)
 */

angular.module("common").directive("epiFiltersCreator", function ($parse) {
  return {
    restrict: "A",
    scope: {
      columns: "=",
      operators: "=",
      filters: "=",
      change: "&",
      showAdd: "=?",
    },
    replace: true,
    templateUrl: "/directives/epi-filters-creator.html",
    controller: function ($scope) {},
    link: function ($scope, element, attrs) {
      if (!$scope.change) $scope.change = function () {};

      if (typeof $scope.showAdd == "undefined") $scope.showAdd = true;

      for (var i = 0; $scope.filters && i < $scope.filters.length; i++) {
        var filter = $scope.filters[i];
        if (filter.value instanceof Array) {
          filter.value = filter.value.join(",");
        }
      }

      $scope.filterDidChange = function (filter) {
        if (("columnID" in filter && "operatorID" in filter && "value" in filter && filter.columnID !== "", filter.operatorID !== "" && filter.value !== "")) {
          $scope.change();
        }
      };

      $scope.addFilter = function () {
        $scope.filters.push({ operatorID: 1, columnID: $scope.columns[0].ID, value: "" });
      };

      $scope.removeFilter = function (index) {
        $scope.filters.splice(index, 1);
        $scope.change();
      };
    },
  };
});
