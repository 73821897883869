import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";
import { useEffect, useMemo } from "react";
import { IState } from "angular-ui-router";

import { useAppSelector } from "../../hooks/useAppSelector";
import { withReduxProvider } from "../../services/withReduxProvider";
import { useDeleteCollectionMutation, useGetCollectionsQuery, useUpdateCollectionMutation } from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { CollectionTypes } from "../../types/CollectionTypes";
import { API_ROOT } from "../../../app/configuration";
import { ICollection } from "../../types/ICollection";
import { ConfirmButton } from "../../components/ConfirmButton";
import { Button } from "../../design/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setConsoleCollection, setCurrentlyEditingCollection } from "../../slices/pagesSlice";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";

export const DataCollections = () => {
  const $state = getService("$state");
  const $rootScope = getService("$rootScope") as angular.IRootScopeService;
  const dispatch = useAppDispatch();

  const deregisterStateListener = $rootScope.$on("$stateChangeSuccess", (_e, toState: IState, toParams: Record<string, string>) => {
    if (toState.name === "data.edit") {
      dispatch(setCurrentlyEditingCollection(parseInt(toParams.collectionID)));
    } else {
      dispatch(setCurrentlyEditingCollection());
    }
  });

  useEffect(() => deregisterStateListener, []);

  const { selectedTypesFilter, consoleCollection, currentlyEditingCollection, showArchived, searchString } = useAppSelector((state) => state.pages.data);
  const { data: rawCollections, isFetching, error } = useGetCollectionsQuery();
  const [performDelete] = useDeleteCollectionMutation();
  const [performUpdate] = useUpdateCollectionMutation();

  const debouncedSearchString = useDebouncedValue(searchString);

  const collections = useMemo(
    () =>
      (rawCollections ?? [])
        .filter(({ name, type }) => {
          return selectedTypesFilter.includes(type) && (!debouncedSearchString || name.toLowerCase().match(debouncedSearchString.toLowerCase()));
        })
        .filter((c) => (showArchived ? true : !c.archived)),
    [selectedTypesFilter, debouncedSearchString, showArchived, rawCollections]
  );

  const getTypeName = (type: string) => CollectionTypes.find((t) => t.type === type)?.name;

  const deleteCollection = function (collection: ICollection) {
    performDelete(collection.ID);
  };

  const makeCollectionDataUrl = function (collection: ICollection, limit?: string, format?: string, download?: boolean) {
    if (!collection) {
      return null;
    }

    return (
      API_ROOT +
      "/mapping/collections/" +
      (collection.ID || "") +
      "/features?" +
      (limit ? "limit=" + limit : "") +
      "&entityID=" +
      (collection.entityID || "") +
      "&format=" +
      (format || "html") +
      (download ? "&download" : "")
    );
  };

  const makeCollectionExportUrl = function (collection: ICollection, format: string) {
    return `${API_ROOT}/mapping/collections/${collection.ID}/download?entityID=${collection.entityID}&format=${format}`;
  };

  const makeCollectionInfoUrl = function (collection: ICollection) {
    if (!collection) {
      return null;
    }

    return API_ROOT + "/mapping/collections/" + collection.ID + "?with=all&append=all&&entityID=" + collection.entityID;
  };

  const downloadCollectionData = function (collection: ICollection) {
    if (!collection) {
      return null;
    }

    var url = makeCollectionExportUrl(collection, "csv");

    if (!url) return;

    window.location.href = url;
  };

  const showCollectionData = function (collection: ICollection) {
    var url = makeCollectionDataUrl(collection, "50");

    if (!url) return;

    window.open(url, url, "dependent,toolbar,width=1250,height=675");
  };

  // Show up a pop-up window with the collection's info
  const showCollectionMetadata = function (collection: ICollection) {
    var url = makeCollectionInfoUrl(collection);

    if (!url) return;

    window.open(url, url, "dependent,toolbar,width=675,height=850");
  };

  /**
   * Toggles the data console open and closed
   *
   * @param collection
   */
  const toggleDataConsole = function (collection: ICollection) {
    if (collection.ID === consoleCollection?.ID) {
      dispatch(setConsoleCollection());
    } else {
      dispatch(setConsoleCollection(collection));
    }
  };

  const toggleArchived = (collection: ICollection) => {
    performUpdate({ ID: collection.ID, archived: !collection.archived });
  };

  const editCollection = function (collection: ICollection) {
    $state.go("data.edit", { collectionID: collection.ID }, { location: false });
  };

  function collectionClassName(collection: ICollection) {
    if (collection.type == "writable") {
      return "writable";
    }

    if (collection.geometryType) {
      return collection.geometryType;
    }

    return "data";
  }

  return (
    <div id="data-collections-wrap">
      {collections?.length === 0 && !isFetching && !error ? (
        debouncedSearchString ? (
          <h4 className="empty">Sorry, your search did not match any collections</h4>
        ) : (
          <h4 className="empty">You do not have any collections. Click "Add Collection" button on the on the right side to get started.</h4>
        )
      ) : error ? (
        <h4 className="empty">There was an error loading collections, please try again</h4>
      ) : (
        <table id="data-collections">
          <tbody>
            {collections.map((collection) => (
              <tr
                key={`collection-${collection.ID}`}
                id={`collection${collection.ID}`}
                className={classNameMapper({ archived: collection.archived, active: collection.ID === currentlyEditingCollection })}
              >
                <td colSpan={3} className={collectionClassName(collection)} title={collection.geometryType || "data"}>
                  {collection.name}
                  {collection.archived ? " (Archived)" : ""}
                </td>
                <td className="type">
                  <Button variant="download" onClick={() => downloadCollectionData(collection)} title="Download Collection Data as CSV" />
                  <Button variant="view" onClick={() => showCollectionData(collection)} title="Preview Collection Data" />
                  <Button variant="data" onClick={() => toggleDataConsole(collection)} title="Open data console for this collection" />
                  <Button variant="information" onClick={() => showCollectionMetadata(collection)} title="Collection Details" />
                  <span>{getTypeName(collection.type)}</span>
                </td>
                <td className="controls">
                  {collection.archived ? (
                    <Button variant="unarchive" onClick={() => toggleArchived(collection)} title={`Unarchive '${collection.name}'`} />
                  ) : (
                    <Button variant="archive" onClick={() => toggleArchived(collection)} title={`Archive '${collection.name}'`}></Button>
                  )}
                  <Button variant="edit" title={`Edit '${collection.name}'`} onClick={() => editCollection(collection)} />
                  <ConfirmButton type="del" text={"Are you sure you want to do this?"} idiotTest={collection.name} yes={"Delete"} callback={() => deleteCollection(collection)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const AngularDataCollections = react2angular(withReduxProvider(DataCollections));
