/**
 * @name fade-in
 * @memberOf common
 * @ngdoc animation
 * @description
 *   Animation to fade in
 */
angular.module("common").animation(".fade-in", function () {
  return {
    addClass: function (element, className, done) {
      jQuery(element).fadeIn();
    },
  };
});
