/**
 * Directive for multi-selection
 * @name multiSelect
 * @ngdoc directive
 * @requires ngModel
 *
 * @attribute items the array of items to build the select from
 * @attribute options the select settings. The options are expected to be a string, separated by comma like so: valueProperty,labelProperty
 * @attribute model the ngModel
 * @attribute [label=] the callback to get a custom label. Will pass in `$values`, where `$values` is the array of selected options, as long as `$values` is one of the params to the function
 *
 * @example
 * <div multi-select="source" options="value,label" ng-model="model"></div>
 */

angular.module("common").directive("multiSelect", function ($parse) {
  return {
    scope: {
      items: "=multiSelect",
      unparsedOptions: "@options",
      model: "=ngModel",
      label: "&",
    },
    require: "ngModel",
    templateUrl: "/directives/multi-select.html",
    link: function ($scope, element, attrs, ngModel) {
      var splitOptions = $scope.unparsedOptions.split(",");
      $scope.options = { value: splitOptions[0], label: splitOptions[1] || splitOptions[0] };
      $scope.isDropdownOpen = false;

      if (!$scope.model) $scope.model = [];

      $scope.generateID = function ($index) {
        return Math.floor(Math.random() * 1000000) + 1 + "-" + $scope.$id + "-" + $index;
      };

      $scope.getLabel = function () {
        if (attrs.label) return $scope.label({ $values: $scope.model });
        else return `${$scope.model.length} selected`;
      };

      $scope.get = function (type, item, $index) {
        if ($scope.options[type] == "$index") return $index;

        if ($scope.options[type] == "$value") return item;

        return item[$scope.options[type]];
      };
    },
  };
});
