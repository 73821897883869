import { Backdrop, Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="div"
          color="white"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const TruTerritoryProgressBackdrop: React.FC<JSX.IntrinsicAttributes> = () => {
  const progress = useAppSelector((state) => state.pages.truterritory.progress)
  
  return <Backdrop open={!!progress} style={{flexDirection: "column"}}>

    { progress?.text && <p style={{fontSize: "large", color: "#fff"}}>{progress?.text}</p> }

    { progress?.determinate
      && <CircularProgressWithLabel value={progress?.value || 0} sx={{color: "white"}} size={80} /> 
      || <CircularProgress sx={{color: "white"}} size={60} />
    }
  </Backdrop>;
}