import { datadogRum } from "@datadog/browser-rum";

const applicationId = require("../../app/configuration/index").DATADOG_APP_ID;

export const bootstrap = () => {
  if (!applicationId) return;

  datadogRum.init({
    applicationId,
    clientToken: "pub21ec35b21120b7c03499924823dafb35",
    site: "datadoghq.com",
    service: "epilists",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
};
