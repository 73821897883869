import { useSortable, UseSortableArguments } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ReactNode } from "react";

export const SortableLi = ({
  id,
  children,
  className,
  useSeparateDragHandle,
}: {
  id: UseSortableArguments["id"];
  children: ReactNode;
  className?: string;
  useSeparateDragHandle?: boolean;
}) => {
  const { setNodeRef, transform, transition, isDragging, listeners, attributes } = useSortable({ id });

  const style = {
    // CSS.Translate doesn't cause the element to become 100% the height of whatever it's over like CSS.Transform does.
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 9999 : undefined,
  };

  return (
    <li ref={setNodeRef} style={style} className={className} {...(useSeparateDragHandle ? {} : { ...listeners, ...attributes })}>
      {children}
    </li>
  );
};
