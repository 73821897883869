import { Formik } from "formik";
import * as Yup from "yup";

import { Content } from "../design/layout/Content";
import { Controls } from "../design/layout/Controls";
import { Button } from "../design/Button";
import { TextField } from "../design/TextField";
import { Gravatar } from "../utils/gravatar";
import { save } from "../services/user";
import { getService } from "react-in-angularjs";
import { react2angular } from "react2angular";

export const MyProfile = () => {
  const Session = getService("Session");
  const image = Gravatar(Session.emailSum, 256);

  return (
    <Content fullWidth>
      <h1>Edit My Profile</h1>
      <Formik
        initialValues={{
          ID: Session.get("ID") as number,
          fname: Session.get("fname") as string,
          lname: Session.get("lname") as string,
          email: Session.get("email") as string,
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={Yup.object().shape({
          fname: Yup.string().required("First Name is required"),
          lname: Yup.string().required("Last Name is required"),
          email: Yup.string().email("Must be valid email").required("Email is required"),
          password: Yup.string().min(6),
          passwordConfirm: Yup.string().oneOf([Yup.ref("password"), null], "Must match password"),
        })}
        onSubmit={async (user, { resetForm }) => {
          await save({
            ...user,
            password: user.password ? user.password : undefined,
            passwordConfirm: user.passwordConfirm ? user.passwordConfirm : undefined,
          });
          resetForm({
            values: {
              ...user,
              password: "",
              passwordConfirm: "",
            },
            isSubmitting: false,
          });
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
          <form className="edit-my-profile col-md-4" onSubmit={handleSubmit}>
            <div className="profile-picture">
              <div className="overlay col-md-12">
                <a href="http://gravatar.com" rel="nofollow" target="_blank">
                  Change your avatar at Gravatar.com
                </a>
              </div>
              <img className="col-md-12" src={image} />
            </div>

            <TextField label="First Name:" placeholder="First Name" name="fname" value={values.fname} onChange={handleChange} onBlur={handleBlur} error={errors.fname} />
            <TextField label="Last Name:" placeholder="Last Name" name="lname" value={values.lname} onChange={handleChange} onBlur={handleBlur} error={errors.lname} />
            <TextField label="Email:" placeholder="Email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} error={errors.email} />
            <TextField
              type="password"
              label="Password:"
              placeholder="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password}
            />
            <TextField
              type="password"
              label="Confirm password:"
              placeholder="Confirm Password"
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={handleChange}
              error={errors.passwordConfirm}
            />

            <Controls breakpoint={"md"} size={6} offset={4}>
              <Button type="submit" loading={isSubmitting} disabled={!isValid}>
                Save
              </Button>
            </Controls>
          </form>
        )}
      </Formik>
    </Content>
  );
};

export const AngularMyProfile = react2angular(MyProfile);
