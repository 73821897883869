import { react2angular } from "react2angular";
import { Modal } from "./Modal";
import { FormEvent, FormEventHandler, useEffect, useState } from "react";
import config from "../../app/configuration";
import { SyntheticEvent } from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { classNameMapper } from "../utils/classNameMapper";

export const ResetPasswordModal = () => {
  const token = window.location.pathname.split("/").pop();

  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [hasExpiredToken, setHasExpiredToken] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    fetch(config.API_ROOT + `/password/reset?token=${token}`).then((response) => setHasExpiredToken(response.status !== 200));
  }, [token]);

  return (
    <Modal isOpen={true} onClose={() => {}}>
      {hasExpiredToken || isSuccess ? (
        <>
          <div className="modal-header">
            <h3>Reset Password</h3>
          </div>
          {isSuccess && <div className="modal-body text-success">Your password has been successfully reset. You can now login using your new password</div>}
          {hasExpiredToken && <div className="modal-body text-danger">This reset password link has expired. Please try again using a different link.</div>}
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => (window.location.href = "/")}>
              Close
            </button>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{ email: "", password: "", passwordConfirm: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Must be valid email").required("Email is required"),
            password: Yup.string().min(6).required("Password is required"),
            passwordConfirm: Yup.string()
              .required("Confirm password is required")
              .oneOf([Yup.ref("password"), null], "Must match password"),
          })}
          validateOnBlur={hasBeenSubmitted}
          validateOnChange={hasBeenSubmitted}
          onSubmit={async (values, { setSubmitting }) => {
            const body = new FormData();
            body.append("email", values.email);
            body.append("password", values.password);
            body.append("passwordConfirm", values.passwordConfirm);
            body.append("token", token ?? "");

            await fetch(config.API_ROOT + "/password/reset", {
              method: "POST",
              credentials: "include",
              body,
            })
              .then((response) => response.json())
              .then((response) => {
                if (response && response.status.code === 200) {
                  setErrorMessage("");
                  setIsSuccess(true);
                }

                if (response && response.status.code === 404) {
                  setErrorMessage((response.status && response.status.message) || "This reset password link has expired. Please try again using a different link.");
                } else {
                  const errors = response.meta?.errors ?? [];
                  let errorText = "";

                  for (const error in errors) {
                    errorText += "\n" + errors[error];
                  }

                  setErrorMessage(errorText.length ? errorText : "An error occurred while resetting your password. Please try again.");
                }
              });
            setSubmitting(false);
          }}
        >
          {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
            <form
              onSubmit={(e) => {
                setHasBeenSubmitted(true);
                handleSubmit(e);
              }}
            >
              <div className="modal-header">
                <h3>Reset Password</h3>
              </div>
              <div className="modal-body">
                <div className={classNameMapper({ "form-group": true, "has-error": "email" in errors })}>
                  <input type="text" className="form-control" placeholder="Email Address" name="email" onChange={handleChange} value={values.email} />
                  <span className="text-danger">
                    <ErrorMessage name="email" />
                  </span>
                </div>
                <div className={classNameMapper({ "form-group": true, "has-error": "password" in errors })}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <span className="text-danger">
                    <ErrorMessage name="password" />
                  </span>
                </div>
                <div className={classNameMapper({ "form-group": true, "has-error": "passwordConfirm" in errors })}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    name="passwordConfirm"
                    onChange={handleChange}
                    value={values.passwordConfirm}
                  />
                  <span className="text-danger">
                    <ErrorMessage name="passwordConfirm" />
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    checked={showPassword}
                    id="showPassword"
                    onChange={() => setShowPassword((v) => !v)}
                    className={classNameMapper({ _checked: showPassword ?? false })}
                  />
                  <label className="checkbox" htmlFor="showPassword">
                    Show Password
                  </label>
                </div>
                <div className="text-danger">{errorMessage}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn plain" onClick={() => (window.location.href = "/")}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? "Resetting..." : "Reset Password"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export const AngularResetPassword = react2angular(ResetPasswordModal);
