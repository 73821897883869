/**
 * A wrapper service for adding a delay for when the user is entering text
 *
 * @param {function} action the closure to be run
 * @param {integer} [delay] an optional delay (in milliseconds)
 * @return {function}
 *
 *  Example:
 *
 *  $scope.$watch('searchInput', function (value, oldValue) {
 *    if(value == oldValue)
 *      return;
 *
 *    $scope.search($scope.users, value)
 *  })
 *
 *  $scope.search = new KeystrokeSearch(
 *    function(users, value) {
 *      users.search(value)
 *    }.bind(this), // Make sure the closure is bound if arguments need to be passed to it
 *  200) // Optional delay
 *
 */

angular.module("common").factory("KeystrokeSearch", function ($timeout) {
  var KeystrokeSearch;

  KeystrokeSearch = function (action, delay) {
    // Set a default delay
    if (typeof delay == "undefined") delay = 500;

    // Keeps track of the $timeout "promise"
    var timer;

    var wrapper = function () {
      // If there's already a timeout running, cancel it
      if (timer) $timeout.cancel(timer);

      // Save the arguments to the wrapper function (used in the apply)
      var wrapperArgs = arguments;

      // Run the timeout
      timer = $timeout(function () {
        action.apply(this, wrapperArgs);
      }, delay);
    };

    // And return the new function
    return wrapper;
  };

  return KeystrokeSearch;
});
