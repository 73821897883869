import { useRef, useState } from "react";
import { getService } from "react-in-angularjs";
import { react2angular } from "react2angular";

import { DOMAIN, protocol } from "../../app/configuration";
import { CompanySelector } from "./CompanySelector";
import { ReportProblemModal } from "./ReportProblemModal";
import { logout } from "../services/user";
import { PermissionManager } from "../services/permission-manager";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { withReduxProvider } from "../services/withReduxProvider";
import { useAppSelector } from "../hooks/useAppSelector";

declare global {
  interface Window {
    epiVersion: { hash: string; branch: string; buildDate: string; publicVersion: string, title: string };
  }
}

export const Header = () => {
  const { h1, session } = useAppSelector((state) => state.app);

  const $state = getService("$state");
  const Menu = getService("Menu");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);

  const closeMenu = () => setIsMenuOpen(false);
  useOutsideClick(menuRef, closeMenu);

  const { hash, branch, buildDate, publicVersion, title } = window.epiVersion;

  /**
   * Get the base route for this user. Normally home, but if the user
   * has access only to a single entity and module, then use that module,
   * e.g., mapping.
   * @return {string}
   */
  function getBaseRoute() {
    if (!session || session.entityList.length != 1) return $state.href("home");

    let [enabled, entityID] = getEnabledFirstEntity();

    if (enabled.length != 1) return $state.href("home");

    return $state.href(enabled[0].id, { entityID });
  }

  /**
   * Get the enabled top-level routes under the user's first entity.
   * @return {array}
   */
  function getEnabledFirstEntity() {
    let entityID = session?.entityList[0].ID,
      access = new PermissionManager(session?.permissions);

    Menu.setPermissionsAndEntity(access, entityID);
    let enabled = Menu.getEnabled();
    Menu.setPermissionsAndEntity(access, entityID);

    return [enabled, entityID];
  }

  const handleLogout = async () => {
    await logout();
    window.location.href = protocol + "//" + DOMAIN;
  };

  return (
    <header>
      <nav id="top-nav" className="navbar navbar-default">
        <a href={getBaseRoute()} id="navbar-logo" className="navbar-brand"></a>
        <div className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li id="page-header">
              <h1>{h1}</h1>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <CompanySelector />
            </li>
            {session && (
              <li id="user-menu">
                <a href="#" onClick={() => setIsMenuOpen((val) => !val)}>
                  {session?.fname}
                </a>
                {isMenuOpen && (
                  <ul className="dropdown-menu open" ref={menuRef}>
                    <li>
                      <a href={$state.get("my-profile").url} onClick={closeMenu}>
                        My Profile
                      </a>
                    </li>
                    <li className="divider"></li>
                    {session?.permissions.superuser && (
                      <li>
                        <a href={$state.get("admin").url} onClick={closeMenu}>
                          Admin
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="#" onClick={handleLogout}>
                        Logout
                      </a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a
                        href="#"
                        onClick={() => {
                          setModalOpen(true);
                          closeMenu();
                        }}
                      >
                        Report a Problem
                      </a>
                    </li>
                    <li className="version" title={`${title} ${hash} - ${branch} - Built on ${buildDate}")`}>
                      {title} {publicVersion}
                    </li>
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
      </nav>
      <ReportProblemModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </header>
  );
};

Header.propTypes = {};

export const AngularHeader = react2angular(withReduxProvider(Header));
