import React, { ReactNode, useMemo } from "react";
import PropTypes from "prop-types";
import { SmallLoadingIndicator } from "./SmallLoadingIndicator";
import { react2angular } from "react2angular";

export type IButtonType = "del" | "download" | "view" | "data" | "information" | "edit" | "archive" | "unarchive" | "light-blue" | "plain";

export interface IButtonProps {
  children?: ReactNode;
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "button" | "submit" | "reset";
  hide?: boolean;
  show?: boolean;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  className?: string;
  title?: string;
  variant?: IButtonType;
  id?: string;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  value,
  className,
  onClick,
  disabled = false,
  loading = false,
  loadingText,
  type = "button",
  hide,
  show,
  title,
  variant,
  id,
}: IButtonProps) => {
  const shouldShow = useMemo(() => {
    if (typeof show === "undefined" && typeof hide === "undefined") return true;
    if (typeof show !== "undefined" && show === true) return true;
    if (typeof hide !== "undefined" && hide === false) return true;
    return false;
  }, [show, hide]);

  return shouldShow ? (
    <button onClick={onClick} className={`btn ${className ?? ""} ${variant ?? ""}`} type={type} disabled={disabled || loading} title={title} id={id}>
      {loading ? (
        <>
          <SmallLoadingIndicator />
          {loadingText || children || value}
        </>
      ) : (
        children || value
      )}
    </button>
  ) : (
    <></>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  hide: PropTypes.bool,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const AngularButton = react2angular(Button);

export const LoadingButton: React.FC<IButtonProps> = ({ children, value, ...props }: IButtonProps) => {
  return (
    <Button {...props}>
      <SmallLoadingIndicator />
      <span>{children || value}</span>
    </Button>
  );
};

LoadingButton.propTypes = {
  ...Button.propTypes,
};

export const AngularLoadingButton = react2angular(LoadingButton);
