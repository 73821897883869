/**
 * @name slide-up
 * @memberOf common
 * @ngdoc animation
 */

angular.module("common").animation(".slide-up", function () {
  return {
    addClass: function (element, className, done) {
      jQuery(element).slideUp();
    },
    removeClass: function (element, className, done) {
      jQuery(element).slideDown();
    },
  };
});
