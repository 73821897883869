export const IntervalSlider = L.Control.extend({
  includes: L.Evented.prototype,
  options: {
    position: "bottomleft",
  },
  onAdd: function (map) {
    // Create the divs to hold the slider, and the slider
    var div = L.DomUtil.create("div", "leaflet-control-interval-slider"),
      slider = L.DomUtil.create("div", "slider", div);

    // Disable the click propagation so that the slider events aren't caught by the map
    L.DomEvent.disableClickPropagation(slider);

    $(slider).prepend("<p>Compare:</p>");

    $(slider).slider({
      orientation: "horizontal",
      range: false,
      min: 0,
      max: this.options.intervals.length - 1,
      value: this.options.intervals.indexOf(this.options.interval),
      step: 1,
      slide: (event, ui) => {
        // On slider, change the label and text position if necessary
        var label = $(ui.handle).children("label");
        label.text(this.options.intervals[ui.value]);
        if (ui.value == this.options.intervals.length - 1) label.css("left", -(label.width() + 20));
        else label.css("left", 10);
      },
      create: (event, ui) => {
        // Create the label, set the text, and the position
        var handle = $(slider).children(".ui-slider-handle").eq(0);
        var label = $("<label>" + this.options.interval + "</label>");

        label.css("left", 10);
        handle.html(label);
      },
      stop: (event, ui) => {
        this.fire("change", { interval: this.options.intervals[ui.value] });
      },
    });

    return div;
  },
});
