export enum CollectionType {
  Master = "master",
  Transformation = "transform",
  Dynamic = "dynamic",
  Writable = "writable",
}

export const CollectionTypes = [
  { type: "master", name: "Master" },
  { type: "transform", name: "Transformation" },
  { type: "dynamic", name: "Dynamic" },
  { type: "writable", name: "Writable" },
];
