export default angular.module("bootstrap.menu", []).run(function ($rootScope, Menu, PermissionManager, Session, $state, $timeout) {
  // Set menu instance for the view
  $rootScope.menu = Menu;

  // Watch for updates
  Session.onFetch(refreshMenu);
  $rootScope.$on("entitySelected", refreshMenu);

  // update permission manager and entityID in Menu
  function refreshMenu() {
    let access = new PermissionManager(Session.getAll().permissions);
    $rootScope.menu.setPermissionsAndEntity(access, $rootScope.entityID);
  }
}).name;
