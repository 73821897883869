import { useEffect, useState } from "react";
import angular, { IScope } from "angular";
import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { ICollection, ITheme, IThemeWithID } from "../../../types/ICollection";
import { IHasMapAccess } from "../../../../app/routes/mapping/services/has-map-access";
import { withReduxProvider } from "../../../services/withReduxProvider";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { TruTerritorySideFilters } from "./TruTerritorySideFilters";
import { IFilter, IMap } from "../../../types/IMap";
import { SortableUl } from "../../../components/Sortable/SortableUl";
import { CollectionLi } from "./CollectionLi";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  removeCollection,
  setCurrentlyEditingThemeAndCollection,
  setMap,
  setMapDirty,
  setMapLabel,
  setMapTheme,
  setSidePanelOpen,
  toggleTopPanelSection,
} from "../../../slices/pagesSlice";
import { useCreateThemeMutation, useDeleteCollectionMutation } from "../../../slices/apiSlice";
import { IModalService } from "angular-ui-bootstrap";
import { Modal } from "../../../components/Modal";
import { Field, Formik } from "formik";
import { Button } from "../../../design/Button";

interface TruTerritorySideProps {
  isPublicPage?: boolean;
  style?: React.CSSProperties;
}

export const TruTerritorySide: React.FC<TruTerritorySideProps & JSX.IntrinsicAttributes> = ({ isPublicPage, style }: TruTerritorySideProps) => {
  const dispatch = useAppDispatch();
  const hasAccess: IHasMapAccess = getService("hasMapAccess");
  const Theme = getService("Theme");
  const { map, availableCollections } = useAppSelector((state) => state.pages.truterritory);

  const [createTheme] = useCreateThemeMutation();

  const [openCollections, setOpenCollections] = useState<Record<number, boolean>>({});
  const [selectedCollection, setSelectedCollection] = useState<ICollection>();
  const [cloningTheme, setCloningTheme] = useState<ITheme | null>(null);

  // when the map changes, close all collections and go back to the collections view
  useEffect(() => {
    setOpenCollections({});
    setSelectedCollection(undefined);
  }, [map?.ID]);

  const onClose = () => dispatch(setSidePanelOpen(false));

  const toggleCollectionOpen = (ID: ICollection["ID"]) => {
    setOpenCollections((openCollections) => ({
      ...openCollections,
      [ID]: !openCollections[ID],
    }));
  };

  const handleRemoveCollection = async (ID: ICollection["ID"]) => {
    if (!map) return;

    dispatch(removeCollection(ID))
    dispatch(setMapDirty(true));
  };

  const handleVisibleToggle = (ID: ICollection["ID"]) => {
    if (!map) return;

    const newMap = { ...map, visible: { ...map.visible, [ID]: !map.visible[ID] } };

    dispatch(setMap(newMap));
    dispatch(setMapDirty(true));
  };

  const setLabel = (ID: ICollection["ID"], label: string | null) => {
    if (!map) return;

    dispatch(setMapLabel({ collectionID: ID, label }));
    dispatch(setMapDirty(true));
  };

  const addTheme = async (ID: ICollection["ID"]) => {
    if (!map) return;

    const collection = availableCollections.find((c) => c.ID === ID);
    if (!collection) return;

    const theme = Theme.getDefaultTheme(collection, map.workspaceID) as ITheme;
    const newTheme = (await createTheme(theme).unwrap()) as IThemeWithID;

    dispatch(setMapTheme({ collectionID: ID, theme: newTheme }));
    dispatch(setMapDirty(true));
  };

  const editTheme = (collectionID: ICollection["ID"], theme: ITheme) => {
    const collection = availableCollections.find((c) => c.ID === collectionID);
    if (!collection || !map) return;

    dispatch(
      setCurrentlyEditingThemeAndCollection({
        collection,
        theme,
      })
    );
    dispatch(toggleTopPanelSection("theme"));
    onClose();
  };

  const cloneTheme = (collectionID: ICollection["ID"], theme: ITheme) => {
    if (!map) return;
    setCloningTheme(theme);
  };

  const applyFilterChanges = (collectionID: ICollection["ID"], filters: IFilter[]) => {
    if (!map) return;

    dispatch(setMap({ ...map, filters: { ...map.filters, [collectionID]: filters } }));
    dispatch(setMapDirty(true));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!map) return;

    if (active.data.current && active.id !== over?.id) {
      const oldIndex = map.collectionIDs.indexOf(active.id as number);
      const newIndex = map.collectionIDs.indexOf(over?.id as number);

      if (oldIndex === -1 || oldIndex === undefined || newIndex === -1 || newIndex === undefined) return;

      const newCollectionIDs = arrayMove(map.collectionIDs ?? [], oldIndex, newIndex);
      dispatch(setMap({ ...map, collectionIDs: newCollectionIDs }));
      dispatch(setMapDirty(true));
    }
  };

  const setTheme = (ID: ICollection["ID"], theme: ITheme) => {
    dispatch(setMapTheme({ collectionID: ID, theme: theme as IThemeWithID }));
    dispatch(setMapDirty(true));
  };

  const collections = map?.collectionIDs
                          .map(id => availableCollections.find(c => c.ID == id))
                          .filter((c): c is ICollection => !!c)
                      || [];

  return (
    <>
      <div className="toggle-triangle open">
        <div className="triangle" onClick={onClose}></div>
      </div>
      <div className="inner">
        <div className="map-tab tab-content" style={selectedCollection ? { width: "0px" } : {}}>
          <div className="input-search centered" style={selectedCollection ? { opacity: 0 } : {}}>
            <input className="search" type="text" placeholder="Search" ng-model="search" />
          </div>
          <div className="collections-wrapper">
            <SortableUl
              className="collections"
              id={`side-collections-sortable`}
              items={collections.map((c) => c.ID) ?? []}
              onDragStart={() => setOpenCollections({})}
              onDragEnd={handleDragEnd}
            >
              {collections.map((collection) => (
                <CollectionLi
                  isOpen={openCollections[collection.ID]}
                  toggleOpen={() => toggleCollectionOpen(collection.ID)}
                  key={`collection-${collection.ID}`}
                  collection={collection}
                  isVisible={!!map?.visible[collection.ID]}
                  label={map?.labels[collection.ID]}
                  isPublicPage={isPublicPage}
                  hasAccess={hasAccess}
                  handleVisibleToggle={handleVisibleToggle}
                  handleRemoveCollection={handleRemoveCollection}
                  editTheme={editTheme}
                  cloneTheme={cloneTheme}
                  setTheme={setTheme}
                  addTheme={addTheme}
                  setLabel={setLabel}
                  onSelect={setSelectedCollection}
                />
              ))}
            </SortableUl>
          </div>
        </div>

        <div className="collections-tab tab-content" style={selectedCollection ? { width: "100%" } : { width: "0px", paddingLeft: "0px" }}>
          {selectedCollection && (
            <>
              <div>
                <button className="btn plain back-button" onClick={() => setSelectedCollection(undefined)} title="Go back to collections list">
                  &lt; Back
                </button>
              </div>
              <TruTerritorySideFilters selectedCollection={selectedCollection} onApplyChanges={applyFilterChanges} />
            </>
          )}
        </div>
      </div>

      <Modal isOpen={!!cloningTheme} onClose={setCloningTheme.bind(null, null)} id="truterritory-clone-theme-modal">
        <Formik
          initialValues={{
            name: cloningTheme?.name,
          }}
          onSubmit={async (values, { setSubmitting}) => {
            if (!cloningTheme) return;

            setSubmitting(true);
            setCloningTheme(null);
            const newTheme = (await createTheme({...cloningTheme, name: values.name || ""}).unwrap()) as IThemeWithID;

            dispatch(setMapTheme({ collectionID: newTheme.collectionID, theme: newTheme }));
            dispatch(setMapDirty(true));
          }}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="modal-body">
                <h4>Enter a name for the cloned theme</h4>
                <Field type="text" name="name" />
              </div>
              <div className="modal-footer">
                <div className="controls">
                  {isSubmitting ? (
                    <Button type="submit" variant="light-blue" disabled>
                      Saving...
                    </Button>
                  ) : (
                    <Button type="submit" variant="light-blue">
                      Save
                    </Button>
                  )}
                  <Button variant="plain" onClick={setCloningTheme.bind(null, null)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export const AngularTruTerritorySide = react2angular(withReduxProvider(TruTerritorySide), [], ["$scope"]);
