angular.module("common").factory("Collapser", function () {
  var Collapser = function (element) {
    this.element = element;
    this.children = this.element.children();
    this.timeout, this.timer;
  };

  Collapser.prototype = {
    /**
     * Animates in
     * @param  {integer} index
     * @param  {integer} timeout
     * @return {void}
     */
    in: function (index, timeout) {
      if (this.timeout) window.clearTimeout(this.timeout);

      // Get all the calculations
      this.target = this.children.eq(index);

      if (!this.target.get(0)) return;

      this.targetWidth = (this.target.get(0).scrollWidth / this.element.width()) * 100;
      this.siblings = this.target.siblings();

      // Make sure that the target isn't animating bigger than the parent
      if (this.targetWidth > 80 && this.siblings.length) {
        this.targetWidth = 80;
      } else if (!this.siblings.length) {
        this.targetWidth = 100;
      }

      this.targetSiblingWidth = (100 - this.targetWidth) / this.siblings.length;

      this.groups = [
        { targetWidth: this.targetWidth, elements: this.target },
        { targetWidth: this.targetSiblingWidth, elements: this.siblings },
      ];

      this.animationLength = 20;
      this.step = 1;

      this.timeout = window.setTimeout(this._run.bind(this), 200);
    },

    /**
     * Animates out
     * @param  {integer} timeout
     * @return {void}
     */
    out: function (timeout) {
      if (this.timeout) window.clearTimeout(this.timeout);

      this.animationLength = 20;
      this.step = 1;

      this.groups = [{ targetWidth: (this.element.width() / this.children.length / this.element.width()) * 100, elements: this.element.children() }];
      this.timeout = window.setTimeout(this._run.bind(this), 200);
    },

    /**
     * Runs the animation for an array of elements
     * @return {void}
     */
    _run: function () {
      this.stepsRemaining = this.animationLength - this.step;

      var self = this;
      for (var i = 0; i < this.groups.length; i++) {
        var group = this.groups[i];
        group.elements.each(function () {
          self._step(this, group.targetWidth, self.stepsRemaining);
        });
      }

      if (this.step != this.animationLength) {
        this.step++;
        setTimeout(this._run.bind(this), 5);
      }
    },

    _step: function (element, targetWidth, stepsRemaining) {
      var node = $(element),
        width = (element.getBoundingClientRect().width / this.element.width()) * 100,
        remaining = targetWidth - width;
      node.css("width", width + remaining / stepsRemaining + "%");
    },

    // _setTimeout: function() {
    //   this.animationLength = 20
    //   this.step = 1
    //   this.timer = window.setInterval(this._runInterval.bind(this), 5)
    // },
    //
    // _runInterval: function() {
    //   this.stepsRemaining = this.animationLength - this.step
    //   this.targetCurrentWidth = this.target.get(0).getBoundingClientRect().width;

    //   // var self = this
    //   // this.siblings.each(function() {
    //   //   self._step(this, self.targetSiblingWidth, self.stepsRemaining)
    //   // })

    //   // this.target.css('width', this.targetCurrentWidth + (this.targetWidth - this.targetCurrentWidth) / this.stepsRemaining + 'px')

    //   if(this.step == this.animationLength) {
    //     this.siblings.css('width', this.targetSiblingWidth)
    //     this.target.css('width', this.targetWidth)
    //     clearInterval(this.timer)
    //   } else {
    //     this.step++;
    //   }
    // },
  };

  return Collapser;
});
