/**
 * Map Report Editor
 */

angular.module("routes:mapping").directive("mapReports", function () {
  return {
    scope: {
      map: "=",
      reports: "=",
      collections: "@",
      templates: "@",
    },
    templateUrl: "/truterritory/map-reports.html",
    controller: function ($scope, Map, MapReports, hasMapAccess, Templates) {
      //getAllCollections();

      $scope.mapReports = MapReports;
      $scope.templates = Templates.getList({ columns: "ID,slug" }).$object; //Templates.load().$object;
      $scope.collections = Map.collections;
      // $scope.search = null;
      // $scope.limitToWorkspace = false;
      // $scope.collections = [];
      // $scope.openWorkspaceModal = MappingModals.openSaveWorkspace;
      $scope.hasAccess = hasMapAccess;

      $scope.addEmptyReport = function () {
        // Add a new, empty map report
      };

      // Initialize the current state
      $scope.init = function () {
        $scope.mapReports = Map.reports;
        $scope.templates = Templates.getList({ columns: "ID,slug" }).$object; //Templates.load().$object;
        $scope.collections = Map.collections;
      };

      $scope.save = function () {
        $scope.mapReports.put().then(function () {
          $scope.onSave($scope.mapReports);
        });
      };

      // /**
      //  * Load the list of all collections, not just in the current workspace
      //  * @return {void}
      //  */
      // function getAllCollections() {
      //     Collection.getList({with: 'themes,themes.rules'}).then(function (collections) {
      //         allCollections = collections;
      //         $scope.collections = collections.clone();
      //
      //         $scope.filterCollections($scope.search, $scope.limitToWorkspace);
      //     });
      // }
    },
  };
});
