angular.module("common").provider("Storage", function () {
  var prefix;
  return {
    setPrefix: function (newPrefix) {
      prefix = newPrefix;
    },
    $get: function ($window, $state, Session) {
      if (!prefix) throw TypeError("Storage needs a set prefix");

      return new Storage($window, $state, prefix + "-" + Session.get("ID"));
    },
  };
});

var Storage = function ($window, $state, prefix) {
  this.prefix = prefix;
  this.state = $state;
  this.parsedStorage;
  this.supported = "localStorage" in $window && $window["localStorage"] !== null ? true : false;

  // When Safari (OS X or iOS) is in private browsing mode, it appears as though localStorage
  // is available, but trying to call .setItem throws an exception below:
  // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota."
  // https://github.com/gsklee/ngStorage/blob/master/ngStorage.js#L59
  if (this.supported) {
    var key = "__" + Math.round(Math.random() * 1e7);

    try {
      $window.localStorage.setItem(key, key);
      $window.localStorage.removeItem(key);
    } catch (err) {
      this.supported = false;
    }
  }

  if (this.supported) {
    this.storage = $window.localStorage;
    this.parsedStorage = JSON.parse($window.localStorage.getItem(this.prefix));
  } else this.storage = new storagePolyfill();

  if (!this.parsedStorage) this.parsedStorage = {};
};

Storage.prototype.getItem = function (key, entityID) {
  return this.parsedStorage[this._getKey(key, entityID)] || null;
};

Storage.prototype.setItem = function (key, value, entityID) {
  this.parsedStorage[this._getKey(key, entityID)] = value;
  this._setStorage();
};

Storage.prototype.removeItem = function (key, entityID) {
  delete this.parsedStorage[this._getKey(key, entityID)];
  this._setStorage();
};

Storage.prototype.clear = function () {
  this.parsedStorage = {};
  this._setStorage();
};

Storage.prototype._setStorage = function () {
  this.storage.setItem(this.prefix, JSON.stringify(this.parsedStorage));
};

Storage.prototype._getKey = function (key, optionalEntityID) {
  var entityID = !optionalEntityID && "params" in this.state && this.state.params.entityID ? this.state.params.entityID : optionalEntityID;
  if (entityID) key = "entity." + entityID + "." + key;

  return key;
};

function storagePolyfill() {
  this.data = {};
}

storagePolyfill.prototype = {
  getItem: function (key) {
    return this.data[key] || null;
  },
  setItem: function (key, value) {
    this.data[key] = value;
  },
  removeItem: function (key) {
    delete this.data[key];
  },
};
