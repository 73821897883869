import React, { FormEvent, ReactNode, useEffect, useState } from "react";
import { Button } from "../design/Button";

export const EditInPlace = ({ value, onSave, children }: { value: string; onSave: (value: string) => void; children: ReactNode }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(editedValue);
    setIsEditing(false);
  };

  // if the external value changes, update the edited value
  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  if (isEditing)
    return (
      <form className="editable-wrap editable-text" onSubmit={handleSubmit}>
        <span className="editable-controls">
          <input type="text" className="editable-has-buttons editable-input" value={editedValue} onChange={({ target }) => setEditedValue(target.value)} />
          <span className="editable-buttons">
            <Button type="submit">Save</Button>
            <Button type="button" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </span>
        </span>
      </form>
    );

  return <div onClick={() => setIsEditing(true)}>{children}</div>;
};
