angular.module("modules:mapping").factory("Workspace", function ($rootScope, Restangular, Collection, Map, $http, $timeout, Session, PermissionManager) {
  var Workspaces = Restangular.all("mapping/workspaces");

  // add a custom methods to collections of collections
  Restangular.addElementTransformer("mapping/workspaces", true, function (workspaces) {
    // don't try to work on uninitialized collection
    if (!("one" in workspaces)) return workspaces;

    workspaces.addRestangularMethod("search", "getList", "search");

    workspaces.getWorkspace = function (ID) {
      if (!ID) ID = workspaces[0].ID;

      return workspaces.get(ID, { with: "all" }).catch(function (response) {
        // don't show the error modal. just try to get another workspace
        $timeout.cancel(response.modalTimeout);
        return workspaces.get(_.find(workspaces, { default: true }).ID, { with: "all" });
      });
    };

    /**
     * Finds a map by ID in the workspaces
     * @param  {integer} mapID
     * @return {object}  the found map
     */
    workspaces.findMap = function (mapID) {
      for (var i = 0; i < workspaces.length; i++) {
        var map = _.find(workspaces[i].maps, { ID: mapID * 1 });
        if (map) return map;
      }
    };

    return workspaces;
  });

  // add methods to single collections
  Restangular.addElementTransformer("mapping/workspaces", false, function (workspace) {
    if (!("one" in workspace)) return workspace;

    workspace.postClone = function () {
      return workspace.post("", { name: workspace.name }, { with: "all" }).then(function (response) {
        Restangular.restangularizeElement(null, response, "mapping/workspaces");
        restangularizeCollections(response.collections);
        restangularizeMaps(response.maps);
        return response;
      });
    };

    workspace.removeUser = function (userID) {
      return workspace.customDELETE("users/" + userID);
    };

    // workspace.confirmRemoveUser = function(userID) {
    //   return workspace.customDELETE('users/'+userID, {confirm: true})
    // }

    workspace.saveUsers = function (users) {
      return workspace.customPUT(_.map(users, "ID"), "users");
    };

    workspace.getTopLevelMaps = function () {
      return workspace.maps ? workspace.maps.filter((m) => !m.parentID) : [];
    };

    /**
     * Remove a collection from this workspace
     * @param  {int} collectionID
     * @return {Promise}
     */
    workspace.removeCollection = function (collectionID) {
      return this.customDELETE("collections/" + collectionID).then((response) => {
        this.collections.splice(_.findIndex(this.collections, { ID: collectionID }), 1);

        for (let i = 0; i < this.maps.length; i++) {
          this.maps[i].removeCollection(collectionID);
        }
        return response;
      });
    };

    restangularizeCollections(workspace.collections);
    restangularizeMaps(workspace.maps);

    return workspace;
  });

  function restangularizeCollections(collections) {
    if (collections) {
      for (var i = 0; i < collections.length; i++) {
        Restangular.restangularizeElement(null, collections[i], "mapping/collections");
      }
    }
  }

  function restangularizeMaps(maps) {
    if (maps) {
      for (var i = 0; i < maps.length; i++) {
        Restangular.restangularizeElement(null, maps[i], "mapping/maps");
      }
    }
  }

  return Workspaces;
});
