angular.module("common").directive("epiPaging", function () {
  return {
    restrict: "C",
    templateUrl: "/directives/epi-paging.html",
    scope: {
      pages: "=pages",
      collection: "=collection",
      verbosity: "@verbosity",
      request: "=",
      params: "=",
    },
    link: function (scope, element, attrs) {
      scope.nextPage = function () {
        this.page(this.pages.nextPage);
      };

      scope.prevPage = function () {
        this.page(this.pages.prevPage);
      };

      scope.page = function (page) {
        var request;
        if (!scope.request) {
          request = this.collection.getList;
        } else {
          request = scope.request;
        }

        var params = angular.extend({ page: page }, scope.params);

        request(params).then(function (collection) {
          scope.collection = collection;
        });
      };
    },
  };
});
