import { react2angular } from "react2angular";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setSidePanelOpen } from "../../slices/pagesSlice";
import { withReduxProvider } from "../../services/withReduxProvider";

export const ToggleTriangle = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="toggle-triangle" onClick={() => dispatch(setSidePanelOpen(true))}>
      <div className="triangle closed" />
    </div>
  );
};

export const AngularToggleTriangle = react2angular(withReduxProvider(ToggleTriangle));
