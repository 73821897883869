import { store } from "../../../../../src/store";

angular.module("modules:mapping").directive("mapPointDetail", function (Restangular, Feature, hasMapAccess) {
  return {
    restrict: "A",
    templateUrl: "/directives/map-point-detail.html",
    scope: {
      leafletMap: "=map",
      feature: "=",
      findCollection: "=",
      editable: "=",
    },
    controller: function ($scope) {
      Feature.restangularize($scope.feature);
      var originalFeature = Restangular.copy($scope.feature);
      $scope.collection = angular.copy($scope.findCollection($scope.feature.layerID));

      const { map, workspaceID } = store.getState().pages.truterritory;

      $scope.editable = ($scope.collection.type == "master" || $scope.collection.type == 'writable')
                        && hasMapAccess('collaborate')
                        && (map.editable && map.editable[$scope.collection.ID])
                        && (typeof $scope.editable == "undefined" ? true : $scope.editable);
      $scope.hasEditableColumns = !!$scope.collection.properties.find(prop => !prop['system'] && !prop['protected'] && !prop['hidden'])
      $scope.formattedDates = $scope.collection.properties
              .filter(prop => prop['type'] == 'timestamptz')
              .reduce((dict, prop) => (
                {...dict, [prop['column']]: $scope.feature.properties[prop['column']] && new Date($scope.feature.properties[prop['column']]).toLocaleString()}
              ), {});

      $scope.csvCopy = $scope.feature.toCSV(makeColumnTuples($scope.collection.featureColumns, $scope.collection.properties));

      // Set the forms object so it can be accessed
      $scope.forms = {};

      $scope.save = function (feature, properties, form) {
        form.validate();
        if (form.isValid()) {
          $scope.editing = false;
          $scope.saving = true;
          feature.saveProperties(feature.layerID, properties, map.ID, workspaceID).finally(function () {
            $scope.saving = false;
            originalFeature = feature.clone();
          });
        }
      };

      $scope.close = function () {
        $scope.leafletMap.closePopup();
      };

      $scope.cancel = function (feature) {
        $scope.editing = false;
        $scope.feature = originalFeature.clone();
      };

      /**
       * Make an array of tuples to pass into feature.toCSV()
       * @param  {array} featureColumns Columns names in order
       * @param  {object} properties    Dictionary of info for each columns
       * @return {array}
       */
      function makeColumnTuples(featureColumns, properties) {
        let columns = [];

        properties.forEach(function (info, index, arr) {
          columns.push([info.name, info.column]);
        });

        return columns;
      }
    },
  };
});
