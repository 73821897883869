import * as CodeMirror from "codemirror";
window.CodeMirror = CodeMirror;

import "../../../node_modules/codemirror/addon/hint/show-hint";
import "../../../node_modules/codemirror/addon/hint/sql-hint";
import "../../../node_modules/codemirror/addon/mode/overlay";
import "../../../node_modules/codemirror/mode/sql/sql";
import "../../../node_modules/codemirror/mode/css/css";
import "../../../node_modules/codemirror/addon/display/fullscreen";

/**
 * @name codeMirror
 * @memberOf common
 * @ngdoc directive
 * @scope true
 * @restrict E
 * @see {@link http://codemirror.net/|Code Mirror}
 * @description A directive for the CodeMirror editor
 *
 * @attribute {Object} options The CodeMirror options to pass into the instance. See [the CodeMirror options](http://codemirror.net/doc/manual.html#config)
 * @example
 *   <code-mirror ng-model="model" options="options" ></code-mirror>
 */

angular.module("common").directive("codeMirror", function () {
  return {
    scope: {
      options: "=",
      onBlur: "&",
    },
    require: "ngModel",
    restrict: "E",
    template: "<textarea></textarea>",
    controller: function () {}, // This empty controller function is here so that you can "require" this directive
    link: function ($scope, $element, $attrs, ngModel) {
      var options = angular.extend(
        {
          indentWithTabs: true,
          smartIndent: true,
          matchBrackets: true,
          autofocus: true,
          theme: "neat epiphany",
        },
        $scope.options,
        { lineNumbers: false }
      );

      // Create the editor, extending the default settings
      var editor = CodeMirror.fromTextArea($element.children()[0], options);

      ngModel.$render = function () {
        // Wrap in a timeout, b/c Code Mirror is weird
        setTimeout(function () {
          // Set the editor value to the model's value
          editor.setValue(ngModel.$modelValue);

          // Turn on line numbers by default, else, use the options passed in
          editor.setOption("lineNumbers", "lineNumbers" in $scope.options ? $scope.options.lineNumbers : true);
          $scope.$emit("codeMirrorReady");
          editor.focus();
        });
      };

      // On change, set the model to the editor's value
      editor.on("change", function (event) {
        ngModel.$setViewValue(event.getValue());
      });

      editor.on("blur", $scope.onBlur);

      // Add a fullscreen toggle button
      var fullscreen = document.createElement("span");
      fullscreen.className = "re-icon redactor-btn-image re-fullscreen";
      fullscreen.onclick = function () {
        editor.setOption("fullScreen", !editor.getOption("fullScreen"));
        $(this).toggleClass("re-fullscreen re-normalscreen");
      };
      $element.children(".CodeMirror").prepend(fullscreen);
    },
  };
});

CodeMirror.defineMode("cartocss", function (config, parserConfig) {
  var cartoOverlay = {
    token: function (stream, state) {
      let rgb = /\([0-9]{1,3},\s?[0-9]{1,3},\s?[0-9]{1,3}(,\s?(0\.[0-9]{1,3}|1))?\)/;
      let hex = /#([0-9A-Fa-f]{3}){1,2}/;
      let ch = stream.peek();

      // Match hex, but not css ids
      if (ch == "#" && stream.match(/#/, false)) {
        stream.eat(/#/);
        stream.match(/(?!.*\s*{)([0-9A-Fa-f]{1,6})/, true);
        if (stream.current().length > 1 || stream.eol() || stream.match(";", false)) return "atom color";
      }

      if (ch == "r" && stream.match(/rgb(a)?/, true)) {
        stream.match(rgb, true);
        return "atom color";
      }

      if (stream.match("setMarker(")) {
        stream.backUp(1);
        this.markerState = [stream.current()];
        return "atom marker";
      }

      if (ch == "(" && this.markerState) {
        stream.next();
        this.markerState.push("(");
        return null;
      }

      if (this.markerState && this.markerState.length == 2 && this.markerState.join("") == "setMarker(") {
        stream.match(/(.*)(?:\))/, true);
        stream.backUp(1);
        this.markerState = null;
        return "string markerUrl";
      }

      if (stream.next() == null) return null;
    },
  };
  return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "css"), cartoOverlay);
});
