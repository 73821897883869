/**
 * Resource for emails
 */

angular.module("modules:admin").factory("Email", function (Restangular) {
  var Email = Restangular.all("system/emails");

  Restangular.addElementTransformer("system/emails", true, function (emails) {
    emails.addRestangularMethod("create", "post");
    emails.addRestangularMethod("search", "getList", "search");

    return emails;
  });

  Restangular.addElementTransformer("system/emails", false, function (email) {
    email.saveEmail = function () {
      return email.put();
    };

    return email;
  });

  return Email;
});
