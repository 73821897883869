/**
 * A directive that scrolls the bottom or top of an element
 */

angular.module("common").directive("scrollTo", function ($parse) {
  return {
    scope: {
      direction: "@",
      animate: "@",
      willScroll: "=scrollTo",
    },
    link: function ($scope, $element, $attrs) {
      $scope.$watch("willScroll", function (willScroll, oldVal) {
        if (!willScroll || willScroll == oldVal) return;

        if ($scope.direction == "top") var scrollTop = 0;
        else if ($scope.direction == "bottom") var scrollTop = $element[0].scrollHeight;

        $element.animate({ scrollTop: scrollTop }, $scope.animate ? 300 : 0, function () {
          $(window).trigger("resize");
        });

        $scope.willScroll = false;
      });
    },
  };
});
