/**
 * @name cmColorPicker
 * @description Color picker plugin for CodeMirror
 * Requires the CodeMirror.cartocss mode, or the color to be wrapped in `cm-url`. Example: `<span>#FFF</span>` `<span>rgb(255,255,255)</span>`
 * Supports hex, short hex, rgb, and rgba
 *
 * @requires common.codeMirror
 *
 * @example
 *
 * <code-mirror options="options" ng-model="model" cm-color-picker></code-mirror>
 */
angular.module("common").directive("cmColorPicker", function () {
  return {
    restrict: "A",
    require: "^codeMirror",
    link: function ($scope, $element, $attrs, $controllers) {
      let editor = $element.children(".CodeMirror")[0].CodeMirror;
      let wrapper = $(editor.getWrapperElement());

      wrapper.on("keyup", createColorBoxes);
      $scope.$on("codeMirrorReady", createColorBoxes);

      // If there are color pickers around, delete them!
      wrapper.on("mouseup", function (event) {
        if (!$(event.target).parents(".sp-container").length) wrapper.children(".sp-container").remove();
      });

      function createColorBoxes() {
        wrapper.find(".cm-color").each(function () {
          let element = $(this);

          let colorBox = element.children(".color-box");
          if (!colorBox.length) {
            let lineNumber = element.parent().parent().prev().children().text();
            colorBox = $(`<span class="color-box box" line-number="${lineNumber - 1}"></span>`);
            colorBox.on("click", onColorBoxClick);
            element.prepend(colorBox);
          }

          colorBox.css("background", element.text());
        });
      }

      function onColorBoxClick(event) {
        let colorElement = $(event.target).parent();
        let lineNumber = parseInt($(event.target).attr("line-number"));

        let startingRange = editor.getLine(lineNumber).indexOf(":") + 2;

        let picker = $(`<input type="text" value="${colorElement.text()}" />`);

        wrapper.prepend(picker);
        picker.spectrum({
          flat: true,
          showButtons: false,
          showAlpha: true,
          preferredFormat: "hex",
          move: function (color) {
            editor.replaceRange(color.toString() + ";", { line: lineNumber, ch: startingRange }, { line: lineNumber });
            createColorBoxes();
          },
        });

        let container = picker.spectrum("container");
        container.css("position", "absolute");
        container.css($(event.target).position());
      }
    },
  };
});
