angular.module("common").directive("epiConfirm", function () {
  return {
    restrict: "A",
    scope: {
      text: "@epiConfirm",
      yes: "@confirmYes",
      no: "@confirmNo",
      idiotTest: "@confirmIdiot",
      details: "@confirmDetails",
      callback: "&confirmCallback",
      stopPropagation: "=",
    },
    controller: function ($scope, $uibModal) {
      /**
       * Open the confirmation dialog modal
       * @return {void}
       */
      $scope.open = function () {
        var modal = $uibModal.open({
          templateUrl: "/directives/epi-confirm.html",
          controller: [
            "$scope",
            "$sce",
            function ($uibModalScope, $sce) {
              // set some default text values
              $uibModalScope.text = $scope.text ? $scope.text : "Are you sure you want to proceed?";
              $uibModalScope.yes = !!$scope.yes ? $scope.yes : "Proceed";
              $uibModalScope.no = $scope.no ? $scope.no : "Cancel";
              $uibModalScope.idiotTest = $scope.idiotTest;
              $uibModalScope.details = $sce.trustAsHtml($scope.details);
              $uibModalScope.callback = $scope.callback;

              // Keeps track of whether the form has been submitted
              $scope.hasSubmitted = false;

              if (!$scope.idiotTest) {
                $uibModalScope.$on("enter", function () {
                  $uibModalScope.submit();
                });
              }

              /**
               * Close the modal and fire the callback
               * @return {void}
               */
              $uibModalScope.submit = function () {
                // Make sure that the form isn't submitted twice
                if ($scope.hasSubmitted) return;
                $scope.hasSubmitted = true;
                $scope.callback();
                modal.close();
              };

              /**
               * Dismiss the modal
               * @return {void}
               */
              $uibModalScope.cancel = function ($event) {
                if ($scope.stopPropagation) $event.stopPropagation();
                modal.dismiss("cancel");
              };

              $uibModalScope.click = function ($event) {
                if ($scope.stopPropagation) $event.stopPropagation();
              };
            },
          ],
        });
      };
    },

    // Set the click event to open up the modal
    link: function (scope, element, attrs) {
      element.click(scope.open);
    },
  };
});
