/**
 * @name DateRange
 * @memberOf common
 * @ngdoc service
 * @description Service to interact with date ranges
 */
angular.module("common").service("DateRange", function (Session, $state) {
  var first12 = moment();
  first12.month(first12.month() - 12);

  var last12 = moment();
  last12.month(last12.month() - 1);

  var last12Text = "(" + first12.format("MMM YY") + " - " + last12.format("MMM YY") + ")";

  this.ranges = [
    { value: "all", name: "All" },
    { value: "today", name: "Today" },
    { value: "2", name: "Last 2 Days" },
    { value: "14", name: "Last 14 Days" },
    { value: "30", name: "Last 30 Days" },
  ];

  this.complexRanges = [
    { value: "mtd", name: "This Month" },
    { value: "last-month", name: "Last Month" },
    { value: "last-quarter", name: "Last Quarter" },
    { value: "ytd", name: "This Year" },
    { value: "last-year", name: "Last Year (" + (new Date().getFullYear() - 1) + ")" },
    { value: "12-months", name: "Last 12 Months " + last12Text },
  ];

  this.getRanges = function (complex) {
    var ranges = angular.copy(this.ranges);
    if (complex) ranges = ranges.concat(angular.copy(this.complexRanges)).concat(this._getFiscalYear());

    ranges.push({ value: "custom", name: "Custom" });

    return ranges;
  };

  this._getFiscalYear = function () {
    let entity = this._getEntity();
    if (entity && entity.fyMonth && entity.fyDay) return [{ value: "fytd", name: "Fiscal Year To Date" }];
    return [];
  };

  this._getEntity = function () {
    return _.find(Session.getAll().entityList, { ID: parseInt($state.params.entityID) });
  };

  this.getRange = function (preset) {
    if (typeof preset == "undefined") return;

    var range = { range: preset };

    switch (preset) {
      case "all":
        range.minDate = new Date();
        range.maxDate = new Date();
        break;

      case "today":
        range.minDate = new Date();
        range.maxDate = new Date();
        break;

      case "mtd":
        range.minDate = new Date();
        range.minDate.setDate(1);
        range.maxDate = new Date();
        break;

      case "last-month":
        range.minDate = new Date();
        var month = range.minDate.getMonth() - 1;
        range.minDate.setMonth(month);
        range.minDate.setDate(1);

        range.maxDate = new Date();
        range.maxDate.setDate(0);

        break;

      case "last-quarter":
        range.minDate = new Date();
        range.minDate = new Date(range.minDate.getFullYear(), range.minDate.getMonth() - (range.minDate.getMonth() % 3));
        range.minDate.setHours(0, 0, 0, 0);
        range.minDate = moment(range.minDate).subtract(1, "quarters").toDate();

        range.maxDate = moment(range.minDate).add(1, "quarters").subtract(1, "days").toDate();
        range.maxDate.setHours(23, 59, 59, 999);

        break;

      case "ytd":
        range.minDate = new Date();
        range.minDate.setDate(1);
        range.minDate.setMonth(0);
        range.minDate.setHours(0, 0, 0, 0);

        range.maxDate = new Date();

        break;
      case "last-year":
        var lastYear = new Date().getFullYear() - 1;
        range.minDate = new Date(lastYear, 0, 1);
        range.maxDate = new Date(lastYear, 12, 0);

        break;
      case "12-months":
        range.minDate = new Date();
        range.minDate.setMonth(range.minDate.getMonth() - 12);
        range.minDate.setDate(1);

        range.maxDate = new Date();
        range.maxDate.setDate(0);

        break;

      case "fytd":
        let entity = this._getEntity();
        range.minDate = moment({ month: entity.fyMonth - 1, day: entity.fyDay });

        break;

      // a custom date range just means they've changed it, so don't bother setting anything
      case "custom":
        range.minDate = preset.minDate;
        range.maxDate = preset.maxDate;
        break;

      // by default preset will be an integer number of days that we'll substract from today for minDate
      default:
        range.minDate = new Date();
        range.minDate.setDate(range.minDate.getDate() - preset);
        range.maxDate = new Date();
    }

    return range;
  };
});
