/**
 * Upload button for Fine Uploader
 * Note: requires the FineUploader directive
 * @ngdoc directive
 *
 * @example
 *   <fine-uploader options="options">
 *     <button class="btn">Select new file(s)</button>
 *   </fine-uploader>
 *
 * @todo Ultimately support needs to be added for multiple upload buttons per Fine Uploader instance, with custom options for each. See: http://docs.fineuploader.com/features/extra-buttons.html
 */

angular.module("common").directive("fineUploaderButton", function () {
  return {
    restrict: "A",
    require: "^fineUploader",
    link: function (scope, element, attrs, fineUploaderCtrl) {
      fineUploaderCtrl.addButton(element);
    },
  };
});
