angular.module("modules:admin").factory("Permission", function (Restangular, $log) {
  var Permission = Restangular.one("permissions");

  // add a custom methods to collection
  Restangular.addElementTransformer("permissions", true, function (permission) {
    // don't try to work on uninitialized collection
    if (!("one" in permission)) return permission;

    permission.addRestangularMethod("search", "getList", "search");

    return permission;
  });

  /**
   * Makes a request to get the permissions for an entity
   * @param  {integer} entityID
   * @param  {integer} userID
   * @return {object} the promise for the request
   */
  Permission.getEntityPermissionList = function (entityID, userID) {
    var promise = Permission.customGET("", {
      entityID: entityID,
      userID: userID,
    });

    // Process the return data and add the slug for each entity and the unassigned permissions for each entity
    promise.then(function (response) {
      for (var permission in response.children) {
        response.children[permission].permission = "entity." + entityID + "." + permission;

        var unassignedPermissions = response.children[permission].unassigned;

        if (unassignedPermissions) {
          if (response.children[permission].children instanceof Array) response.children[permission].children = {};

          for (var unassigned in unassignedPermissions) {
            response.children[permission].children[unassigned] = unassignedPermissions[unassigned];
            response.children[permission].children[unassigned].permission = response.children[permission].permission + "." + unassigned;
            response.children[permission].children[unassigned].isUnassigned = true;
          }
        }
      }
    });
    return promise;
  };

  /**
   * Makes a request to get a specific permission string (and children)
   * @param  {array} permissionArray
   * @param  {integer} entityID
   * @param  {integer} userID
   * @return {object} the promise for the request
   */
  Permission.getPermission = function (permissionArray, entityID, userID) {
    var permissionString = permissionArray.join(".");
    var promise = Permission.customGET(permissionString, {
      entityID: entityID,
      userID: userID,
    });

    // After the promise is fulfilled, process all the entries and add a slug for the permission
    promise.then(function (response) {
      response.permission = "entity." + entityID + "." + permissionString;

      if (!response.children || (!_.isEmpty(response.unassigned) && response.children instanceof Array)) response.children = {};

      for (var permission in response.unassigned) {
        response.children[permission] = response.unassigned[permission];
        response.children[permission].isUnassigned = true;
      }

      for (var permission in response.children) {
        response.children[permission].permission = response.permission + "." + permission;
      }
    });
    return promise;
  };

  return Permission;
});
