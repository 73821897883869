/**
 * Validator service
 *   (used by epi-validate to validate controls against rules)
 * Usage (in epi-validate):
 *    [rule]:[arg1],[arg2],[arg3...]|[rule2]:[arg1...]
 */

angular.module("common").factory("Validator", function ($parse) {
  var Validator = function () {
    // default error messages
    this._messages = {
      required: "This field is required",
      requiredIf: "This field is required",
      alpha: "This field must be letters only",
      alphanumeric: "This field must be letters and numbers only",
      decimal: "This field must be a decimal number",
      min: function (args) {
        return "This field must be at least " + args[0] + " characters long";
      },
    };

    // custom validation rules & messages
    this._customRules = {};
    this._customMessages = {};

    // the default validation rules
    this._validationRules = {
      /**
       * required: make sure there is a value
       * @param  {string} value the control's value
       * @return {boolean} whether the control is valid
       */
      required: function (value) {
        return !(typeof value == "undefined" || value === "" || value === null || (value instanceof Array && value.length == 0));
      },

      /**
       * email: validates an email
       * @param  {string} value the control's value
       * @return {boolean} whether the control is valid
       */
      email: function (value) {
        var regex = /^[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,}$/;
        return regex.test(value);
      },

      /**
       * Accepts a list of valid email email addresses delimited by commas
       * @param {string} value
       * @return {boolea}
       */
      emails: function (value) {
        var emails = value.split(",");
        for (var i = 0; i < emails.length; i++) {
          if (!this._validationRules.email(emails[i].trim())) return false;
        }
        return true;
      },

      /**
       * requiredIf: required only if another control equals a certain value
       *         Example: requiredIf:type,1 (required if "type" equals "1")
       * @param  {string} value the control's value
       * @return {boolean} whether the control is valid
       */
      requiredIf: function (value, args, controls) {
        var otherVal = typeof controls[args[0]] != "undefined" ? controls[args[0]] : null,
          match = args[1];
        if (otherVal == match) {
          return this._validationRules.required(value, args);
        }
        return true;
      },

      /**
       * requiredWith: required only if another control's value is presentg
       *         Example: requiredWith:password (required if "password" has a value)
       * @param  {string} value the control's value
       * @param  {array} args the arguments passed into epi-validate
       * @param  {object} controls all the controls
       * @return {boolean} whether the control is valid
       */
      requiredWith: function (value, args, controls) {
        if (!controls[args[0]]) return true;
        return this._validationRules.required(value, args);
      },

      /**
       * matches: does this control match another control's value?
       *         Example: matches:password
       * @param  {string} value the control's value
       * @param  {array} args the arguments passed into epi-validate
       * @param  {object} controls all the controls
       * @return {boolean} whether the control is valid
       */
      matches: function (value, args, controls) {
        return value == controls[args[0]];
      },

      /**
       * min: required a minimum length
       * @param  {string} value the control's value
       * @param  {array} args the arguments passed into epi-validate
       * @return {boolean} whether the control is valid
       */
      min: function (value, args) {
        return value && value.length >= args[0];
      },

      /**
       * alpha: requires an alpha (not numeric) value
       * @param  {string} value the control's value
       */
      alpha: function (value) {
        var regex = /[^A-z]/;
        return !regex.test(value);
      },

      /**
       * alphanumeric: requires an alphanumeric value
       * @param  {string} value the control's value
       */
      alphanumeric: function (value) {
        var regex = /[^A-z0-9]/;
        return !regex.test(value);
      },

      /**
       * decimal: requires an decimal value
       * @param  {string} value the control's value
       */
      decimal: function (value) {
        var regex = /^-?\d+(\.\d+)?$/;
        return regex.test(value);
      },

      /**
       * regex: tests a control's value against a regex
       *       Example: regex:[^A-z]
       * @param  {string} value the control's value
       * @param  {array} args the array of arguments
       */
      regex: function (value, args) {
        var regex = new RegExp(args[0]);
        return regex.test(value);
      },

      /**
       * unique: tests a control's value against a list of data
       *         Example: unique:list,property,excludedIndex
       *
       *         excludedIndex can also be a string, which will look on scope to find a property with that name
       * @param  {string} value the control's value
       * @param  {array} args the array of arguments
       * @param  {array} values the array of other controls
       * @param  {object} scope the current $scope
       */
      unique: function (value, args, values, scope) {
        var search = {};
        search[args[1]] = value;

        var list = $parse(args[0])(scope);

        var found = _.find(list, function (item, index) {
          if (index == args[2] || index == scope[args[2]]) return false;
          else if (item[args[1]] == value) return true;
        });
        return !found || false;
      },

      /**
       * eq: checks to see if the value equals a string value
       *     Example: eq:Matthew
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      eq: function (value, args) {
        return value == args[0];
      },

      /**
       * ne: checks to see if the value doesn't equal a string value
       *     Example: ne:Matthew
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      ne: function (value, args) {
        return value != args[0];
      },

      /**
       * in: checks to see if a value is contained in a given array of values
       *     Example: in:One,Two,Three
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      in: function (value, args) {
        return args.indexOf(value) != -1;
      },

      /**
       * not_in: checks to see if a value is *not* contained in a given array of values
       *       Example: not_in:One,Two,Three
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      not_in: function (value, args) {
        return args.indexOf(value) == -1;
      },

      // For all four comparison rules (gt, gte, lt, lte), the comparison value is first checked to see if it's a number,
      //  if so, it compares it as a number, else it compares it lexographically (the default JavaScript behavior when comparing two strings)

      /**
       * gt: tests to see if the value is greater than a given value,
       *   first parsing it as a number, and comparing numbers, else comparing a string
       *   Example: gt:5
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      gt: function (value, args) {
        var comparison = isNaN(parseFloat(args[0])) ? args[0] : parseFloat(args[0]);
        return value > comparison;
      },

      /**
       * gt: tests to see if the value is less than a given value,
       *   first parsing it as a number, and comparing numbers, else comparing a string
       *   Example: lt:5
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      lt: function (value, args) {
        var comparison = isNaN(parseFloat(args[0])) ? args[0] : parseFloat(args[0]);
        return value < comparison;
      },

      /**
       * gte: tests to see if the value is greater than or equal to a given value,
       *   first parsing it as a number, and comparing numbers, else comparing a string
       *   Example: gte:5
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      gte: function (value, args) {
        var comparison = isNaN(parseFloat(args[0])) ? args[0] : parseFloat(args[0]);
        return value >= comparison;
      },

      /**
       * lte: tests to see if the value is less than or equal a given value,
       *   first parsing it as a number, and comparing numbers, else comparing a string
       *   Example: lte:5
       * @param  {string} value
       * @param  {array} args
       * @return {boolean}
       */
      lte: function (value, args) {
        var comparison = isNaN(parseFloat(args[0])) ? args[0] : parseFloat(args[0]);
        return value <= comparison;
      },
    };

    /**
     * Validate a single value
     * @param  {mixed} value
     * @param  {string} rules Pipe-delimited string of rules
     * @param  {object} messages Hash of messages to override defaults
     * @return {boolean|array} If valid, returns boolean true; else return array of error messages
     */
    this.validate = function (value, rules, messages, controls, $scope) {
      // compile rules string into an object of functions
      var compiled = this._compileRules(rules),
        valid = true,
        errors = [];

      // If there are no rules
      if (!compiled) return true;

      // If it's not required and it's empty, skip the rest of the rules
      if (!this._isRequired(compiled, controls) && !this._validationRules.required(value)) return true;

      // call each function, passing in the value
      for (var name in compiled) {
        valid = compiled[name].func.call(this, value, compiled[name].args, controls, $scope);
        // console.log('Result of "'+name+'" test: '+(valid === true ? 'passed' : 'failed'));

        // if true, do nothing
        // if false, add appropriate error message to array
        if (!valid) errors.push(this._getMessage(name, messages, compiled[name].args));
      }

      if (errors.length) return errors;

      return true;
    };

    /**
     * Add an app-wide custom rule
     * @param {string} name
     * @param {string} message
     * @param {function} func
     * @return {void}
     */
    this.createCustomRule = function (name, message, func) {
      this._customRules[name] = func;
      if (message) this._customMessages[name] = message;
    };

    /**
     * Check to see if a given rule exists
     * @param  {string} name
     * @return {boolean}
     */
    this.ruleExists = function (name) {
      if (this._validationRules[name] || this._customRules[name]) return true;
      return false;
    };

    /**
     * Compile a string of rules into a hash of validation functions
     * @param  {string} rulesStr
     * @return {object}
     */
    this._compileRules = function (rulesStr) {
      var compiled = {},
        rules = rulesStr.length ? rulesStr.split("|") : false,
        funcName = "",
        args = {},
        func;

      if (!rules) return false;

      for (var i = 0; i < rules.length; i++) {
        // expected format is "function:arg1,arg2,arg3|function2:arg1,arg2,arg3..."

        // Grab the index of the first ":"
        var index = rules[i].indexOf(":");

        // The function name is up to the first colon (allows colons in the args list)
        funcName = rules[i].substring(0, index) || rules[i];

        // the args are everything after the colon (omit the colon by adding one to it)
        if (index == -1) args = [];
        else {
          args = rules[i].substring(index + 1);

          if (args.length) args = args.split(",");
        }

        // find rule function in defaults
        if (funcName in this._validationRules) func = this._validationRules[funcName];
        // otherwise look for it in extended rules
        else if (funcName in this._customRules) func = this._customRules[funcName];
        else throw new ReferenceError('No validation rule named "' + funcName + '" could be found.');

        // save it in the compiled object
        compiled[funcName] = {
          func: func,
          args: args,
        };
      }

      return compiled;
    };

    /**
     * Get the appropriate error message for a given rule
     * @param  {string} ruleName
     * @param {object} messages Hash of messages to override defaults
     * @return {string}
     */
    this._getMessage = function (ruleName, messages, args) {
      if (messages && ruleName in messages) return messages[ruleName];
      if (ruleName in this._customMessages) return this._customMessages[ruleName];

      var rule = this._messages[ruleName];
      return typeof rule == "function" ? rule(args) : rule;
    };

    /**
     * Checks to see if a control is required
     * @param {object} compiled
     * @param {object} controls
     * @return {boolean}
     */
    this._isRequired = function (compiled, controls) {
      // if required rule is present
      if ("required" in compiled) return true;

      // if requiredWith is present, run required on the other control
      if ("requiredWith" in compiled) {
        return this._validationRules.required(controls[compiled.requiredWith.args[0]]);
      }

      // if requiredIf is present, check to make sure the control
      if ("requiredIf" in compiled) {
        return controls[compiled.requiredIf.args[0]] == compiled.requiredIf.args[1];
      }

      return false;
    };
  };

  return new Validator();
});
