/**
 * Directive for toggling fade in/out
 */

angular.module("common").directive("fadeAway", function ($parse, $timeout) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      scope.$watch(attrs.fadeAway, function (val, oldVal) {
        // for the first time run (if val & oldVal equal each other),
        // *AND* it's false
        if (val == oldVal && !val) {
          // Hide the element, and return
          element.hide();
          return;
        }

        let method;

        // Standard watch
        if (val) method = "fadeIn";
        else method = "fadeOut";

        element.addClass("isFadingAway");
        element[method](function () {
          element.removeClass("isFadingAway");
        });
      });
    },
  };
});
