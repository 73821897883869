export interface IMapAction {
  ID?: number;
  type: string;
  label?: string;
  verb?: string;
  refreshDownstream: boolean;
  defaultName?: string;
  reloadOnCompletion?: boolean,
  enabled: boolean;
  sorder: number;
  sourceID?: number;
  targetID?: number;
  scope?: Interaction[];
  createdAt?: string;
  updatedAt?: string;
}

export enum Interaction {
  SELECT = "SELECT",
  CLICK = "CLICK",
}