const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

/**
 * Create a new user
 */

angular.module("routes:admin").controller("admin:create-user", function ($rootScope, $scope, $log, $state, User) {
  store.dispatch(updateGlobalState({ title: "Create User" }));
  $scope.user = {
    activated: true,
    multisession: true,
  };
  $scope.processing = false;

  $scope.update = function (user) {
    var form = $scope.validateForm("userForm");
    if (form.isValid()) {
      $scope.processing = true;
      User.post(user)
        .then(
          function (response) {
            $scope.users.push(response);
            $state.go("^");
          },
          function (response) {
            $scope.errors = response.data.meta.errors;
          }
        )
        .finally(function () {
          $scope.processing = false;
        });
    }
  };
});
