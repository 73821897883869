angular.module("common").directive("epiFilter", function () {
  return {
    restrict: "A",
    scope: {
      filter: "=epiFilter",
      columns: "=filterColumns",
      operators: "=filterOperators",
      attribute: "@filterAttribute",
      attributeClass: "@filterAttributeClass",
      operatorClass: "@filterOperatorClass",
      valueClass: "@filterValueClass",
    },
    templateUrl: "/directives/epi-filter.html",

    compile: function (element, attrs) {
      // set default attribute model name
      if (!attrs.filterAttribute) attrs.filterAttribute = "column";

      // return the link function
      return function (scope, element, attrs) {
        scope.isArray = scope.columns instanceof Array;

        function toString(value) {
          return value instanceof Array ? value.join(",") : value;
        }

        function parse(value) {
          if (scope.filter.operator.toUpperCase() == "IN" || scope.filter.operator.toUpperCase() == "NOT IN") {
            return typeof value == "string" && value.length ? value.split(",") : [];
          }
          return toString(value);
        }

        var operatorModelCtrl = element.find('[ng-model="filter.operator"]').controller("ngModel"),
          valueModelCtrl = element.find('[ng-model="filter.value"]').controller("ngModel");

        // format the value for display (join arrays into string)
        valueModelCtrl.$formatters.push(toString);

        // format the input for saving (parse string into array if necessary)
        valueModelCtrl.$parsers.push(parse);

        // reparse value when operator changes
        operatorModelCtrl.$viewChangeListeners.unshift(function () {
          scope.filter.value = parse(scope.filter.value);
        });
      };
    },
  };
});
