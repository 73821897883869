/**
 * Edit Entity Controller
 */

angular.module("routes:admin").controller("admin:edit-entity", function ($scope, $stateParams) {
  var id = $stateParams.id;

  // has this entity already been loaded?
  var entity = $scope.entities.find(id);
  if (entity) $scope.entity = entity;
  else
    $scope.entities.get(id).then(function (entity) {
      $scope.entity = entity;
    });

  $scope.processing = false;

  $scope.update = function (entity) {
    if ($scope.processing == true) return;

    $scope.processing = true;
    entity.put().then(function (response) {
      $scope.processing = false;
    });
  };
});
