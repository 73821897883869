import angular from "angular";
import { react2angular } from "react2angular";
import { AngularButton, AngularLoadingButton, Button, LoadingButton } from "./Button";
import { TextField } from "./TextField";
import { AngularSmallLoadingIndicator, SmallLoadingIndicator } from "./SmallLoadingIndicator";

export default angular
  .module("design", [])
  .component("epiButton", AngularButton)
  .component("epiLoadingButton", AngularLoadingButton)
  .component("epiSmallLoadingIndicator", AngularSmallLoadingIndicator).name;
