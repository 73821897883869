import "./import-jquery";

import "jquery-ui/dist/jquery-ui";

import _ from "lodash";
window._ = _;

import L from "leaflet";
window.L = L;

L.Icon.Default.imagePath = "/";

import moment from "moment";
window.moment = moment;

import qq from "../node_modules/fine-uploader/lib/jquery/s3";
window.qq = qq;

import numeral from "numeral";
window.numeral = numeral;

import "jquery.nicescroll/dist/jquery.nicescroll.min";
import "../node_modules/jquery-ui-touch-punch/jquery.ui.touch-punch";
import "leaflet-draw";
import "../node_modules/@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";

import "redactor";
import "redactor/plugins/fontcolor";
import "redactor/plugins/fontsize";
import "redactor/plugins/fullscreen";

import "angular";
import "../node_modules/angular-promise-tracker/promise-tracker";
import "spectrum-colorpicker";
import "angular-spectrum-colorpicker";
import "../node_modules/checklist-model/checklist-model";
import "angular-animate";
import "angular-sanitize";
import "../node_modules/ngGeolocation/ngGeolocation";
import "ngprogress-lite";
import "restangular";
import "angular-bootstrap";
import "angular-ui-router";
import "../node_modules/angular-ui-utils/ui-utils";
import "../node_modules/angular-xeditable/dist/js/xeditable";
import "angular-ui-sortable";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
