/**
 * Error handling controller (replaces routes/error)
 */

const { clearError } = require("../../../src/slices/appSlice");
const { store } = require("../../../src/store");

angular.module("routes").controller("error-handler", function ($rootScope, $scope, $log, $uibModal, $templateCache, $state, $http, API_ROOT, $timeout) {
  $scope.modalIsOpen = false;
  $rootScope.$on("httpError", function (event, data) {
    // If a modal is already open, cancel
    if ($scope.modalIsOpen) return;

    // Else, open the modal
    openModal(data.error.status);
  });

  store.subscribe(() => {
    const {
      app: { error },
    } = store.getState();
    if (error && !$scope.modalIsOpen) {
      openModal(error);
    }
  });

  function openModal(error) {
    var exception = error.exception;

    //if the error is a 403 error and there is no exception
    if (error.code == "403" && exception == "LoginRequiredException") {
      var template = "login";
      var controller = "login";

      //if there is an exception and there is a template and a controller for it
    } else if (typeof exception != "undefined" && typeof $templateCache.get("/" + exception + ".html") != "undefined" && typeof controllers[exception] != "undefined") {
      var template = exception;
      var controller = exception;

      //if there is no controller and there is a template
    } else if (typeof controllers[exception] == "undefined" && typeof $templateCache.get("/" + exception + ".html") != "undefined") {
      var template = exception;
      var controller = "generic";

      // otherwise just use a generic error
    } else {
      var template = "generic";
      var controller = "generic";
    }

    var modalInstance = $uibModal.open({
      templateUrl: "/" + template + ".html",
      controller: controllers[controller],
      resolve: {
        error: function () {
          return error;
        },
      },
      windowClass: "error-handler-modal",
      backdrop: "static",
      keyboard: controller == "login" ? false : true,
    });

    modalInstance.result.finally(function () {
      $scope.modalIsOpen = false;
      store.dispatch(clearError());
    });

    $scope.modalIsOpen = true;
  }

  function ctrlInit($scope, $modalInstance, $state, error) {
    $scope.error = error;

    /**
     * Close function to dismiss the modal and clean up the stuff left behind from the error
     * @param  optional {string} state - if set, goes to this state
     */
    $scope.close = function (state) {
      // Dismiss the modal
      $modalInstance.dismiss();

      //If there's a state set, go to it
      if (typeof state != "undefined") $state.go(state);
    };
  }

  var controllers = {
    login: [
      "$scope",
      "$modalInstance",
      "$http",
      "$state",
      "Session",
      "$timeout",
      "error",
      "$location",
      function ($scope, $modalInstance, $http, $state, Session, $timeout, error, $location) {
        ctrlInit($scope, $modalInstance, $state, error);
        $scope.login = {};
        $scope.didForgetPassword = false;
        $scope.error = error;

        $scope.isLoginPage = false;
        if ($state.current.name == "login") $scope.isLoginPage = true;

        $scope.loginSubmit = function (form, data) {
          //If the form isn't valid (client side)
          if (form.$invalid) {
            form.errors = {};
            if (form.uname.$invalid) {
              form.errors["NoUserName"] = "Please enter a username";
            }
            if (form.password.$invalid) {
              form.errors["NoPassword"] = "Please enter a password";
            }
          } else {
            $scope.request = true;

            //Post the login data
            $http
              .post(API_ROOT + "/login", data)
              .then(
                function (response) {
                  //If there aren't any errors, set the session from the response
                  Session.setAll(response.data.data);

                  //If this state is abstract or it's the login page
                  if ($state.current.abstract || $scope.isLoginPage) {
                    $timeout(function () {
                      // Include any query params that might be in the URL when forwarding to home.
                      $state.go("home", $location.search());
                    });
                  } else {
                    $state.go($state.current, {}, { reload: true });
                  }

                  //And finally, close the modal and destroy all the error stuff
                  $scope.close();
                },
                function ({ data: response }) {
                  //If there is an error, display it in the template
                  data.errors = {};
                  data.errors[response.status.exception] = response.status.message;
                }
              )
              .finally(function () {
                $scope.request = false;
              });
          }
        };

        $scope.goBackToLogin = function () {
          $scope.didForgetPassword = false;
        };

        $scope.goToForgotPassword = function () {
          $scope.didForgetPassword = true;
        };

        $scope.forgot = {};

        $scope.forgotPassword = function (form, data) {
          if (form.$invalid) {
          } else {
            $scope.request = true;

            $http
              .post(API_ROOT + "/password/remind", data)
              .then(
                function ({ data: response }) {
                  $scope.forgot.success = response.status.message;
                  $scope.forgot.errorMessage = null;
                  $scope.forgot.errors = null;
                },
                function ({ data: response }) {
                  $scope.forgot.success = false;
                  $scope.forgot.errorMessage = response.status.message;
                  $scope.forgot.errors = response.meta.errors;
                }
              )
              .finally(function () {
                $scope.request = false;
              });
          }
        };
      },
    ],
    newVersionAvailable: [
      "$scope",
      "$window",
      function ($scope, $window) {
        $scope.refresh = function () {
          $window.location.reload();
        };
      },
    ],
    generic: [
      "$scope",
      "$modalInstance",
      "$state",
      "error",
      function ($scope, $modalInstance, $state, error) {
        ctrlInit($scope, $modalInstance, $state, error);
      },
    ],
  };
});
