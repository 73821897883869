import { useState } from "react";
import { IMap } from "../../types/IMap";
import { ITruMap } from "../../types/ITruMap";
import { TruTerritoryNavbarLocation } from "./TruTerritoryNavbar/TruTerritoryNavbarLocation";

export const PublicControls = ({ truMap, map, onSearch }: { truMap?: ITruMap; map?: IMap; onSearch: (search: string) => void }) => {
  const [search, setSearch] = useState("");

  return (
    <div className="public-controls">
      <TruTerritoryNavbarLocation truMap={truMap} />
      <form
        className="location-search"
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(search);
        }}
      >
        <span className="input-search">
          <input type="text" placeholder="Search:" onChange={({ target }) => setSearch(target.value)} value={search} />
        </span>
      </form>
      <div className="map-name">{map?.name}</div>
    </div>
  );
};
