import { useEffect, useRef, useState } from "react";
import * as CodeMirror from "codemirror";

import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/lint/lint";
import "codemirror/addon/mode/overlay";
import "codemirror/mode/sql/sql";
import "codemirror/mode/css/css";
import "codemirror/addon/display/fullscreen";
import "./cartocss-mode";
import { ColorPicker } from "./color-picker";
import { ImageUploader } from "./image-uploader";
import { ImagePreview } from "./image-preview";
import { Errors } from "./errors";

export const CodeMirrorEditor = ({
  value,
  onChange,
  disabled = false,
  id,
  errors,
  mode,
  plugins = [],
}: {
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
  disabled?: boolean;
  id?: string;
  errors?: Record<number, string[]>;
  mode: "cartocss" | "text/x-pgsql";
  plugins?: ("color-picker" | "image-preview" | "image-uploader")[];
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [editor, setEditor] = useState<CodeMirror.EditorFromTextArea | undefined>();

  // initialize the editor
  useEffect(() => {
    let renderTimeout: NodeJS.Timeout;

    // for some reason this is necessary to get the editor to render properly
    renderTimeout = setTimeout(() => {
      // if the textarea isn't ready, the editor is already initialized,
      //  or the textarea is hidden (i.e., already initialized, but everything hasn't been set up yet),
      //  don't do anything
      if (!textAreaRef.current || editor || $(textAreaRef.current).css("display") === "none") return;

      clearTimeout(renderTimeout);

      let instance = CodeMirror.fromTextArea(textAreaRef.current, {
        indentWithTabs: true,
        smartIndent: true,
        lineNumbers: true,
        autofocus: true,
        theme: "neat epiphany",
        readOnly: disabled,
        mode,
        gutters: ["CodeMirror-lint-markers"],
      });
      setEditor(instance);
      instance.setValue(value || "");
      instance.focus();

      // set a debouncer for on change so react isn't spammed with events
      let timeout: NodeJS.Timeout;
      instance.on("change", (event) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          onChange?.(event.getValue());
        }, 350);
      });

      // set the id if it exists (mainly for styling for the expanded css editor)
      if (id) $(instance.getWrapperElement()).attr("id", id);

      // initialize the requisite addons
      if (plugins.includes("color-picker")) ColorPicker(instance);
      if (plugins.includes("image-preview")) ImagePreview(instance, ["cm-url"]);
      if (plugins.includes("image-uploader")) ImageUploader(instance, ["cm-marker", "cm-markerUrl"]);
    });
  }, [textAreaRef.current]);

  useEffect(() => {
    // if there's no editor or the value is the same, don't do anything
    if (!editor || editor.getValue() === value) return;

    // otherwise set the value
    editor.setValue(value || "");

    if (plugins.includes("color-picker")) ColorPicker(editor);
    if (plugins.includes("image-preview")) ImagePreview(editor, ["cm-url"]);
    if (plugins.includes("image-uploader")) ImageUploader(editor, ["cm-marker", "cm-markerUrl"]);
  }, [value]);

  useEffect(() => {
    if (!editor) return;
    Errors(editor, errors, textAreaRef.current);
  }, [errors]);

  return <textarea ref={textAreaRef} />;
};
