import { IModalProps, Modal } from "./Modal";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button } from "../design/Button";
import { TextField } from "../design/TextField";
import { Col } from "../design/layout/Col";
import { getService } from "react-in-angularjs";
import { sendError } from "../services/error";

export const ReportProblemModal = ({ isOpen, onClose }: Omit<IModalProps, "children">) => {
  const $state = getService("$state");
  const Session = getService("Session");
  const ErrorLogger = getService("ErrorLogger");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{ description: "" }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required(),
        })}
        onSubmit={async ({ description }, { setSubmitting, resetForm }) => {
          const error = {
            state: $state.current,
            session: Session.getAll(),
            userID: Session.get("ID"),
            location: window.location,
            errorLog: ErrorLogger.getAll(),
            message: description,
          };
          await sendError(error);
          setSubmitting(false);
          resetForm();
          onClose();
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} id="report-problem-form">
            <div className="modal-header">
              <h3>Report a problem</h3>
            </div>
            <div className="modal-body">
              <p>Please describe the problem you encountered, including any relevant details or error messages.</p>
              <Col breakpoint="md" size={12}>
                <TextField
                  multiline
                  name="description"
                  inputSize={12}
                  placeholder="Tell us what happened when you experienced the problem..."
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.description}
                  rows={4}
                />
              </Col>
            </div>
            <div className="modal-footer">
              <span className="btn plain" onClick={onClose}>
                Cancel
              </span>
              <Button type="submit" loading={isSubmitting}>
                Send feedback
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
