/**
 * Directive for the index creator (for collections)
 */

angular.module("routes:data").directive("indexesCreator", function () {
  return {
    restrict: "AEC",
    scope: {
      collection: "=",
      indexes: "=ngModel",
    },
    templateUrl: "/data/directives/indexes-creator.html",
    require: "ngModel",
  };
});
