/**
 * String manipulation service
 */

angular.module("common").factory("StringHelper", function () {
  var StringHelper = function () {};

  /**
   * Increments a letter to the next consecutive letter
   *   If "z" is passed in, it will go on to "aa", when "zz" => "aaa", and so on
   * @param  {string} text the letter to increment
   * @return {string}
   */
  StringHelper.increment = function (text) {
    var length = text.length,
      lastChar = text.charAt(text.length - 1),
      firstChar = text.charAt(0),
      character = String.fromCharCode(firstChar.charCodeAt(0) + 1);

    if (lastChar == "z" || lastChar == "Z") {
      if (lastChar == "z") character = "a";
      if (lastChar == "Z") character = "A";
      length++;
    }
    return Array(length + 1).join(character);
  };

  var _alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  StringHelper.getAlphabet = function () {
    return _alphabet;
  };

  return StringHelper;
});
