angular.module("modules").factory("User", function (Restangular) {
  var User = Restangular.all("users");

  // add a custom methods to collection
  Restangular.addElementTransformer("users", true, function (collection) {
    // don't try to work on uninitialized collection
    if (!("one" in collection)) return collection;

    collection.addRestangularMethod("search", "getList", "search");
    collection.addRestangularMethod("getEmails", "getList", "emails");

    return collection;
  });

  // add custom methods to individual user and imports
  Restangular.extendModel("users", function (user) {
    if (!("one" in user)) return user;

    // make sure permissions are an object, not an array
    if (user.permissions instanceof Array) user.permissions = {};

    user.savePermissions = function () {
      return user.customPUT(user.permissions, "permissions");
    };

    return user;
  });

  User.saveUser = function (user) {
    var filtered = {
      lname: user.lname,
      fname: user.fname,
      email: user.email,
      password: user.password,
      passwordConfirm: user.passwordConfirm,
    };

    if (filtered.password === null && filtered.passwordConfirm === null) {
      delete filtered.password;
      delete filtered.passwordConfirm;
    }

    return User.customPUT(filtered, user.ID);
  };

  User.getEmails = function (userID) {
    return User.customGETLIST(userID + "/emails");
  };

  return User;
});
