/**
 * Service for storing map reports on the backend
 *
 * THIS MIGHT BE NEEDED.... wait until proven needed
 *
 */

angular.module("modules:mapping").factory("MapReports", function (Restangular, Storage) {
  var storageKey = "truterritory-map-reports";

  var Reports = Restangular.all("mapping/reports");

  Restangular.addElementTransformer("mapping/reports", true, function (reports) {
    /**
     * Save all the reports
     * @return {Promise}
     */
    reports.saveAll = function () {
      return Reports.customPUT(reports);
    };

    return reports;
  });

  /**
   * Load all the reports
   * @return {Promise<Array>}
   */
  Reports.load = function () {
    // If there are reports in localStorage, migrate them to the backend, otherwise just make a request to get all of the reports
    return Storage.getItem(storageKey) ? Reports._migrate(Storage.getItem(storageKey)) : Reports.getList();
  };

  /**
   * Migrate the local storage reports to the database
   * @param  {array} reports
   * @return {Promise<Array>}
   */
  Reports._migrate = function (reports) {
    var toCreate = [];

    // Loop through all the reports, and create an object in the correct format
    for (var i = 0; i < reports.length; i++) {
      var report = reports[i];
      for (var j = 0; j < report.reports.length; j++) {
        toCreate.push({ mapID: report.reports[j], workspaceID: reports[i].ID, sorder: j });
      }
    }

    // PUT all the reports
    return Reports.customPUT(toCreate).then(function (reports) {
      // Remove from local storage
      Storage.removeItem(storageKey);
      Restangular.restangularizeCollection(null, reports, "mapping/reports");

      return reports;
    });
  };

  return Reports;
});
