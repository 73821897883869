/**
 * Color helper service
 * @param {Object|string} color
 *                Takes an rgb object, like so: {r: 255, g: 255, b: 255}
 *                Or a hex string: '000000', '#000000'
 *                Or an rgb string: '(255, 255, 255)'
 * @return {string} returns either 'dark' or 'light'
 */

angular.module("common").factory("ColorHelper", function () {
  var ColorHelper = {
    /**
     * Gets the contrast of a color (dark|light); used for text color
     * @param  {string} color
     * @param  {integer} [threshold] an integer that changes the threshold
     *                               for what is considered dark/light
     *                               Less = darker
     *                               Higher = lighter
     * @return {string} dark|light
     */
    getContrast: function (color, threshold) {
      var rgb = this.parseColor(color);

      if (rgb === null) return rgb;

      var brightness = rgb.r * 299 + rgb.g * 587 + rgb.b * 114,
        threshold = threshold || 0.8;
      brightness = brightness / 255000;

      // values range from 0 to 1
      // anything greater than 0.8 should be bright enough for dark text
      if (brightness >= threshold) return "dark";
      else return "light";
    },

    componentToHex: function (c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex: function (rgb, green, blue) {
      if (rgb instanceof Object) {
        var red = rgb.r;
        green = rgb.g;
        blue = rgb.b;
      } else var red = rgb;

      return "#" + this.componentToHex(red) + this.componentToHex(green) + this.componentToHex(blue);
    },
    hexToRgb: function (hex) {
      // Strip the pound symbol
      hex = hex.replace("#", "");

      // If it isn't a correct hex color, make it black
      if (hex.length != 3 && hex.length != 6) hex = "000";

      // If it's a three character string,
      //  split it and double each element
      if (hex.length == 3) {
        hex = hex.split("");
        for (var i = 0; i < hex.length; i++) {
          hex[i] += hex[i];
        }

        // If it's a six digit string,
        //  split it into three elements
      } else if (hex.length == 6) {
        var array = [];
        array.push(hex.substr(0, 2));
        array.push(hex.substr(2, 2));
        array.push(hex.substr(4, 2));
        hex = array;
      }

      // Return the parsed color as an object
      return {
        r: parseInt(hex[0], 16),
        g: parseInt(hex[1], 16),
        b: parseInt(hex[2], 16),
      };
    },

    /**
     * Brightens/darkens an rgb color
     * @param  {string} color
     * @param  {integer} percent a float where 1 = 100 percent
     * @return {object} the brightened color
     */
    brighten: function (color, percent, min) {
      if (!isNaN(parseInt(percent)) && percent !== 0) {
        var rgba = this.parseColor(color);
        for (var i in rgba) {
          if (i == "a") continue;

          rgba[i] += parseInt(percent * 255);

          if (rgba[i] < 0) rgba[i] = 0;

          if (rgba[i] > 255) rgba[i] = 255;
        }
      }
      return rgba;
    },

    /**
     * Parses a color and returns an RGB
     * @param  {string} color hex/rgb/rgba
     * @return {object|null} either an rgb(a) object or null
     */
    parseColor: function (color) {
      var hexRegex = /^([a-f0-9]{6}|[a-f0-9]{3})$/i,
        rgbRegex = /^(\d{1,3}),(\d{1,3}),(\d{1,3}),?([01]?(?:\.\d+)?)?$/,
        rgb = {},
        matches;

      if (!(color instanceof Object))
        var color = color
          .replace("#", "")
          .replace(/ /g, "")
          .replace(/rgb(a)?/gi, "")
          .replace(/(\()|(\))/gi, "");

      // If this is a hex string
      if (hexRegex.test(color)) {
        rgb = this.hexToRgb(color);
      } else if (color instanceof Object) {
        rgb = color;
      } else if ((matches = rgbRegex.exec(color)) && matches !== null) {
        rgb.r = parseFloat(matches[1], 10);
        rgb.g = parseFloat(matches[2], 10);
        rgb.b = parseFloat(matches[3], 10);
        if (matches[4]) rgb.a = parseFloat(matches[4], 10);
      }
      if (_.isEmpty(rgb)) return null;
      return rgb;
    },
  };

  return ColorHelper;
});
