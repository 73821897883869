/**
 * Main index.js module (routing)
 */

const { Theme } = require("../constants");

export default angular
  .module("routes", [require("./admin/index").default, require("./data/index").default, require("./error/index").default, require("./mapping/index").default])
  .config(function ($stateProvider, THEME) {
    $stateProvider
      .state("init", {
        abstract: true,
        template: `<epi-menu></epi-menu><div id="page-wrap" ui-view="" ng-class="{'single-module': menu.getEnabled().length <= 1}"></div>`,
        resolve: {
          session: function (Session) {
            return Session.startPolling(); // Returns the first result and continues getting updates to it
          },
        },
      })
      .state("home", {
        url: "/?docs",
        template: "<epi-home></epi-home>",
        parent: "init",
      })
      .state("login", {
        url: "/login",
      })
      .state("reset-password", {
        url: "/reset-password/:token",
        template: "<epi-reset-password></epi-reset-password>",
      })
      .state("post-login", {
        url: "/post-login",
        controller: "post-login",
        parent: "init",
      })
      .state("not-found", {
        url: "/not-found",
        templateUrl: "/not-found.html",
      })
      .state("my-profile", {
        url: "/my-profile",
        template: "<epi-my-profile-page />",
        parent: "init",
      })
      .state("test-sandbox", {
        url: "/test-sandbox",
        template: require("bundle-text:./views/test-sandbox.pug"),
        controller: require("./controllers/test-sandbox").default,
      });

    if (THEME !== Theme.morrowHill) {
      $stateProvider.state("faq", {
        url: "/faq",
        parent: "init",
        template: "<epi-faqs></epi-faqs>",
      });
    }
  }).name;

require("./controllers/error-handler");
require("./controllers/home-child");
require("./controllers/top-nav");
