const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

/**
 * Email-create.js controller
 */

angular.module("routes:admin").controller("admin:email-create", function ($rootScope, $scope, $stateParams, $log) {
  store.dispatch(updateGlobalState({ title: "Create new email" }));

  $scope.email = {};

  $scope.saveEmail = function (email, form) {
    $scope.validateForm("editEmail");
    if (form.isValid()) {
      $scope.saving = true;
      $scope.emails
        .create(email)
        .then(
          function (response) {
            $scope.saving = false;
            $scope.emails.push(response);
            $scope.closeRightSide();
          },
          function (response) {
            $scope.errors = response.data.meta.errors;
          }
        )
        .finally(function () {
          $scope.saving = false;
        });
    }
  };
});
