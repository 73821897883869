/**
 * @description Image uploader/picker plugin for CodeMirror
 * Requires the CodeMirror.cartocss mode, or the url() to have the class of `cm-url`, and the url string to have a class of `cm-urlString`. Example: `<span class="cm-url">url</span>(<span class="cmUrlString"></span>)`
 * @requires common.codeMirror
 *
 * @example
 *
 * <code-mirror options="options" ng-model="model" cm-image-uploader></code-mirror>
 */
angular.module("common").directive("cmImageUploader", function ($compile) {
  return {
    restrict: "A",
    require: "^codeMirror",
    link: function ($scope, $element, $attrs, $controllers) {
      let editor = $element.children(".CodeMirror")[0].CodeMirror;
      let wrapper = $(editor.getWrapperElement());

      let classes = $attrs.cmImageUploader.split(" ") || [];
      let urlClass = classes.splice(classes.length - 1, 1);

      wrapper.on("keyup", createPreviewBoxes);
      wrapper.on("click", function (event) {
        let target = $(event.target);
        if (!target.parents("fine-uploader").length && !target.hasClass("image-preview")) wrapper.children("fine-uploader").css("display", "none");
      });
      // $scope.$on('codeMirrorReady', createPreviewBoxes)

      createUploader();

      var selectedLineNumber, uploaderElement, uploaderInstance;

      function createPreviewBoxes() {
        if (!uploaderInstance) return;

        let files = uploaderInstance.getUploads();
        classes.forEach(function (classString) {
          wrapper.find(`.${classString}`).each(function () {
            let element = $(this);

            let previewBox = element.children(".image-preview");
            if (!previewBox.length) {
              let lineNumber = element.parent().parent().prev().children().text();
              previewBox = $(`<span class="image-preview box" line-number="${lineNumber - 1}"></span>`);
              previewBox.on("click", onPreviewBoxClick);
              element.prepend(previewBox);
            }

            let file = files.find(function (file) {
              return file.name == element.next().text() && !file.parentId;
            });

            let thumbnailUrl;
            if (file) thumbnailUrl = file.thumbnailUrl || uploaderInstance._thumbnailUrls[file.id];
            else thumbnailUrl = null;

            if (thumbnailUrl === null) previewBox.addClass("empty");
            else previewBox.removeClass("empty");

            previewBox.css("background-image", `url("${thumbnailUrl}")`);
          });
        });
      }

      function createUploader() {
        let scope = $scope.$new();
        scope.options = {
          directory: "mapping/media",
          gallery: true,
          scaling: {
            hideScaled: true,
            sizes: [{ maxSize: 100 }],
          },
          validation: {
            sizeLimit: 50000,
            image: {
              maxHeight: 512,
              maxWidth: 512,
            },
          },
          signature: {
            endpoint: "mapping/themes/media/sign",
          },
          uploadSuccess: {
            endpoint: "mapping/themes/media",
          },
          deleteFile: {
            endpoint: "mapping/themes/media",
          },
          session: {
            endpoint: "mapping/themes/media",
          },
          callbacks: {
            onSessionRequestComplete: function (response) {
              uploaderInstance = this;
              createPreviewBoxes();
            },
            onSelect: function (file) {
              if (!this.getParentId(file.id)) {
                let line = editor.getLine(selectedLineNumber);
                let endCh = line.indexOf(")") == -1 ? line.length : line.indexOf(")");
                let { start, end } = {
                  start: {
                    line: selectedLineNumber,
                    ch: line.indexOf("(") + 1,
                  },
                  end: {
                    line: selectedLineNumber,
                    ch: endCh,
                  },
                };

                let replacement = `${file.name}${endCh == line.length ? ");" : ""}`;

                editor.replaceRange(replacement, start, end);
                createPreviewBoxes();
                uploaderElement.css("display", "none");
              }
            },
          },
        };

        uploaderElement = $compile('<fine-uploader options="options"></fine-uploader>')(scope);
        uploaderElement.css("display", "none");
        wrapper.append(uploaderElement);
      }

      function addThumbnailPreviews(files, uploader) {
        $(`.${urlClass}`).each(function () {
          let elem = $(this);
          let file = _.find(files, { name: elem.text() });

          let thumbnailUrl = file.thumbnailUrl || uploader._thumbnailUrls[file.id];

          elem
            .prev("." + classes.join(",."))
            .children(".image-preview")
            .css("background-image", `url('${thumbnailUrl}')`);
        });
      }

      function onPreviewBoxClick(event) {
        selectedLineNumber = parseInt($(event.target).attr("line-number"));
        uploaderElement.css($(event.target).position());
        uploaderElement.css("display", "block");
      }
    },
  };
});
