/**
 * @name epiCheckbox
 * @ngdoc directive
 * @memberOf common
 * @scope true
 * @restrict C
 *
 * @attribute {String} inputId
 * @attribute {String} [value]
 * @attribute {String} [name]
 * @attribute {String} [label]
 * @attribute {String} model
 * @attribute {Expression} [change]
 * @attribute {Boolean} [disabled]
 *
 */

angular.module("common").directive("epiCheckbox", function ($parse, $log) {
  return {
    restrict: "C",
    templateUrl: "/directives/epi-checkbox.html",
    scope: {
      id: "@inputId",
      value: "@?value",
      name: "@?name",
      label: "@?label",
      model: "=model",
      change: "&?change",
      disabled: "=?disabled",
    },

    link: function (scope, element, attrs) {
      if (scope.model) scope.model = scope.model.toString();

      if (typeof scope.change == "undefined") {
        scope.change = function () {
          return;
        };
      } else {
        var oldFn = scope.change;
        scope.change = function () {
          var fn = $parse(oldFn);
          fn(scope);
        };
      }

      if (!scope.disabled) scope.disabled = false;
    },
  };
});
