import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { getService } from "react-in-angularjs";
import { react2angular } from "react2angular";
import { useAppSelector } from "../../hooks/useAppSelector";
import { withReduxProvider } from "../../services/withReduxProvider";
import { ICollection } from "../../types/ICollection";
import { createDateRange, createMaxDateRange } from "../../data/map";

// eventually move this to L.HistorySlider
interface ILHistorySlider {
  remove: () => void;
  initialize: (options: { minDate?: Moment; maxDate?: Moment; range?: { minDate: Moment; maxDate: Moment }; mode: number; interval: number }) => void;
  on: (event: string, callback: (data: any) => void) => void;
  addTo: (map: L.Map) => void;
  options: {
    minDate?: Moment;
    maxDate?: Moment;
    range?: { minDate: Moment; maxDate: Moment };
    mode: number;
    interval: number;
  };
}

interface HistorySliderProps {
  leafletMap: L.Map;
  onChange: (range: { minDate: Moment; maxDate: Moment }) => void;
}

const HistorySlider: React.FC<HistorySliderProps & JSX.IntrinsicAttributes> = ({ leafletMap, onChange }) => {
  const LHistorySlider = getService("HistorySlider") as () => ILHistorySlider;
  const [control, setControl] = useState<ILHistorySlider>();

  const {
    map,
    map: { sliderType: mode, sliderInterval: interval, dateEnabled } = {
      sliderType: 0,
      sliderInterval: 0,
      dateEnabled: false,
    },
    availableCollections,
  } = useAppSelector((state) => state.pages.truterritory);

  const maxDateRange = useMemo(() => {
    if (!map) {
      return { minDate: moment(), maxDate: moment() };
    }

    const collections = map?.collectionIDs
                          .map(id => availableCollections.find(c => c.ID == id))
                          .filter((c): c is ICollection => !!c)
                      || [];
    return createMaxDateRange(map, collections);
  }, [map, availableCollections]);

  const dateRange = useMemo(() => {
    if (!map) {
      return { minDate: moment(), maxDate: moment() };
    }

    return createDateRange(map);
  }, [map]);

  const rangesAreEqual = (range1: any, range2: any) => {
    return range1?.minDate?.format() === range2?.minDate?.format() && range1?.maxDate.format() === range2?.maxDate.format();
  };

  // initialize the control when the component is mounted
  useEffect(() => {
    if (control) {
      control.remove();
      setControl(undefined);
    }

    if (!dateEnabled) return;

    initialize();
    updateControlSettings();

    // Cleanup when the component is unmounted
    return () => {
      control?.remove();
    };
  }, [dateEnabled]);

  const initialize = () => {
    const control = LHistorySlider();

    setControl(control);

    control.on("change", (data: any) => {
      // Update the dateRange when the slider changes
      onChange(data.range);
    });

    control.addTo(leafletMap);
  };

  const updateControlSettings = async () => {
    if (!control || !(dateRange && maxDateRange)) return;

    control.initialize({
      minDate: dateRange?.minDate ?? maxDateRange?.minDate,
      maxDate: dateRange?.maxDate ?? maxDateRange?.maxDate,
      range: {
        minDate: maxDateRange?.minDate ?? moment(),
        maxDate: maxDateRange?.maxDate ?? moment(),
      },
      mode,
      interval,
    });
  };

  // this is used when the control is initialized to set the options
  useEffect(() => {
    if (control && !control.options.range?.minDate) {
      updateControlSettings();
    }
  }, [control]);

  // Update the slider to reflect the mode setting
  useEffect(() => {
    if (mode && control && mode !== control.options.mode) {
      control.remove();
      initialize();
      updateControlSettings();
    }
  }, [mode]);

  // Update the slider if needed when maxDateRange or interval changes
  useEffect(() => {
    if (!rangesAreEqual(maxDateRange, control?.options.range) || interval != control?.options.interval) {
      updateControlSettings();
    }
  }, [maxDateRange, interval]);

  return <></>;
};
export default HistorySlider;

export const AngularHistorySlider = react2angular(withReduxProvider(HistorySlider), ["leafletMap", "onChange"]);
