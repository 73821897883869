/**
 * Array manipulation service
 */

angular.module("common").factory("ArrayHelper", function () {
  var ArrayHelper = function () {};

  /**
   * Breaks an array on a split number
   * @param  {array} items
   * @param  {integer} splitNumber how big each array will be
   *                               i.e., a splitNumber of 5 will return arrays with a max length of 5
   * @param  {integer} [startAt=1]     the index to start counting at
   * @param  {boolean} [returnArray=false] whether to return an array of arrays
   * @return {array} either an array of objects
   *                        or if returnArray == true,
   *                        an array of arrays of objects
   */
  ArrayHelper.breakAt = function (items, splitNumber, startAt, returnArray) {
    // If there aren't any items, return
    if (!items || items.length == 0) {
      return;
    }

    // Initialize the new array
    var newItems = [];

    // to keep track of the new array's key
    var j = 0;

    // If this is returning an array,
    // this the the individual array's current key
    var i = 0;

    // to keep track of what number the loop is on
    var x = typeof startAt == "undefined" ? 1 : startAt;

    // Loop through all the items
    angular.forEach(items, function (val, key) {
      // If this array hasn't been initialized, do so
      if (!newItems[j]) {
        if (returnArray) {
          newItems[j] = [];
        } else {
          newItems[j] = {};
        }
      }

      // The key for the array (second level array)
      // If this is returning an array of arrays, it will be i (the array tracker key)
      // If it's not (i.e., it will return an array of objects), the key will be the current key (the "key" variable)
      var theKey = returnArray ? i : key;

      // Initalize the array with the key and set the object
      newItems[j][theKey] = {};
      newItems[j][theKey] = val;

      // Increment the array key
      i++;

      // If the loop has reached the split number
      if (x == splitNumber) {
        // Increment the array key
        j++;

        // Reset the current number
        x = 1;

        // Reset the array key
        i = 0;

        // Otherwise just increment the current number
      } else {
        x++;
      }
    });

    // And finally, after all that craziness, *whew*, return the final array
    return newItems;
  };

  /**
   * Make a dictionary out of an array using the specified element as id
   * @param {array} array
   * @param {string} id The name of the property to use a key
   * @return {object}
   */
  ArrayHelper.makeDict = function (array, id) {
    var dict = {};

    for (var i = 0; i < array.length; i++) {
      dict[array[i][id]] = array[i];
    }

    return dict;
  };

  return ArrayHelper;
});
