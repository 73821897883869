/**
 * Checks for the current version number
 */

export default angular.module("bootstrap.version", []).run(function ($timeout, $http, $rootScope, $window) {
  var currentVersion = $window.CURRENT_HASH;

  function getVersion() {
    $timeout(function () {
      $http.get("version.json?" + new Date().getTime()).then(function (data) {
        if (data.data.version != currentVersion) {
          $rootScope.$broadcast("httpError", { error: { status: { exception: "newVersionAvailable" } } });
          return;
        }
      });
      getVersion();
    }, 60 * 60 * 1000);
  }

  getVersion();
}).name;
