import { useState } from "react";
import { getService } from "react-in-angularjs";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ITruMap } from "../../../types/ITruMap";
import { setMarkerID } from "../../../slices/pagesSlice";

export const TruTerritoryNavbarLocation = ({ truMap }: { truMap?: ITruMap }) => {
  const dispatch = useAppDispatch();
  const [locationActive, setLocationActive] = useState(false);

  const { map, markerID } = useAppSelector((state) => state.pages.truterritory);

  const centerOnCurrentLocation = async () => {
    if (!map || !truMap) return;

    setLocationActive(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (markerID) truMap.removeLayer(markerID);

        const newMarkerID = truMap.addMarker([position.coords.latitude, position.coords.longitude]);

        truMap.setView([position.coords.latitude, position.coords.longitude], 15, { animate: true });

        dispatch(setMarkerID(newMarkerID));
        setLocationActive(false);
      },
      () => {
        setLocationActive(false);
      },
      {
        timeout: 60000,
      }
    );
  };
  return <button className={`btn location${locationActive ? " active" : ""}`} title="Show your current location on the map" onClick={centerOnCurrentLocation} />;
};
