/**
 * Broadcasts window events (such as keydown, & onbeforeunload)
 */

export default angular.module("bootstrap.events", []).run(function ($rootScope, $window) {
  // Broadcast window unload events
  $window.onbeforeunload = function (event) {
    var message = beforeUnload(event);
    if (message) return message;
  };

  /**
   * The handler for the unload events
   * @param  {object} event the event object
   * @return {string|void}
   */
  function beforeUnload(event) {
    var response = { event: event, message: "" };
    $rootScope.$broadcast("windowUnload", response);
    if (response.message) return response.message;
  }

  // Broadcast window unload events on state change
  $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
    var message = beforeUnload({ event: event, toState: toState, toParams: toParams, fromState: fromState, fromParams: fromParams }),
      stateEvent = event;

    // if there's a message and it hasn't been confirmed, i.e., false
    if (message && !confirm(message)) {
      // Prevent the state change and broadcast the cancel event
      stateEvent.preventDefault();
      $rootScope.$broadcast("$stateChangeCancel", {});
    }
  });

  $window.onclick = function (event) {
    $rootScope.$broadcast("click", event);
  };

  // Broadcast key events
  $window.onkeydown = function (event) {
    // Figure out which key to use (defaults to control)
    var commandKey = event.ctrlKey;
    var isMac;

    // If this is running on a Mac, set it to command (meta key)
    if ($window.navigator.appVersion.indexOf("Mac") != "-1") {
      commandKey = event.metaKey;
      isMac = true;
    }

    switch (event.keyCode) {
      // Z
      case 90:
        if (commandKey) {
          if (event.shiftKey && isMac) {
            $rootScope.$broadcast("redo");
            break;
          }
          $rootScope.$broadcast("undo");
        }
        break;

      // Y
      case 89:
        if (commandKey && !isMac) {
          $rootScope.$broadcast("redo");
        }
        break;

      // ?
      case 191:
        $rootScope.$broadcast("showHelp");
        break;

      // Enter key
      case 13:
        $rootScope.$broadcast("enter");
        break;
    }
  };
}).name;
