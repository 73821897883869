import "angular-xeditable/dist/css/xeditable.css";

export default angular.module("bootstrap.xeditable", []).run(function (editableOptions, editableThemes) {
  // set `default` theme
  editableOptions.theme = "default";

  // overwrite submit button template
  editableThemes["default"].submitTpl = '<button type="submit" class="btn">Save</button>';
  editableThemes["default"].cancelTpl = '<button type="button" class="btn" ng-click="$form.$cancel()">Cancel</button>';
}).name;
