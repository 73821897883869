import angular, { IScope } from "angular";
import { react2angular } from "react2angular";
import PropTypes from "prop-types";
import { useState } from "react";

import { useAppSelector } from "../../../hooks/useAppSelector";
import { getService } from "react-in-angularjs";
import { NavbarLi, NavbarUl, TopNavbarSecondary } from "../../../components/TopNavbarSecondary";
import { withReduxProvider } from "../../../services/withReduxProvider";
import { ICreateMap, IMap } from "../../../types/IMap";
import { Button } from "../../../design/Button";
import { ButtonGroupDropdown } from "../../../components/ButtonGroupDropdown";
import { SmallLoadingIndicator } from "../../../design/SmallLoadingIndicator";
import { incrementName } from "../../../utils/stringUtils";
import { IWorkspace } from "../../../types/IWorkspace";
import { IHasMapAccess } from "../../../../app/routes/mapping/services/has-map-access";
import { EditMapForm } from "../EditMapForm";
import { isICreateMap } from "../../../types/IMap";
import { WorkspaceModal } from "../WorkspaceModal";
import { Modal } from "../../../components/Modal";
import { PermissionManager } from "../../../services/permission-manager";
import { store } from "../../../store";
import { ITruTerritoryState, setMarkerID, toggleTopPanelSection } from "../../../slices/pagesSlice";
import { getTopLevelMaps } from "../../../data/workspace";
import { useCreateWorkspaceCloneMutation } from "../../../slices/apiSlice";
import { ITruMap } from "../../../types/ITruMap";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

interface TruterritoryNavbarProps {
  onSearch: (text: string) => void;
  areTilesLoading?: boolean;
  isMapDirty: boolean;
  saveMap: (map: Partial<IMap>, refresh: boolean) => Promise<void>;
  createMap: (map: ICreateMap) => Promise<void>;
  workspace: IWorkspace;
  createNewMap: () => void;
  switchWorkspace: (ID: number) => void;
  $scope: IScope;
  truMap?: ITruMap;
}

export const TruTerritoryNavbar: React.FC<TruterritoryNavbarProps & JSX.IntrinsicAttributes> = ({
  onSearch,
  areTilesLoading,
  saveMap,
  createMap,
  workspace,
  switchWorkspace,
  createNewMap: ogCreateNewMap,
  truMap,
  $scope,
}: TruterritoryNavbarProps) => {
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.app.session);
  const { topPanelSections, isMapDirty, map, markerID, availableCollections } = useAppSelector((state) => state.pages.truterritory);
  const [searchText, setSearchText] = useState("");
  const [editingMap, setEditingMap] = useState<ICreateMap | IMap>();
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
  const [locationActive, setLocationActive] = useState(false);

  const [makeWorkspaceClone] = useCreateWorkspaceCloneMutation();

  const hasAccess: IHasMapAccess = getService("hasMapAccess");
  const access = new PermissionManager(session?.permissions);

  const toggleTopPanel = (section: keyof ITruTerritoryState["topPanelSections"]) => {
    store.dispatch(toggleTopPanelSection(section));
  };

  const closeEditModal = () => {
    setEditingMap(undefined);
  };

  const createNewMap = () => {
    $scope.$apply(() => ogCreateNewMap());
  };

  const canEditMap = (map?: IMap): boolean => (hasAccess("collaborate") && !!map?.allowCollaboration) || (hasAccess("update") && !!map?.ID);

  const isSuper = (): boolean => access.isSuper();

  const createWorkspaceClone = async (workspace: IWorkspace) => {
    const newWorkspace = await makeWorkspaceClone({ ID: workspace.ID, name: workspace.name }).unwrap();
    switchWorkspace(newWorkspace.ID);
  };

  const centerOnCurrentLocation = async () => {
    if (!map || !truMap) return;

    setLocationActive(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (markerID) truMap.removeLayer(markerID);

        const newMarkerID = truMap.addMarker([position.coords.latitude, position.coords.longitude]);
        truMap.setView([position.coords.latitude, position.coords.longitude], 15, { animate: true });

        dispatch(setMarkerID(newMarkerID));
        setLocationActive(false);
      },
      () => {
        setLocationActive(false);
      },
      {
        timeout: 60000,
      }
    );
  };

  const onEditMap = () => {
    if (!map) return;
    setEditingMap(store.getState().pages.truterritory.map);
  };

  return (
    <>
      <TopNavbarSecondary title={""}>
        <NavbarUl>
          <NavbarLi>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSearch(searchText);
              }}
            >
              <div className="input-search">
                <input className="search" type="text" placeholder="Search:" value={searchText} onChange={({ target }) => setSearchText(target.value)} />
              </div>
            </form>
          </NavbarLi>
          <NavbarLi>
            <button className={`btn location${locationActive ? " active" : ""}`} title="Show your current location on the map" onClick={centerOnCurrentLocation} />
          </NavbarLi>
          <NavbarLi className="map-name">
            {map?.name ? (
              <>
                <span>{map?.name}</span>
                {canEditMap(map) && <Button className="edit map-editor" onClick={onEditMap} title="Edit general properties for this map" />}
                {!!map?.ID && !map?.parentID && isSuper() && (
                  <Button
                    className={`menu-data map-report-editor ${topPanelSections.mapReports ? "highlight" : ""}`} // todo, change to active
                    id="top-nav-map-report-editor-button"
                    onClick={() => toggleTopPanel("mapReports")}
                    title="Edit reports for this map"
                  />
                )}
              </>
            ) : (
              <>
                {map?.collections?.length ? (
                  <span>(new map)</span>
                ) : (
                  <span>
                    <em>(no map)</em>
                  </span>
                )}
              </>
            )}
          </NavbarLi>
        </NavbarUl>
        <NavbarUl id="truterritory-ul-right" right>
          {areTilesLoading && (
            <NavbarLi className="tiles-loading">
              <SmallLoadingIndicator />
            </NavbarLi>
          )}
          <NavbarLi>
            <a href="/?docs=/" className="icon help" target="_blank" />
          </NavbarLi>
          {hasAccess("collaborate") && (
            <>
              <NavbarLi>
                <ButtonGroupDropdown id="truterritory-save-dropdown" buttonText="Save" disabled={isMapDirty === false} onClick={() => saveMap(map!, false)}>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        if (!map) return;
                        const newMap = {
                          ...map,
                          name: incrementName(map.name),
                          ID: undefined,
                          parentID: map.parentID || map.ID,
                          children: [],
                          views: [],
                          workspaceID: workspace.ID,
                        };

                        setEditingMap(newMap);
                      }}
                    >
                      Save Map As
                    </a>
                  </li>
                  {hasAccess("create") ? (
                    <>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            setIsWorkspaceModalOpen(true);
                          }}
                        >
                          Save Workspace As
                        </a>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a href="#" onClick={() => createNewMap()}>
                          New Map
                        </a>
                      </li>
                    </>
                  ) : (
                    <li>
                      <a href="#" onClick={() => createNewMap()}>
                        Clear Map
                      </a>
                    </li>
                  )}
                </ButtonGroupDropdown>
              </NavbarLi>
              {hasAccess("update") && (
                <NavbarLi>
                  <Button onClick={() => toggleTopPanel("layers")}>Layers</Button>
                </NavbarLi>
              )}
            </>
          )}
        </NavbarUl>
      </TopNavbarSecondary>

      <Modal isOpen={Boolean(editingMap)} onClose={closeEditModal} id="truterritory-save-map-modal">
        <EditMapForm
          onCancel={closeEditModal}
          map={editingMap}
          parents={getTopLevelMaps(workspace)}
          workspace={workspace}
          parentChangeAllowed={!editingMap?.children?.length}
          isCreating={!editingMap || !("ID" in editingMap) || !editingMap?.ID}
          onSave={async (map: ICreateMap | Partial<IMap>) => {
            await (isICreateMap(map) ? createMap(map) : saveMap(map, true));
            closeEditModal();
          }}
        />
      </Modal>
      <WorkspaceModal isOpen={isWorkspaceModalOpen} onClose={() => setIsWorkspaceModalOpen(false)} workspace={workspace} onSave={createWorkspaceClone} />
    </>
  );
};

TruTerritoryNavbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  areTilesLoading: PropTypes.bool,
  saveMap: PropTypes.func.isRequired,
  createMap: PropTypes.func.isRequired,
  workspace: PropTypes.any.isRequired,
  createNewMap: PropTypes.func.isRequired,
};

export const AngularTruTerritoryNavbar = react2angular(withReduxProvider(TruTerritoryNavbar), Object.keys(TruTerritoryNavbar.propTypes) as (keyof TruterritoryNavbarProps)[], [
  "$scope",
]);
