/**
 * Service for managing text templates on the backend
 */
angular.module("modules:mapping").factory("Templates", function (Restangular, Storage) {
  var storageKey = "truterritory-templates";

  var Templates = Restangular.all("templates");

  Restangular.addElementTransformer("templates", true, function (templates) {
    /**
     * Save all the templates
     * @return {Promise}
     */
    templates.saveAll = function () {
      return Templates.customPUT(templates);
    };

    /**
     * Removes a template and all its maps
     * @param  {object} template
     * @return {Promise}
     */
    templates.removeTemplate = function (template) {
      // Find the index
      var index = _.findIndex(templates, { ID: template.ID });

      // Make the request to remove the template and splice it
      templates.splice(index, 1);

      return templates.saveAll();
    };

    return templates;
  });

  /**
   * Load all the templates
   * @return {Promise<Array>}
   */
  Templates.load = function () {
    // If there are templates in localStorage, migrate them to the backend, otherwise just make a request to get all of the templates
    return Storage.getItem(storageKey) ? Templates._migrate(Storage.getItem(storageKey)) : Templates.getList();
  };

  /**
   * Migrate the local storage templates to the database
   * @param  {array} templates
   * @return {Promise<Array>}
   */
  Templates._migrate = function (templates) {
    var toCreate = [];

    // Loop through all the templates, and create an object in the correct format
    for (var i = 0; i < templates.length; i++) {
      var template = templates[i];
      for (var j = 0; j < template.templates.length; j++) {
        toCreate.push({ mapID: template.templates[j], workspaceID: templates[i].ID, sorder: j });
      }
    }

    // PUT all the templates
    return Templates.customPUT(toCreate).then(function (templates) {
      // Remove from local storage
      Storage.removeItem(storageKey);
      Restangular.restangularizeCollection(null, templates, "mapping/templates");
      return templates;
    });
  };

  return Templates;
});
