import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";
import { Key } from "react";

import { withReduxProvider } from "../services/withReduxProvider";
import { useAppSelector } from "../hooks/useAppSelector";

export const AdminMenu = () => {
  const Menu = getService("Menu");
  const $state = getService("$state");

  const { activeSection } = useAppSelector((state) => state.app);

  return (
    <div id="side-nav-right" className="nav nav-pills nav-stacked col-xs-2">
      {Menu.get("admin").map((section: { text: string; routes: { enabled: boolean; sref: string; name: string }[] }, index: Key) => (
        <div key={index}>
          <h3>{section.text}</h3>
          <ul>
            {section.routes
              .filter((i) => i.enabled)
              .map((item, index: number) => (
                <li key={index} className={activeSection === item.sref ? "active" : ""}>
                  <a href="#" onClick={() => $state.go(item.sref)}>
                    {item.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export const AngularAdminMenu = react2angular(withReduxProvider(AdminMenu));
