/**
 * Edit user permissions
 */

const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

angular
  .module("routes:admin")
  .controller("admin:edit-user-permissions", function ($rootScope, $scope, users, user, userEntities, permissions, Permission, PermissionManager, $http) {
    store.dispatch(
      updateGlobalState({
        title: "Manage Permissions for '" + user.fname + " " + user.lname + "'",
        h1: "Administration",
        activeSection: "admin.users-and-entities",
      })
    );

    $scope.entities = [];

    $scope.users = users;
    $scope.entities = userEntities;
    $scope.permissions = permissions;

    $scope.user = user;
    $scope.permission;

    $scope.permissionList = [$scope.entities];
    $scope.current = [];
    $scope.currentPermission = {};
    $scope.currentPermissionObject = {};

    var permissionManager = new PermissionManager($scope.user.permissions);

    // When the permission selection is changed
    $scope.$watch(
      "current",
      function (current, oldVal) {
        var permissionString = current.join(".");

        // If this is a list, or a sublist…
        if (current[1] == "lists" && current.length > 2) {
          let currentList = $scope.permissionList[$scope.current.length - 1].children[$scope.current[$scope.current.length - 1]];

          if (currentList.isUnassigned == true) $scope.currentPermission = { delete: 0, create: 0, update: 0, read: 0 };
        } else {
          $scope.currentPermission = permissionManager.get(permissionString);
        }
      },
      true
    );

    /**
     * Triggered when a permissions checkbox is changed
     * @param  {string} type
     * @param  {integer} value
     * @return {void}
     */
    $scope.changedPermission = function (type, value) {
      // If there's no permission string, don't do anything
      if (!$scope.current.length) return;

      // Get the type hierarchy and the highest type
      var typeHierarchy = permissionManager.getTypeHierarchy(),
        highestType = typeHierarchy[permissionManager.findHighestType($scope.currentPermission)];

      // If there *is* a highest type
      if (highestType) {
        // Expand it
        var currentPermission = permissionManager.expandAccessType(highestType);

        // If there isn't a permission at the current level (the level that was clicked), remove all the permissions
        if (!currentPermission[type]) {
          for (var type in currentPermission) {
            $scope.currentPermission[type] = 0;
          }

          // Otherwise, expand the type that was clicked and set it on scope
        } else {
          $scope.currentPermission = permissionManager.expandAccessType(type);
        }

        // Otherwise there's not a highest type,
        // So remove all permissions
      } else {
        for (var type in $scope.currentPermission) {
          $scope.currentPermission[type] = 0;
        }
      }

      permissionManager.set($scope.current.join("."), $scope.currentPermission);
      $scope.user.savePermissions();
    };

    /**
     * Triggered when an entity is clicked
     * @param {object} entity
     * @param {object} user
     * @return {void}
     */
    $scope.setEntity = function (entity, user) {
      $scope.entity = entity;
      entity.permission = "entity." + entity.ID;

      // Make a request to get the list of permissions for the entity
      Permission.getEntityPermissionList(entity.ID, user.ID).then(function (response) {
        // Set all the necessary stuff for the breadcrumbs and for the tracking array
        $scope.permissionList.splice(1, $scope.current.length);
        $scope.current.splice(1, $scope.current.length);
        $scope.permissionList[1] = response;

        $scope.current[0] = "entity." + entity.ID;
        $scope.entity = entity;
      });
    };

    /**
     * Triggered when a permission is clicked
     * @param  {string} permission
     * @param  {object} permissionObj
     * @param  {integer} index
     * @return {void}
     */
    $scope.openPermission = function (permission, permissionObj, index) {
      // If the clicked permission is not the furthest level down,
      // And it's not in the active tree
      if (index < $scope.current.length) {
        // Remove everything down the tree after the clicked permission
        $scope.permissionList.splice(index + 1, $scope.current.length - index + 1);
        $scope.current.splice(index, $scope.current.length - index);
      }

      // If the permission doesn't have any children,
      // Or if the clicked permission is already in the tree,
      // Clear all of its children from the tracking array (but don't change the UI)
      // Set the current permission and return
      if (!permissionObj.children || permissionObj.children === null || $scope.current[index] == permission) {
        $scope.current[index] = permission;
        return;
      }

      // Set the object at the current index
      $scope.permissionList[index + 1] = permissionObj;

      // Set the slug for the tracking array
      $scope.current[index] = permission;

      Permission.getPermission($scope.current.slice(1, $scope.current.length), $scope.entity.ID, $scope.user.ID).then(function (response) {
        if ($scope.permissionList[index + 1] && $scope.permissionList[index + 1].permission == permissionObj.permission)
          $scope.permissionList[index + 1].children = response.children;
      });
    };

    /**
     * Check the current permission selection against a permission
     * @param  {string} permission
     * @param  {array} current
     * @return {boolean}
     */
    $scope.checkSelection = function (permission, current) {
      if (permission.permission == current.join(".")) return true;
      return false;
    };

    $scope.cascadePermissions = function () {
      permissionManager.clearDescendants($scope.current.join("."));
      $scope.user.savePermissions();
    };

    /**
     * Checks to see if the a permission type should be disabled
     * @param  {string}  permission
     * @return {boolean}
     */
    $scope.isTypeDisabled = function (permission) {
      if (!$scope.current.length) return true;

      var type = _.indexOf(permissionManager.getTypeHierarchy(), permission);
      var highest = permissionManager.findHighestType($scope.currentPermission);

      if (type > highest && highest !== null) return true;

      return false;
    };

    /**
     * Returns the classes for a permission (in the tree)
     * @param  {string} permission
     * @param  {integer} index
     * @return {string} the list of classes, space-delimited
     */
    $scope.getPermissionClass = function (permission, index) {
      if (!permission.permission && permission.ID) permission.permission = "entity." + permission.ID;

      var classString = [],
        summary = permissionManager.summarize(permission.permission),
        highPrefix = "sidebar-high-",
        lowPrefix = "sidebar-low-";

      if (permission.children !== null) classString.push("next");

      if ($scope.checkSelection(permission, $scope.current)) classString.push("selected");

      if (summary.access) classString.push(summary.access);

      if (summary.high) classString.push(highPrefix + summary.high);

      if (summary.low) classString.push(lowPrefix + summary.low);

      return classString.join(" ");
    };

    $scope.getWrapperWidth = function () {
      return 245 * $scope.permissionList.length + "px";
    };
  });
