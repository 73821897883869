/**
 * $exceptionHandler definition
 * Handles all exceptions
 */

export default angular.module("configuration.exceptionHandler", []).config(function ($provide) {
  // $provide.decorator("$exceptionHandler", [
  //   "$log",
  //   "$delegate",
  //   "$window",
  //   function ($log, $delegate, $window) {
  //     return function $exceptionHandler(exception, cause) {
  //       $delegate(exception, cause);
  //       // $window.logger.logError("error", exception, cause);
  //     };
  //   },
  // ]);
}).name;
