import { react2angular } from "react2angular";

export const SmallLoadingIndicator = () => {
  return (
    <div className="small-loading-indicator">
      <div className="icon-notched-circle"></div>
    </div>
  );
};

export const AngularSmallLoadingIndicator = react2angular(SmallLoadingIndicator);
