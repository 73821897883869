/**
 * @name epiCheckboxSwitch
 * @ngdoc directive
 * @memberOf common
 * @scope true
 * @restrict C
 *
 * @attribute {String} inputId
 * @attribute {String} value
 * @attribute {String} name
 * @attribute {Boolean} checked
 * @attribute {Object} model
 * @attribute {String} onText
 * @attribute {String} offText
 */
angular.module("common").directive("epiCheckboxSwitch", function () {
  return {
    restrict: "C",
    templateUrl: "/directives/epi-checkbox-switch.html",
    scope: {
      id: "@inputId",
      value: "@value",
      name: "@name",
      checked: "=checked",
      model: "=model",
      onText: "@onText",
      offText: "@offText",
    },
  };
});
