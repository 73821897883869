import { ComponentType, ReactNode } from "react";
import { Provider } from "react-redux";

import { store } from "../store";

/**
 * This HOC is wrapper for providing the redux store to the provided component
 * Long term this isn't really needed, but for the interim while there's no root store / provider in the app,
 *  this is necessary for usage of hooks and the main redux store
 *
 * @example
 * // this is an example of a functional component with props
 * const Component: React.FC<IComponentProps & JSX.IntrinsicAttributes> = ({name}: IComponentProps) => <>{name}</>
 * export WrappedComponent = withReduxProvider(Component)
 *
 * @example
 * // this is an example of a functional component without props
 * const Component = withReduxProvider(() => <></>)
 *
 */
export function withReduxProvider<T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
  const Wrapped = (props: T) => {
    return (
      <Provider store={store}>
        <Component {...(props as T)} />
      </Provider>
    );
  };

  // retain propTypes for react2angular
  Wrapped.propTypes = Component.propTypes;

  return Wrapped;
}
