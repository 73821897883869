import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { arrayMove, useSortable, UseSortableArguments } from "@dnd-kit/sortable";

import { ICollection, Index, IndexColumn } from "../../types/ICollection";
import { Button } from "../../design/Button";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { SortableUl } from "../../components/Sortable/SortableUl";
import { SortableLi } from "../../components/Sortable/SortableLi";
import { DragEndEvent } from "@dnd-kit/core";
import { classNameMapper } from "../../utils/classNameMapper";
import { Checkbox } from "../../design/Checkbox";

interface IIndexesCreatorProps {
  collection: ICollection;
  indexes: ICollection["indexes"];
  onChange: (indexes: ICollection["indexes"]) => void;
}

export const IndexesCreator = ({ collection, indexes, onChange }: IIndexesCreatorProps) => {
  const [newIndex, setNewIndex] = useState<Index>({ unique: false, columns: [] });

  return (
    <div className="indexes-creator">
      <h4>Indexes</h4>
      {indexes.map((index, idx) => (
        <IndexCreator
          key={idx}
          collection={collection}
          index={index}
          onChange={(index) => {
            const newIndexes = [...indexes];
            newIndexes[idx] = index;
            onChange(newIndexes);
          }}
          removeIndex={() => onChange(indexes.filter((_, i) => i !== idx))}
        />
      ))}
      <div className="create">
        <IndexCreator collection={collection} index={newIndex} onChange={(index) => setNewIndex(index)} />
        <Button
          className="btn"
          disabled={!newIndex.columns.length}
          onClick={() => {
            onChange([...indexes, newIndex]);
            setNewIndex({ unique: false, columns: [] });
          }}
        >
          Add Index
        </Button>
      </div>
    </div>
  );
};

interface IIndexCreatorProps {
  collection: ICollection;
  index: ICollection["indexes"][0];
  onChange?: (index: Index) => void;
  removeIndex?: () => void;
}

const IndexCreator = ({ collection, index, onChange, removeIndex }: IIndexCreatorProps) => {
  const [columns, setColumns] = useState<IndexColumn[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const multiSelectRef = useRef<HTMLDivElement>(null);

  // set the initial values
  useEffect(() => {
    // sort all the indexed columns first, since they may have a different order than the collection properties
    let sortedColumns = [...collection.properties];

    // flip the columns, then iterate backwards over them, adding them to the beginning of the array
    [...index.columns].reverse().forEach((col) => {
      const findIndex = sortedColumns.findIndex((c) => c.column === col.column);
      if (findIndex === -1) return;
      const foundColumn = sortedColumns.splice(findIndex, 1)[0];
      sortedColumns.unshift(foundColumn);
    });

    setColumns(sortedColumns);
  }, [collection.properties, collection.featureColumns]);

  useOutsideClick(multiSelectRef, () => setIsDropdownOpen(false));

  const getNames = () => {
    return index.columns.map((col) => col.name || _.find(collection.properties, { column: col.column })?.name).join(", ");
  };

  const isChecked = (index: Index, column: string) => {
    return !!_.find(index.columns, { column });
  };

  const getOrder = (column: string) => {
    return _.find(index.columns, { column })?.order;
  };

  const handleCheckboxChange = (column: IndexColumn) => {
    const newColumns = [...index.columns];
    if (isChecked(index, column.column)) {
      newColumns.splice(
        newColumns.findIndex((c) => c.column === column.column),
        1
      );
    } else {
      newColumns.push(column);
    }
    onChange && onChange({ ...index, columns: newColumns });
  };

  const handleOrderChange = (order: string, column: string) => {
    const newColumns = index.columns.map((c) => (c.column === column ? { ...c, order } : { ...c }));
    onChange && onChange({ ...index, columns: newColumns });
  };

  const handleRemoveIndex = () => removeIndex && removeIndex();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const activeIndex = columns.findIndex(({ column }) => column === active.id);
      const overIndex = columns.findIndex(({ column }) => column === over.id);

      if (activeIndex === -1 || overIndex === -1) return;

      const newColumns = arrayMove(columns, activeIndex, overIndex);
      setColumns(newColumns);
      onChange &&
        onChange({
          ...index,
          columns: newColumns.map(({ column }) => (isChecked(index, column) ? index.columns.find((c) => c.column === column) : undefined)).filter((c) => c) as IndexColumn[],
        });
    }
  };

  return (
    <div className="index-creator">
      <div className="multiselect" ref={multiSelectRef}>
        <div className="toggle" onClick={() => setIsDropdownOpen((v) => !v)}>
          {index.columns.length ? getNames() : "Select..."}
        </div>
        {isDropdownOpen && (
          <div className="dropdown" style={{ listStyleType: "none", padding: 0 }}>
            <SortableUl items={columns.map(({ column }) => column) ?? []} id="index-creator" className="sortable-ul" onDragEnd={handleDragEnd}>
              {columns.map((column) => {
                const id = column.column;
                return (
                  <SortableLi className="sortable-li option" id={id} key={id} useSeparateDragHandle>
                    <DragHandle id={id} isHidden={!isChecked(index, column.column)} />
                    <input
                      type="checkbox"
                      checked={isChecked(index, column.column)}
                      onChange={() => handleCheckboxChange(column)}
                      id={id}
                      className={isChecked(index, column.column) ? "_checked" : ""}
                    />
                    <label className="checkbox" htmlFor={id}>
                      {column.name}
                    </label>
                    {isChecked(index, column.column) && (
                      <div className="select-holder">
                        <select
                          value={getOrder(column.column) ?? ""}
                          onChange={(e) => {
                            handleOrderChange(e.target.value, column.column);
                          }}
                        >
                          <option value="" disabled>
                            Order...
                          </option>
                          <option value="ASC">ASC</option>
                          <option value="DESC">DESC</option>
                        </select>
                      </div>
                    )}
                  </SortableLi>
                );
              })}
            </SortableUl>
          </div>
        )}
      </div>
      <Checkbox checked={index.unique ?? false} onChange={(e) => onChange && onChange({ ...index, unique: e.target.checked })} label="Unique" />
      {removeIndex && (
        <button className="btn del" onClick={handleRemoveIndex}>
          Delete
        </button>
      )}
    </div>
  );
};

const DragHandle = ({ id, isHidden }: { id: UseSortableArguments["id"]; isHidden: boolean }) => {
  const { listeners, attributes } = useSortable({ id });
  return <span className={classNameMapper({ hidden: isHidden }, "btn draggable")} {...listeners} {...attributes} />;
};
