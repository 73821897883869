/**
 * Error/index.js (handles all the routing for the error pages)
 */

export default angular.module("routes:error", []).config(function ($stateProvider) {
  $stateProvider
    .state("error", {
      abstract: true,
      templateUrl: "/error/error-init.html",
      parent: "init",
    })
    .state("error.generic", {
      url: "/generic/:previous",
      templateUrl: "/error/generic.html",
      controller: "error:generic",
    });
}).name;

require("./controllers/generic");
