export interface IColProps {
  breakpoint: "xs" | "sm" | "md" | "lg";
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  offset?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const Col = ({ children, ...props }: IColProps & { children?: React.ReactNode }) => {
  return <div className={createColumnClassName(props)}>{children}</div>;
};

export function createColumnClassName({ breakpoint, size, offset }: IColProps) {
  return `col-${breakpoint}-${size}` + (offset ? ` col-${breakpoint}-offset-${offset}` : "");
}
