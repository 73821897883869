export default angular.module("configuration.other", ["ui.router"]).config(function ($urlRouterProvider, $locationProvider, $logProvider, DEV) {
  $locationProvider.hashPrefix("");
  $locationProvider.html5Mode(true);

  // Turn on logging (if this is a development environment)
  $logProvider.debugEnabled(DEV);

  // Set the default 404 url
  $urlRouterProvider.when("", "/").otherwise("/not-found");
}).name;
