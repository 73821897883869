import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "../store";
import { IApiStatus } from "../types/IApiResponse";
import { IEntity, ISession } from "../types/ISession";
import { baseApi } from "./apiSlice";
import { IUserPreferences } from "../types/IUser";

export interface AppState {
  entityID?: number;
  entity?: IEntity;
  session?: ISession;
  h1: string;
  title: string;
  activePage: string;
  activeSection: string;
  error?: IApiStatus;
}

export const initialState: AppState = {
  h1: "",
  title: "",
  activePage: "",
  activeSection: "",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateGlobalState: (state, { payload }: PayloadAction<Partial<AppState>>) => {
      return { ...state, ...payload };
    },
    setEntityID: (state, { payload }: PayloadAction<string | number>) => {
      // it can be a number or string depending on the part of the app
      const parsedEntityId = typeof payload === "string" ? parseInt(payload) : payload;

      // if it's the same, don't do anything
      if (state.entityID === parsedEntityId) return;

      // else set it on the state
      state.entityID = parsedEntityId;

      state.entity = state.session?.entityList.find((e) => e.ID === parsedEntityId);

      // this is frustrating but there's no other good way to clear the api cache without this
      setTimeout(() => {
        store.dispatch(baseApi.util.resetApiState());
      });
    },
    setSession: (state, { payload }: PayloadAction<any>) => {
      state.session = payload;

      // if the page has been refreshed, it's possible the entity isn't set, so just make sure it's set
      if (!state.entity) {
        state.entity = state.session?.entityList.find((e) => e.ID === state.entityID);
      }
    },
    setH1: (state, { payload }: PayloadAction<string>) => {
      state.h1 = payload;
    },
    setError: (state, { payload }: PayloadAction<AppState["error"]>) => {
      if (!state.error) state.error = payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },

    updateWorkspaceFolding: (state, { payload }: PayloadAction<IUserPreferences["workspaceFolding"]>) => {
      if (!state.session) return;

      if (state.session.preferences == undefined) {
        state.session.preferences = {};
      }

      state.session.preferences.workspaceFolding = payload;
    },

    updateMapFolding: (state, { payload }: PayloadAction<IUserPreferences["mapFolding"]>) => {
      if (!state.session) return;

      if (state.session.preferences == undefined) {
        state.session.preferences = {};
      }

      state.session.preferences.mapFolding = payload;
    },
  },
});

export const { updateGlobalState, setEntityID, setSession, setH1, setError, clearError, updateWorkspaceFolding, updateMapFolding } = appSlice.actions;

export default appSlice.reducer;
