export default angular.module("bootstrap.google-analytics", []).run(function ($rootScope, $window, $location, APP_GA_ID) {
  if (!APP_GA_ID) return;

  angular.element($window.document).ready(function () {
    if (!$window.ga) {
      $window.ga = angular.noop;
    }
    $window.ga("create", APP_GA_ID, "auto");
    $rootScope.$on("$stateChangeSuccess", function () {
      $window.ga("send", "pageview", $location.path());
    });
  });
}).name;
