angular.module("modules:mapping").factory("Theme", function (Restangular, API_ROOT) {
  var Themes = Restangular.all("mapping/themes");

  // add a custom methods to collections of collections
  Restangular.addElementTransformer("mapping/themes", true, function (themes) {
    // don't try to work on uninitialized collection
    if (!("one" in themes)) return themes;

    return themes;
  });

  Themes.validateCSS = function (css, label, fontSize) {
    return Themes.customPOST({ css, variables: { label, fontSize } }, "validate");
  };

  /**
   *
   * @param collection
   * @return {{}}
   */
  Themes.getDefaultLabelFormats = function (collection) {
    var result = {};

    if ("properties" in collection) {
      collection.properties.forEach(function (property) {
        if (Themes.shouldGetDefaultFormat(property)) {
          // Apply the number format
          result[property.column] = Themes.getDefaultNumericLabelFormat(property);
        }
      });
    }

    return result;
  };

  /**
   * Return whether or not we should even apply a default label format
   *
   * @param property
   * @return {boolean}
   */
  Themes.shouldGetDefaultFormat = function (property) {
    if (Themes.isNumericColumnType(property.type)) {
      if (
        property.column === "lat" ||
        property.column === "lon" ||
        property.column === "lon" ||
        property.column.toLowerCase().includes("latitude") ||
        property.column.toLowerCase().includes("longitude")
      ) {
        return false;
      }

      // Integers that shouldn't be formated (commas, etc). ID's and similar.
      if (property.column === "ogc_fid" || property.name === "ogc_fid" || property.column.endsWith("ID") || property.column.toLowerCase().endsWith("_id")) {
        return false;
      }

      return true;
    }

    return false;
  };

  /**
   * Takes a given column type (intv, text, varchar, numeric, etc)
   *
   * @param type string
   */
  Themes.isDecimalColumnType = function (type) {
    return type.startsWith("float") || type.startsWith("numeric") || type.startsWith("money");
  };

  /**
   * Takes a column type and returns whether or not it represents a number column
   *
   * @param type
   * @return {*|boolean}
   */
  Themes.isNumericColumnType = function (type) {
    return Themes.isDecimalColumnType(type) || type.startsWith("int");
  };

  /**
   * Given a property, returns the appropriate number format, assuming it is a numeric column
   *
   * @param property
   * @return string
   */
  Themes.getDefaultNumericLabelFormat = function (property) {
    var looksLikePercent =
      property.column.includes("%") ||
      property.column.includes("pct") ||
      property.column.toLowerCase().includes("percent") ||
      property.name.includes("%") ||
      property.name.includes("pct") ||
      property.name.toLowerCase().includes("percent");

    var looksLikeMoney =
      property.type.startsWith("money") ||
      property.column.includes("dollar") ||
      property.column.includes("revenue") ||
      property.column.includes("spend") ||
      (property.column.includes("sales") && (property.column.includes("total") || property.column.includes("by"))) ||
      property.name.includes("dollar") ||
      property.name.includes("spend") ||
      (property.name.includes("sales") && (property.name.includes("total") || property.name.includes("by")));

    // As factor - If column name or name includes "factor"
    var looksLikeFactor = property.column.includes("factor") || property.name.includes("factor");

    // Set the baseline format based on whether this basically looks like a decimal value or not
    var format = Themes.isDecimalColumnType(property.type) || looksLikeFactor ? "0.0" : "0,0";

    if (looksLikePercent) {
      format += "%";
    }

    if (looksLikeMoney) {
      format = "$" + format;
    }

    return format;
  };

  /**
   *
   * @param collection
   * @return {{}}
   */
  Themes.getDefaultLabelZeros = function (collection) {
    var result = {};

    if ("properties" in collection) {
      collection.properties.forEach(function (property) {
        if (Themes.isNumericColumnType(property.type)) {
          // Apply the number format
          result[property.column] = true;
        }
      });
    }

    return result;
  };

  Themes.getDefaultCss = function (collection) {
    var body = "";

    if (collection === undefined || !("geometryType" in collection) || !("tableName" in collection)) {
      return body;
    }
    if (["multilinestring", "linestring", "multipolygon", "polygon"].includes(collection.geometryType)) {
      body =
        ["line-color:      #66686b", "line-width:      1", "line-opacity:    1", "polygon-fill:    #0b80d0", "polygon-opacity: 0.3", "setLabel(_, _,   #000)"].join(";\n  ") + ";";
    } else if (["multipoint", "point"].includes(collection.geometryType)) {
      body =
        [
          "marker-fill:          #1883ce",
          "marker-file:          url(marker.svg)",
          "marker-width:         15",
          "marker-transform:     translate(0,-10)",
          "marker-allow-overlap: true",
          "setLabel(_, _,        #000)",
        ].join(";\n  ") + ";";
    }  else if (collection.type == "writable") {
      body = [
        '[__geom_type="POINT"] {',
        "  marker-fill:          #1883ce;",
        "  marker-file:          url(marker.svg);",
        "  marker-width:         15;",
        "  marker-transform:     translate(0,-10);",
        "  marker-allow-overlap: true;",
        "}",
        "line-color:           #66686b;",
        "line-width:           1;",
        "line-opacity:         1;",
        "polygon-fill:         #0b80d0;",
        "polygon-opacity:      0.3;",
        "setLabel(_, _,        #000);",
        "[__vis_id=1] {polygon-fill: #e41a1c}",
        "[__vis_id=2] {polygon-fill: #377eb8}",
        "[__vis_id=3] {polygon-fill: #4daf4a}",
        "[__vis_id=4] {polygon-fill: #984ea3}",
        "[__vis_id=5] {polygon-fill: #ff7f00}",
        "[__vis_id=6] {polygon-fill: #ffff33}",
        "[__vis_id=7] {polygon-fill: #a65628}",
        "[__vis_id=8] {polygon-fill: #f781bf}",
      ].join("\n  ");
    } else if (["geometry", "geometrycollection"].includes(collection.geometryType)) {
      body =
        [
          "line-color:           #66686b",
          "line-width:           1",
          "line-opacity:         1",
          "polygon-fill:         #0b80d0",
          "polygon-opacity:      0.3",
          "marker-fill:          #1883ce",
          "marker-file:          url(marker.svg)",
          "marker-width:         15",
          "marker-transform:     translate(0,-10)",
          "marker-allow-overlap: true",
          "setLabel(_, _,        #000)",
        ].join(";\n  ") + ";";
    }

    return "#" + collection.tableName + " {\n  " + body + "\n}";
  };

  Themes.getDefaultTheme = function (collection, workspaceID, name = null) {
    return {
      collectionID: collection === undefined ? null : collection.ID,
      workspaceID,
      name: name || new Date().toLocaleString(),
      legend: [],
      label: null,
      customLabel: null,
      labelFormats: collection === undefined ? {} : Themes.getDefaultLabelFormats(collection),
      labelZeroes: collection === undefined ? {} : Themes.getDefaultLabelZeros(collection),
      fontSize: 10,
      css: collection === undefined ? "" : Themes.getDefaultCss(collection),
    };
  };

  /**
   * Strip everything off the object except for data.
   * (Note: not recursive.)
   */
  Themes.dataOnly = function (dirty) {
    return Object.keys(Restangular.stripRestangular(dirty))
      .filter((k) => typeof dirty[k] !== "function")
      .reduce((clean, k) => ({ ...clean, [k]: dirty[k] }), {});
  };

  return Themes;
});
