import { getService } from "react-in-angularjs";
import { react2angular } from "react2angular";

import { useAppSelector } from "../hooks/useAppSelector";
import { withReduxProvider } from "../services/withReduxProvider";
import { IStateService } from "angular-ui-router";

export const Menu = () => {
  const Menu = getService("Menu");
  const $state: IStateService = getService("$state");
  const { entityID: entityId, activePage } = useAppSelector((state) => state.app);

  if (Menu.getEnabled().length <= 1) return <></>; // if we only have 1 menu item, we don't need a menu

  return (
    <nav id="side-nav-left" className="col-xs-2">
      <ul className="nav nav-pills nav-stacked nav-collapse">
        {Menu.get()
          .filter((i: { visible: boolean }) => i.visible !== false)
          .map((item: { id: string }, index: number) => (
            <li className={[item.id, item.id === activePage ? "active" : "", Boolean(entityId) ? "" : "disabled"].join(" ")} key={index}>
              <a
                href={$state.href(item.id, { entityID: entityId })}
                onClick={() => {
                  Boolean(entityId) && $state.go(item.id, { entityID: entityId });
                }}
              ></a>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export const AngularMenu = react2angular(withReduxProvider(Menu));
