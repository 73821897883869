/**
 * @name fade-out
 * @memberOf common
 * @ngdoc animation
 * @description
 *   Animation to fade out
 */
angular.module("common").animation(".fade-in", function () {
  return {
    addClass: function (element, className, done) {
      jQuery(element).fadeOut();
    },
  };
});
