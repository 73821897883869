/**
 * Directive to validate an array of file extensions against a list of extension rules
 * @name FileValidatorFactory
 * @memberOf common
 * @ngdoc service
 *
 * @example
 *   let allowedRules = [
 *     {required: ['XLS']},
 *     {required: ['CSV']},
 *     {required: ['TXT'], allowed: ['SHP']},
 *   ]
 *   let validator = FileValidatorFactory(allowedRules)
 *
 *   let files = ['TXT']
 *   let errorMessages = validator.validate(files) // no errors
 *   errorMessages = validator.validate(['JSON']) // error messages
 */
angular
  .module("common")
  .factory("FileValidatorFactory", function (FileValidator) {
    // Return the factory/constructor method
    return FileValidator.create;
  })
  .factory("FileValidator", function () {
    var FileValidator = {};

    /**
     * The "constructor" for the validator
     * @param  {object} rules the array of rules in this format:
     *                        [{required: [String], allowed: [String]}...]
     * @return {FileValidator}
     */
    FileValidator.create = function (rules) {
      var inst = Object.create(FileValidator);
      inst.rules = rules;
      for (var i = rules.length - 1; i >= 0; i--) {
        if (!rules[i].allowed) rules[i].allowed = [];

        if (!rules[i].required || !rules[i].required.length) throw new TypeError("Rules must include a required list");
      }
      return inst;
    };

    /**
     * Gets the extension for a particular file
     * @param  {string} filename the file name to get the extension from
     * @return {string}
     */
    FileValidator.getExtension = function (filename) {
      return filename.split(".").pop();
    };

    /**
     * Validates a list of file extensions
     * @param  {array} files
     * @return {null|string}       either returns null or returns an error message
     */
    FileValidator.validate = function (files) {
      var message = "",
        invalid = [],
        missing = [];

      // normalize all the file names to be uppercase
      files = files.map(function (name) {
        return name.toUpperCase();
      });

      // If no rule is found, this will be the "default" rule and all files will fail this validation
      var rule = { required: [], allowed: [] };

      // Loop through all the rules and find the one that matches the given files
      for (var i = 0; i < this.rules.length; i++) {
        var allowed = this.rules[i].required.concat(this.rules[i].allowed);
        if (allowed.indexOf(files[0]) != -1) rule = this.rules[i];
      }

      // Loop through the files and find invalid file types
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (rule.required.indexOf(file) == -1 && rule.allowed.indexOf(file) == -1) invalid.push(file);
      }

      // Loop through all the required file types and add that to the missing array
      for (var i = 0; i < rule.required.length; i++) {
        if (files.indexOf(rule.required[i]) == -1) missing.push(rule.required[i]);
      }

      // Create the error message
      if (missing.length) message += missing.length > 1 ? "File types {{missing}} are required. " : "File type {{missing}} is required. ";

      if (invalid.length) message += invalid.length > 1 ? "File types {{invalid}} is invalid. " : "File type {{invalid}} is invalid. ";

      message = message.replace("{{missing}}", missing.toString());
      message = message.replace("{{invalid}}", invalid.toString());

      // Either return the message or null
      if (missing.length || invalid.length) return message;
      return null;
    };

    return FileValidator;
  });
