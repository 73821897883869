import { getService } from "react-in-angularjs";

export const ImageUploader = (editor, classes = []) => {
  const $scope = getService("$rootScope");
  const $compile = getService("$compile");

  let wrapper = $(editor.getWrapperElement());

  let urlClass = classes.splice(classes.length - 1, 1);

  ["keyup", "paste", "cut"].forEach((event) => {
    wrapper.on(event, createPreviewBoxes);
  });
  wrapper.on("click", function (event) {
    let target = $(event.target);
    if (!target.parents("fine-uploader").length && !target.hasClass("image-preview")) wrapper.children("fine-uploader").css("display", "none");
  });

  createUploader();

  var selectedLineNumber, uploaderElement, uploaderInstance;

  const regex = /setMarker\((.*)\)/;

  function createPreviewBoxes() {
    if (!uploaderInstance) return;

    const files = uploaderInstance.getUploads();

    editor.operation(() => {
      for (let i = 0; i < editor.lineCount(); i++) {
        const line = editor.getLineHandle(i).text;
        const start = line.indexOf("setMarker");

        editor.findMarksAt({ line: i, ch: start }).forEach((mark) => mark.clear());

        if (start < 0) continue;

        const [, fileName] = line.match(regex) ?? [];

        const previewBox = $(`<span class="image-preview box" line-number=${i}></span>`);
        previewBox.on("click", onPreviewBoxClick);

        if (fileName) {
          const file = files.find((file) => file.name === fileName && !file.parentId);

          const thumbnailUrl = file ? file.thumbnailUrl || uploaderInstance._thumbnailUrls[file.id] : null;

          if (!thumbnailUrl) previewBox.addClass("empty");
          else previewBox.removeClass("empty");

          previewBox.css("background-image", `url("${thumbnailUrl}")`);
        } else {
          previewBox.addClass("empty");
        }

        editor.setBookmark({ line: i, ch: start }, { widget: previewBox[0] });
      }
    });
  }

  function createUploader() {
    let scope = $scope.$new();
    scope.options = {
      directory: "mapping/media",
      gallery: true,
      scaling: {
        hideScaled: true,
        sizes: [{ maxSize: 100 }],
      },
      validation: {
        sizeLimit: 50000,
        image: {
          maxHeight: 512,
          maxWidth: 512,
        },
      },
      signature: {
        endpoint: "mapping/themes/media/sign",
      },
      uploadSuccess: {
        endpoint: "mapping/themes/media",
      },
      deleteFile: {
        endpoint: "mapping/themes/media",
      },
      session: {
        endpoint: "mapping/themes/media",
      },
      callbacks: {
        onSessionRequestComplete: function (response) {
          uploaderInstance = this;
          createPreviewBoxes();
        },
        onSelect: function (file) {
          if (!this.getParentId(file.id)) {
            let line = editor.getLine(selectedLineNumber);
            let endCh = line.indexOf(")") == -1 ? line.length : line.indexOf(")");
            let { start, end } = {
              start: {
                line: selectedLineNumber,
                ch: line.indexOf("(") + 1,
              },
              end: {
                line: selectedLineNumber,
                ch: endCh,
              },
            };

            let replacement = `${file.name}${endCh == line.length ? ");" : ""}`;

            editor.replaceRange(replacement, start, end);
            createPreviewBoxes();
            uploaderElement.css("display", "none");
          }
        },
      },
    };

    uploaderElement = $compile('<fine-uploader options="options"></fine-uploader>')(scope);
    uploaderElement.css("display", "none");
    wrapper.append(uploaderElement);
  }

  function addThumbnailPreviews(files, uploader) {
    $(`.${urlClass}`).each(function () {
      let elem = $(this);
      let file = _.find(files, { name: elem.text() });

      let thumbnailUrl = file.thumbnailUrl || uploader._thumbnailUrls[file.id];

      elem
        .prev("." + classes.join(",."))
        .children(".image-preview")
        .css("background-image", `url('${thumbnailUrl}')`);
    });
  }

  function onPreviewBoxClick(event) {
    selectedLineNumber = parseInt($(event.target).attr("line-number"));
    uploaderElement.css($(event.target).position());
    uploaderElement.css("display", "block");
  }
};
