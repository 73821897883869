/**
 * Small loading indicator
 */

angular.module("common").directive("smallLoadingIndicator", function () {
  return {
    restrict: "C",
    templateUrl: "/directives/small-loading-indicator.html",
    scope: {},
    link: function (scope, element, attrs) {},
  };
});
