import angular from "angular";
import { IStateParamsService } from "angular-ui-router";

export type IHasMapAccess = (type: string) => boolean;

// @TODO: remove the any types
angular.module("routes:mapping").factory("hasMapAccess", function (PermissionManager: any, Session: any, $stateParams: IStateParamsService) {
  var access = new PermissionManager(Session.getAll().permissions || {});

  /**
   * A simple function-as-a-service to check mapping permissions
   * @param  {[type]}  type [description]
   * @return {Boolean}      [description]
   */
  return function hasMapAccess(type: string) {
    return access.hasAccessAbove("entity." + $stateParams.entityID + ".mapping", type);
  };
});
