/**
 * Directive for selecting an element
 */

angular.module("common").directive("selectElement", function ($parse, $timeout) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      element.on("focusin", function (e) {
        element.select();
      });

      element.mouseup(function (e) {
        e.preventDefault();
      });
    },
  };
});
