export interface IMapActionType {
  ID: string;
  name: string;
  enabled: boolean;
  disabledReason?: string;
  requiresSource: boolean;
  requiresTarget: boolean;
  allowedSources: number[];
  allowedTargets: number[];
  sourceOps: string[];
  targetOps: string[];
  performsWriteOnSource: boolean;
  performsWriteOnTarget: boolean;
  supportedAreaTypes?: AreaType[];
}

export enum AreaType {
  SELECTION = "SELECTION",
  GEOMETRY = "GEOMETRY",
  CIRCLE = "CIRCLE",
}