import angular from "angular";

import { AngularMenu } from "./Menu";
import { AngularAdminMenu } from "./AdminMenu";
import { AngularCompanySelector } from "./CompanySelector";
import { AngularHeader } from "./Header";
import { AngularResetPassword } from "./ResetPasswordModal";
import { AngularEditMapForm } from "../pages/TruTerritory/EditMapForm";

export default angular
  .module("components", [])
  .component("epiMenu", AngularMenu)
  .component("epiAdminMenu", AngularAdminMenu)
  .component("epiCompanySelector", AngularCompanySelector)
  .component("epiHeader", AngularHeader)
  .component("epiResetPassword", AngularResetPassword)
  .component("epiEditMapForm", AngularEditMapForm).name;
