const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

/**
 * Email-edit.js controller
 */

angular.module("routes:admin").controller("admin:email-edit", function ($rootScope, $scope, $stateParams, $log, $uibModal, email, emailIndex, $controller) {
  $controller("admin:email-create", { $rootScope: $rootScope, $scope: $scope, $stateParams: $stateParams, $log: $log, $uibModal: $uibModal });

  $scope.email = email;

  store.dispatch(updateGlobalState({ title: "Edit '" + $scope.email.subject + "'" }));

  $scope.saving = false;

  $scope.saveEmail = function (email) {
    // Validate the form
    var form = $scope.validateForm("editEmail");
    if (form.isValid()) {
      // Set saving true
      $scope.saving = true;

      // Set the plaintext to something just so that the API won't complain
      email.plaintext = "NOTHING TO SEE HERE!!";

      // Save it
      email
        .saveEmail()
        .then(
          function (response) {
            // Upon save, set the edited email in the list
            $scope.emails[emailIndex] = $scope.email;

            // Reset the errors,
            $scope.errors = {};

            // Show the highlight
            $scope.setSavedEmail(email.ID);

            // Close the right side
            $scope.closeRightSide();

            // If it errors out, set the errors on $scope
          },
          function (response) {
            $scope.errors = response.data.meta.errors;
          }
        )

        // Always set saving to false
        .finally(function () {
          $scope.saving = false;
        });
    }
  };
});
