/**
 * Directive for the index creator (for collections)
 */

angular.module("routes:data").directive("indexCreator", function () {
  return {
    scope: {
      collection: "=",
      index: "=ngModel",
      removeIndex: "&?",
    },
    restrict: "E",
    require: "ngModel",
    templateUrl: "/data/directives/index-creator.html",
    link: function ($scope, $element, $attrs, $model) {
      if (!$attrs.removeIndex) $scope.removeIndex = null;

      $scope.$watchGroup(
        ["collection.properties", "collection.featureColumns"],
        function () {
          $scope.columns = angular.copy($scope.collection.properties);
          $scope.featureColumns = angular.copy($scope.collection.featureColumns);
        },
        true
      );

      $scope.isChecked = function (index, column) {
        return !!_.find(index.columns, { column: column });
      };

      $scope.sortableOptions = {
        handle: ".draggable",
        stop: function (event, ui) {
          setTimeout($scope.$apply($scope.reorderIndex));
        },
      };

      $scope.generateID = function (key) {
        return Math.floor(Math.random() * 1000000) + 1 + "-" + $scope.$id + "-" + key;
      };

      $scope.getNames = function () {
        var names = [];
        for (var i = 0; i < $scope.index.columns.length; i++) {
          var column = $scope.index.columns[i];
          if (column.name) names.push(column.name);
          else names.push(_.find($scope.collection.properties, { column: column.column }).name);
        }

        return names.join(", ");
      };

      $scope.checkboxID = $scope.generateID("unique");

      /**
       * Re-orders the index columns to match the UI
       * @return {void}
       */
      function reorderIndex() {
        var oldIndex = angular.copy($scope.index);
        $scope.index.columns = [];
        for (var i = 0; i < $scope.featureColumns.length; i++) {
          var key = $scope.featureColumns[i];

          if ($scope.isChecked(oldIndex, key)) $scope.index.columns.push($scope.columns[key]);
        }
      }
    },
  };
});
