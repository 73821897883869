import { react2angular } from "react2angular";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { GridApi, IDatasource, IGetRowsParams } from "ag-grid-community";
import { useGetCollectionColumnDefsQuery, useLazyGetCollectionFeaturesQuery } from "../../slices/apiSlice";
import { ICollection } from "../../types/ICollection";
import { useAppSelector } from "../../hooks/useAppSelector";
import { withReduxProvider } from "../../services/withReduxProvider";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setConsoleCollection } from "../../slices/pagesSlice";

type InfiniteConsoleProps = { collection: ICollection };

export const InfiniteConsole = () => {
  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.pages.data.consoleCollection);

  const handleClose = () => {
    dispatch(setConsoleCollection());
  };

  if (!collection) return <></>;

  return (
    <div
      className="dock-bottom"
      id="main-console"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        maxHeight: "50%",
        width: "100%",
      }}
    >
      <div className="console-header" style={{ display: "flex" }}>
        <div className="console-title" style={{ flexGrow: 1 }}>
          {collection.name} [{collection.ID} - {collection.entityID}] - ({collection.type})
        </div>
        <span className="console-window-control btn arrow-down" onClick={handleClose}></span>
      </div>
      <div className="console-content">
        <InfiniteConsoleTable collection={collection} />
      </div>
      <div className="console-footer"></div>
    </div>
  );
};

/**
 * This is the data grid for viewing collection data in a table.
 * It's powered by AG Grid
 * @see https://www.ag-grid.com/react-data-grid/
 */
const InfiniteConsoleTable = ({ collection }: InfiniteConsoleProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();

  const [getFeatures] = useLazyGetCollectionFeaturesQuery();
  const { data: columnDefs } = useGetCollectionColumnDefsQuery(collection.ID, { skip: !collection });

  // resize columns when/if col defs change
  useEffect(() => {
    gridApi?.sizeColumnsToFit();
  }, [columnDefs]);

  // when the collection id changes, update the datasource
  useEffect(() => {
    gridApi?.setDatasource(datasource);
  }, [collection.ID]);

  const datasource: IDatasource = useMemo(
    () => ({
      getRows: async (params: IGetRowsParams) => {
        let limit = params.endRow - params.startRow + 1;

        const result = await getFeatures({ id: collection.ID, limit, offset: params.startRow });

        if (result.data) params.successCallback(result.data.data, result.data.meta.total);
      },
    }),
    [collection.ID]
  );

  return (
    <div
      id="myGrid"
      style={{
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
      }}
      className="ag-theme-balham"
    >
      {!collection ? (
        <p>Something happened and a collection wasn't passed to this console instance. Please try again.</p>
      ) : (
        <AgGridReact rowModelType="infinite" datasource={datasource} onGridReady={(e) => setGridApi(e.api)} defaultColDef={{ resizable: true }} columnDefs={columnDefs} />
      )}
    </div>
  );
};

export const AngularInfiniteConsole = react2angular(withReduxProvider(InfiniteConsole));
