export default angular.module("bootstrap.progress-bar", []).run(function ($rootScope, $state, ngProgressLite) {
  $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
    if (event.defaultPrevented) return;

    // Make sure the other animations are done before firing a new progressbar
    ngProgressLite.done();

    ngProgressLite.start();
  });

  $rootScope.$on("$stateChangeCancel", function () {
    ngProgressLite.done();
  });

  $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
    ngProgressLite.done();
  });

  $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams) {
    ngProgressLite.done();
  });
}).name;
