angular.module("common").directive("fadeSwitch", function ($parse) {
  return {
    link: function ($scope, element, attrs) {
      if (!$parse(attrs.fadeSwitch)($scope)) element.css({ opacity: 0, display: "none" });
      else element.css({ opacity: 1, display: "block" });

      $scope.$watch(attrs.fadeSwitch, function (fade, oldVal) {
        if (fade != oldVal) {
          element.stop();
          if (fade) {
            element.css({ position: "relative", display: "block", opacity: 0 });
            element.animate({ opacity: 1 });
          } else {
            element.css({ position: "absolute" });
            element.animate(
              { opacity: 0 },
              {
                complete: function () {
                  element.css({ display: "none" });
                },
              }
            );
          }
        }
      });
    },
  };
});
