/**
 * Directive for the .collapse-triangle
 */

angular.module("common").directive("epiCollapseTriangle", function ($parse) {
  return {
    restrict: "A",
    templateUrl: "/directives/epi-collapse-triangle.html",
    replace: true,
    link: function (scope, element, attrs) {
      var action = $parse(attrs.action);

      scope.action = function () {
        action(scope);
      };
    },
  };
});
