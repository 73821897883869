/**
 * Directive for Fine Uploader's S3 module
 * @name fineUploader
 * @memberOf common
 * @ngdoc directive
 * @scope false
 * @restrict E
 * @see [FineUploader](http://fineuploader.com/)
 * @description
 *   # Directive for Fine Uploader
 *
 *   This directive adds a couple of extra events and properties. Note that `API_ROOT`, `ROOT`, `S3_BUCKET_URL`, `S3_ACCESS_KEY`, and `S3_REGION` all have to be set the in configuration file for this to work.
 *
 *   ## Callbacks:
 *
 *   ### `onSelect`
 *
 *     Example:
 *
 *    ```
 *     onSelect: function(selectedFile) {
 *       // `this` is the uploader instance
 *       // selectedFile is…well…the selected file
 *     }
 *    ```
 *
 *   ## Properties
 *
 *   ### `state`
 *
 *   `uploads`: a list of uploads from `getUploads()`
 *   `uploadComplete`: whether the uploads are complete
 *   `isUploading`: whether it's uploading or not…
 *   `progress`: the current progress, as a decimal number between 0 and 1
 *
 *
 *   ## Custom Button
 *   This directive supports a custom button via the fineUploaderButton directive:
 *
 *  ```
 *   <fine-uploader options="options">
 *     <button class="btn">Select new file(s)</button>
 *   </fine-uploader>
 *  ```
 *
 * @example <fine-uploader options="options"></fine-uploader>
 */

angular.module("common").directive("fineUploader", function (API_ROOT, ROOT, S3_BUCKET_URL, S3_ACCESS_KEY, S3_REGION, DEV, $rootScope, $parse, $timeout) {
  return {
    restrict: "E",
    transclude: true,
    templateUrl: "/directives/fine-uploader.html",
    controller: function () {
      /**
       * Setter for the button!
       * @param {Element} button
       */
      this.addButton = function (button) {
        this.button = button;
      };
    },
    link: function ($scope, $element, $attrs, ctrl, $transclude) {
      // If there's a button, get it, and add it before this element
      if (ctrl.button) $element.before(ctrl.button);

      // Grab the uploader options
      let uploaderOptions = $parse($attrs.options)($scope);

      // First make sure that all the required endpoints are set. If they aren't, throw an error!

      // Upload success and signature are always required
      if (!uploaderOptions.uploadSuccess.endpoint) throw new ReferenceError(`fineUploader: uploadSuccess.endpoint is a required option`);

      if (!uploaderOptions.signature.endpoint)
        throw new ReferenceError(`fineUploader: signature.endpoint is a required option`)[
          // These are only required when gallery is true
          ("deleteFile", "session")
        ].forEach(function (requiredOption) {
          if ($scope.gallery && !uploaderOptions[requiredOption].endpoint) throw new ReferenceError(`fineUploader: ${requiredOption}.endpoint is a required option`);
        });

      const placeholder = new URL("../../assets/assets/_/img/placeholder.svg", import.meta.url).href;

      /**
       * This is the object of default settings. They can be overridden at any time
       * @type {Object}
       */
      let defaults = {
        // Turn off debug in production, on for local development
        debug: DEV,

        // Switch the template based on whether the gallery option is turned on or off
        template: uploaderOptions.gallery ? "fine-uploader-gallery" : "fine-uploader-button",
        validation: {
          // image: {
          //   maxHeight: 512,
          //   maxWidth: 512
          // }
        },
        // Merge the thumbnail and scaling options with the implementation, and don't break if thumbnails/scaling isn't sepcified
        thumbnails: angular.extend(
          {
            enabled: false,
            timeBetweenThumbs: 0,
            placeholders: {
              waitingPath: placeholder,
              notAvailablePath: placeholder,
            },
          },
          "thumbnails" in uploaderOptions ? uploaderOptions.thumbnails : {}
        ),
        scaling: angular.extend(
          {
            hideScaled: true,
            sizes: [],
          },
          "scaling" in uploaderOptions ? uploaderOptions.scaling : {}
        ),
      };

      // Only add the option if there's a button
      if (ctrl.button) defaults.button = ctrl.button.get(0);

      /**
       * These are the required options. They cannot be overridden at any time for any reason
       * @type {Object}
       */
      let required = {
        element: $element.get(0),
        maxConnections: 1,
        chunking: {
          enabled: true,
          mandatory: true,
          concurrent: {
            enabled: true,
          },
        },
        resume: { enabled: true },
        cors: {
          expected: true,
          sendCredentials: true,
        },
        request: {
          endpoint: S3_BUCKET_URL,
          accessKey: S3_ACCESS_KEY,
        },
        signature: {
          version: 4,
          endpoint: `${API_ROOT}/${uploaderOptions.signature.endpoint}?entityID=${$rootScope.entityID}`,
        },
        uploadSuccess: {
          enabled: true,
          endpoint: `${API_ROOT}/${uploaderOptions.uploadSuccess.endpoint}?entityID=${$rootScope.entityID}`,
        },
        deleteFile: {
          enabled: true,
          endpoint: `${API_ROOT}/${uploaderOptions.deleteFile ? uploaderOptions.deleteFile.endpoint : ""}`,
          params: {
            entityID: $rootScope.entityID,
          },
        },
        objectProperties: {
          region: S3_REGION,
          key: function (id) {
            let directory = `entity_${$rootScope.entityID}/${uploaderOptions.directory}/`;

            // If this type of FineUploader instance uploads scaled images (thumbnails), use special endpoints
            if (((uploaderOptions || {}).scaling || {}).sizes && uploaderOptions.scaling.sizes.length > 0) directory += uploader.getParentId(id) ? `thumbs/` : `orig/`;

            return directory + uploader.getUuid(id) + `_` + uploader.getName(id);
          },
        },
        session: {
          endpoint:
            uploaderOptions.gallery == true && uploaderOptions.session && uploaderOptions.session.endpoint
              ? `${API_ROOT}/${uploaderOptions.session.endpoint}?entityID=${$rootScope.entityID}`
              : null,
          cors: {
            expected: true,
            sendCredentials: true,
          },
        },
        callbacks: angular.extend({}, uploaderOptions.callbacks, {
          onSubmitted: function () {
            this.updateState();

            runCallback("onSubmitted", this, arguments);
          },
          onDelete: function (id) {
            this.setDeleteFileParams({ entityID: $rootScope.entityID }, id);

            runCallback("onDelete", this, arguments);
          },
          onError: function (id, name, errorReason, xhrOrXdr) {
            this.addError(errorReason);
            this.updateState();

            runCallback("onError", this, arguments);
          },
          onUpload: function () {
            this.state.isUploading = true;
            this.clearErrors();
            this.updateState();

            runCallback("onUpload", this, arguments);
          },
          onUploadChunk: function () {
            this.updateState();
            runCallback("onUploadChunk", this, arguments);
          },
          onTotalProgress: function (uploaded, total) {
            this.state.progress = uploaded / total;
            runCallback("onTotalProgress", this, arguments);
          },
          onSelect: function (selectedFile) {},
          onComplete: function (id, name, responseJSON, xhrOrXdr) {
            bindOnSelect.call(this);
            this.updateState();

            this.state.isUploading = false;
            runCallback("onComplete", this, arguments);
          },
          onSessionRequestComplete: function (response) {
            let uploader = this;
            bindOnSelect.call(this);

            runCallback("onSessionRequestComplete", this, arguments);
          },
        }),
      };

      function bindOnSelect() {
        $element.find("li.qq-upload-success").each(function () {
          let id = uploader.getId(this);
          $(this).on("click", function () {
            (uploaderOptions.callbacks.onSelect || required.callbacks.onSelect).call(uploader, uploader.getUploads({ id }));
          });
        });
      }

      /**
       * Runs a particular callback with the correct context and args
       * @param  {string} name    the name of the method that should be called
       * @param  {object} context the context to apply to the method call
       * @param  {array} args     the forwarded args from the original callback
       * @return {void}
       */
      function runCallback(name, context, args) {
        if (uploaderOptions.callbacks && uploaderOptions.callbacks[name]) uploaderOptions.callbacks[name].apply(context, args);
      }

      // Create the options object, first passing in the defaults, then the instance options, and finally, the required options, so that they override everything
      let options = angular.extend(defaults, uploaderOptions, required);

      // After extending the options, wrap all the callback in a $scope.$apply so that Angular picks up on changes
      for (let func in options.callbacks) {
        let originalCallback = options.callbacks[func];

        options.callbacks[func] = function () {
          let forwardedArgs = arguments;

          // Wrap the $apply in a timeout, otherwise in some circumstances, it will error out due to a $digest cycle already running
          // If there isn't a $digest running, this will run right away, else it will wait until the $digest is done, and then run
          setTimeout(() => {
            $scope.$apply(() => {
              originalCallback.apply(this, forwardedArgs);
            });
          });
        };
      }

      // Create an s3 instance of FineUploader!
      let uploader = new qq.s3.FineUploader(options);

      // Declare the "start" or "base" state of the state value, and initialize the state property using the base state
      let baseState = {
        progress: 0,
        hasErrors: false,
        errors: [],
      };
      uploader.state = baseState;

      let originalReset = uploader.reset;

      /**
       * Records an error to the current list of errors
       * @param {string} errorReason
       */
      uploader.addError = function (errorReason) {
        this.state.errors.push(errorReason);
      };

      /**
       * Clears all errors from the error list
       */
      uploader.clearErrors = function () {
        this.state.errors = [];
      };

      /**
       * Updates the state object on the uploader
       * @return {void}
       */
      uploader.updateState = function () {
        // Make sure our list of uploads is correct
        this.state.uploads = this.getUploads();

        // Refresh the "haveUploaded" attribute
        var netUploads = this.getNetUploads();
        this.state.uploadComplete = netUploads > 0 && netUploads === this.getUploads().length;

        // Refresh our tracker for whether or not there are errors
        this.state.hasErrors = this.state.errors.length > 0;
      };

      /**
       * Resets the uploader
       * @param  {Boolean} [shouldClearErrors=true] whether to clear the errors or not when resetting
       * @return {void}
       */
      uploader.reset = function (shouldClearErrors = true) {
        if (!shouldClearErrors) var savedErrors = uploader.state.errors;

        uploader.state = baseState;

        originalReset.call(uploader);

        if (shouldClearErrors) uploader.clearErrors();
        else uploader.state.errors = savedErrors;

        uploader.updateState;
      };

      $scope.uploader = uploader;
    },
  };
});
