/**
 * Edit User Controller
 */

angular.module("routes:admin").controller("admin:edit-user", function ($scope, $stateParams, user) {
  $scope.user = user;
  $scope.processing = false;

  $scope.brands = {'Epiphany Analytics': 'default', 'VisionMap': 'morrow-hill'};

  $scope.setAllowedLoginBrand = function (isAllowed, brand) {
    if (!Array.isArray(user.allowedLoginBrands))
      user.allowedLoginBrands = [];

    if (isAllowed && !user.allowedLoginBrands.includes(brand))
      user.allowedLoginBrands.push(brand);
    else if (!isAllowed)
      user.allowedLoginBrands = user.allowedLoginBrands.filter(b => b != brand);
  }

  $scope.isAllowedLoginBrand = function (brand) {
    return Array.isArray(user.allowedLoginBrands) && user.allowedLoginBrands.includes(brand);
  }

  $scope.update = function (user) {
    if ($scope.processing == true) return;

    $scope.processing = true;
    user.put().then(
      function (response) {
        $scope.error = null;
        $scope.processing = false;
      },
      function (response) {
        $scope.processing = false;
        $scope.error = response.data.status.message;
      }
    );
  };
});
