export const PermissionManager = function (permissions) {
  /**
   * The user's permissions
   * @type {Object}
   */
  this.permissions = permissions;

  /**
   * All the different permission types as:
   *         <permission name>: <permission string>
   * @type {Object}
   */
  this.types = {
    delete: "",
    create: ".create",
    read: ".read",
    update: ".update",
    collaborate: ".collaborate",
  };

  /**
   * The permission hierarchy
   * Zero-indexed, with zero being the highest
   * @type {Array}
   */
  this.permissionHierarchy = ["delete", "create", "update", "collaborate", "read"];
};

PermissionManager.prototype = {
  ////////////////////
  // Getter methods //
  ////////////////////

  /**
   * Get the permission types
   * @return {object}
   */
  getPermissionTypes: function () {
    return this.types;
  },

  /**
   * Get the permission hierarchy
   * @return {array}
   */
  getTypeHierarchy: function () {
    return this.permissionHierarchy;
  },

  ////////////////////
  // Public Methods //
  ////////////////////

  /**
   * Set the effective permissions on a given area, taking ancestors into account
   * @param {string} area
   * @param {string} accessType
   * @return {void}
   */
  set: function (area, accessType) {
    this.removePermissionArea(area);
    // get effective permissions of parent (i.e., inherited permissions)
    var granted = this.get(area),
      // expand access type to include its children
      desired = typeof accessType == "string" ? this.expandAccessType(accessType) : accessType,
      // get index of accessType (will be max desired index)
      index = this.findHighestType(desired),
      toSet = {},
      grantedSum = 0;

    // Loop through the hierarchy starting at the bottom
    for (var i = 0; i < this.permissionHierarchy.length; i++) {
      var value = 0;

      // If the current index in hierarchy is less than or equal to max desired index, set as 1
      if (i >= index && index !== null) value = 1;
      else if (granted[this.permissionHierarchy[i]]) value = -1;
      grantedSum += value;
      if (value != 0) toSet[this.permissionHierarchy[i]] = value;
    }

    if (grantedSum == 4) {
      this.permissions[area] = 1;
    } else {
      for (var permission in toSet) {
        this.permissions[area + "." + permission] = toSet[permission];
      }
    }
  },

  /**
   * Get the effective permissions on a given area, taking ancestors into account
   * @param  {string} area
   * @return {object} a dictionary of the permissions
   */
  get: function (area) {
    var permissions = {};
    for (var i = 0; i < this.permissionHierarchy.length; i++) {
      var accessAbove = this.hasAccessAbove(area, this.permissionHierarchy[i]);
      // console.log('accessAbove', area, this.permissionHierarchy[i], accessAbove)
      var access = this.permissions[area + "." + this.permissionHierarchy[i]];
      permissions[this.permissionHierarchy[i]] = access ? Number(access > 0) : Number(accessAbove);
    }

    return permissions;
  },

  /**
   * Delete permissions for all descendants so that only the parent's are effective
   * @param  {string} area
   * @return {void}
   */
  clearDescendants: function (area) {
    // Loop through all the permissions and figure out
    // if the permission is below the area in question
    for (var permission in this.permissions) {
      // Split the permission so that splitArea is the area only
      var splitPermission = this._splitPermission(permission),
        splitArea = splitPermission[0];

      // If the beginning of the area contains the area in question
      // And it *isn't* the area,
      // Delete it
      if (splitArea.indexOf(area) === 0 && splitArea !== area) {
        delete this.permissions[permission];
      }
    }
  },

  /**
   * Get a summary of the permissions under a certain area
   * @param  {string} area
   * @return {object} the summary
   *         {"access": "<area access>", "high": "<highest granted permission>", "low": "<lowest granted permission>"}
   */
  summarize: function (area) {
    var permissions = this.getAccessUnder(area),
      highsArray = [],
      highs = {},
      areaHigh;

    // Loop through all the user permissions
    for (var i = 0; i < permissions.length; i++) {
      var split = this._splitPermission(permissions[i]),
        splitArea = split[0],
        splitType = split[1],
        index = _.indexOf(this.permissionHierarchy, splitType);

      if (splitType === null) index = 0;

      // Create an object with the highest type for each permission
      if (!(splitArea in highs)) {
        highs[splitArea] = index;
      } else if (index < highs[splitArea]) {
        highs[splitArea] = index;
      }
    }

    // Now loop through that object and push it to a new array
    for (var each in highs) {
      highsArray.push(highs[each]);
    }

    // Sort it numerically (highest is at the beginning)
    highsArray.sort();

    // At this point, the highest & lowest permission has been found,
    // Now all that's left is the find the highest granted permission for the area in question

    if (this.permissions[area] == 1) {
      areaHigh = 0;
    } else {
      if (this._hasLocalRules(area) == true) {
        var permissions = this.get(area);
        areaHigh = this.findHighestType(permissions);
      }
    }

    // Return all the *string* values for the type indexes
    return {
      access: this.permissionHierarchy[areaHigh],
      high: this.permissionHierarchy[highsArray[0]],
      low: this.permissionHierarchy[highsArray[highsArray.length - 1]],
    };
  },

  /**
   * Expand access type to include children
   * @param  {string} type
   * @return {object}
   */
  expandAccessType: function (type) {
    var types = this.permissionHierarchy.slice(_.indexOf(this.permissionHierarchy, type), this.permissionHierarchy.length);

    var returnTypes = {};
    for (var i = 0; i < this.permissionHierarchy.length; i++) {
      returnTypes[this.permissionHierarchy[i]] = _.indexOf(types, this.permissionHierarchy[i]) === -1 ? 0 : 1;
    }
    return returnTypes;
  },

  /**
   * Deletes all the permissions for a specific area in a user's permission object
   * @param  {object} userPermissions
   * @param  {string} permissionString
   * @return {void}
   */
  removePermissionArea: function (permissionString) {
    for (var type in this.types) {
      delete this.permissions[permissionString + this.types[type]];
    }
  },

  /**
   * Finds the highest permission type from an array of permission types
   * @param  {object} types an object of types with values
   * @return {integer}
   */
  findHighestType: function (types) {
    // Start at one more than the lowest permission (which is 4)
    var highest = 5;

    // Loop through all the types that were passed in
    for (var type in types) {
      // For those that are set to 1 (turned on)
      if (types[type] * 1 === 1) {
        var typeHierarchy = _.indexOf(this.permissionHierarchy, type);

        // If the type hierarchy is less than (higher up the permission hierarchy),
        // Then set the highest to that type
        if (typeHierarchy < highest) {
          highest = typeHierarchy;
        }
      }
    }
    if (highest === 5) highest = null;
    return highest;
  },

  /////////////////////
  // Private methods //
  /////////////////////

  /**
   * Split a permission into area and type
   * @param  {string} permission
   * @return {array} an array of the [area, type|null]
   */
  _splitPermission: function (permission) {
    // Split the permission at the last dot
    // Find the area and the last dot
    var lastDot = permission.lastIndexOf(".");
    var type = permission.substr(lastDot + 1, permission.length);
    var area = permission.substr(0, lastDot);

    // If the type is found in the permissions list
    // Return the [area, type]
    if (_.indexOf(this.permissionHierarchy, type) >= 0) return [area, type];

    // Otherwise return the permission (there's no type in the permission string)
    // And null for the type
    return [permission, null];
  },

  /**
   * Find out whether an area has a permission
   *    for a certain type at *or* above the area in question
   *
   * @todo: needs to support denial of permission (-1)
   *
   * @param  {string}  area
   * @param  {string}  [type]
   * @return {Boolean}
   */
  hasAccessAbove: function (area, type) {
    if (this.isSuper() || this.permissions[area + "." + type] == 1) return true;

    if (this.permissions[area + "." + type] == -1) return false;

    for (var permission in this.permissions) {
      if (area.indexOf(permission) === 0 && this.permissions[permission] === 1) return true;

      var permissionSplit = this._splitPermission(permission),
        splitArea = permissionSplit[0],
        splitType = permissionSplit[1];

      if (area.indexOf(splitArea) === 0 && (type == splitType || splitType == null) && this.permissions[permission] === 1) return true;
    }

    return false;
  },

  /**
   * Get the access below a specific area
   * @param  {string} area
   * @return {array}
   */
  getAccessUnder: function (area) {
    var matching = [];
    for (var permission in this.permissions) {
      var splitArea = this._splitPermission(permission)[0];

      if (splitArea.indexOf(area) === 0 && this.permissions[permission] === 1 && splitArea !== area) {
        matching.push(permission);
      }
    }
    return matching;
  },

  /**
   * Determine if this user is super/admin
   * @return {boolean}
   */
  isSuper: function () {
    return this.permissions && this.permissions.superuser == 1;
  },

  /**
   * Check whether the person has enought of a related permission that they should see the menu item
   * @param {string} area
   * @return {boolean}
   */
  hasMenuAccess: function (area) {
    if (this.isSuper()) return true;

    for (var permission in this.permissions) {
      var grantedArea = this._splitPermission(permission)[0];

      // if the granted permission exactly matches the area, they have access
      if (grantedArea == area && this.permissions[permission] == 1) return true;

      // If the granted area is an ancestor of the requested area, they have access above and can see the menu
      if (area.indexOf(grantedArea) === 0 && this.permissions[permission] == 1) return true;

      // If the granted area is a child of the requested area, they have access below and can see the menu
      if (grantedArea.indexOf(area) === 0 && this.permissions[permission] == 1) return true;
    }

    return false;
  },

  _hasLocalRules: function (area) {
    if (this.permissions[area]) return true;

    for (var i = this.permissionHierarchy.length - 1; i >= 0; i--) {
      if (this.permissions[area + "." + this.permissionHierarchy[i]]) return true;
    }

    return false;
  },
};
