/**
 * Create a problem ;)
 */
angular.module("common").directive("createProblem", function ($window) {
  return {
    scope: {
      on: "=",
    },
    restrict: "A",
    template: '<canvas id="problem"></canvas>',
    link: function (scope, element, attrs) {
      scope.$watch("on", function (on) {
        if (on) createProblem();
      });

      var canvas = document.getElementById("problem"),
        ctx = canvas.getContext("2d");

      function resize() {
        canvas.style.width = $window.innerWidth + "px";
        canvas.style.height = $window.innerHeight + "px";
      }
      resize();
      $window.onresize = resize;

      function createProblem() {
        $(canvas).show();

        // a variant using fixed canvas size but stretches the result.
        // emulates interference/bad reception
        // using a different "noise" algo
        canvas.width = canvas.height = 256;

        function noise(ctx) {
          var w = ctx.canvas.width,
            h = ctx.canvas.height,
            idata = ctx.getImageData(0, 0, w, h),
            buffer32 = new Uint32Array(idata.data.buffer),
            len = buffer32.length,
            i = 0,
            pr = 456 * Math.random(),
            prs = 716 * Math.random();
          for (; i < len; ) {
            buffer32[i++] = (pr % 255 | 0) << 24;
            pr += prs * 1.2;
          }
          ctx.putImageData(idata, 0, 0);
        }

        (function loop() {
          noise(ctx);
          requestID = $window.requestAnimationFrame(loop);
        })();
      }
    },
  };
});
