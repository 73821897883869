/**
 * Spinner directive
 */

angular.module("common").directive("epiSpinner", function () {
  return {
    restrict: "C",
    templateUrl: "/directives/epi-spinner.html",
    link: function () {
      if ("WebkitTransition" in document.body.style || "MozTransition" in document.body.style || "OTransition" in document.body.style || "transition" in document.body.style) {
      }
    },
  };
});
