/**
 * Ng-Multi-Transclude directive
 * Lifted from: http://zachsnow.com/#!/blog/2013/angularjs-multi-transclusion/
 */

angular.module("common").directive("ngMultiTransclude", function () {
  return {
    controller: function ($scope, $element, $attrs, $transclude, $log) {
      if (!$transclude) {
        throw {
          name: "DirectiveError",
          message: "ng-multi-transclude found without parent requesting transclusion",
        };
      }
      this.$transclude = $transclude;
    },

    link: function ($scope, $element, $attrs, controller, $log) {
      console.log($element);
      var selector = "[name=" + $attrs.ngMultiTransclude + "]";
      var attach = function (clone) {
        var $part = clone.find(selector).addBack(selector);
        $element.html("");
        $element.append($part);
      };

      if (controller.$transclude.$$element) {
        attach(controller.$transclude.$$element);
      } else {
        controller.$transclude(function (clone) {
          controller.$transclude.$$element = clone;
          attach(clone);
        });
      }
    },
  };
});
