/**
 * Admin/index.js (handles all the routing for the admin pages)
 */

export default angular.module("routes:admin", []).config(function ($stateProvider) {
  $stateProvider
    .state("admin", {
      url: "/admin",
      templateUrl: "/admin/admin-init.html",
      controller: "admin:init",
      parent: "init",
    })
    .state("admin.users-and-entities", {
      url: "/users-and-entities",
      templateUrl: "/admin/users-and-entities.html",
      controller: "admin:users-and-entities",
      resolve: {
        users: function (User) {
          return User.getList();
        },
        entities: function (Entity) {
          return Entity.getList();
        },
        // get a list of all available entity permissions
        permissions: function ($q, $http, API_ROOT) {
          var deferred = $q.defer();
          $http({
            method: "GET",
            url: API_ROOT + "/entities/permissions",
          }).then(function (response) {
            deferred.resolve(response.data.data);
          });
          return deferred.promise;
        },
      },
    })
    .state("admin.users-and-entities.edit-user.permissions", {
      url: "/permissions",
      views: {
        "@admin": {
          templateUrl: "/admin/edit-user-permissions.html",
          controller: "admin:edit-user-permissions",
        },
      },
      resolve: {
        userEntities: function (Entity, $stateParams) {
          return Entity.getList({
            userID: $stateParams.userID,
            limit: 10000000,
          });
        },
      },
    })
    .state("admin.users-and-entities.edit-user", {
      url: "/user/:userID",
      views: {
        right: {
          templateUrl: "/admin/edit-user.html",
          controller: "admin:edit-user",
        },
      },
      resolve: {
        user: function (users, $stateParams, User) {
          var user = users.find($stateParams.userID * 1);
          if (!user) return User.get($stateParams.userID);
          return user;
        },
      },
    })
    .state("admin.users-and-entities.edit-entity", {
      url: "/entity/:id",
      views: {
        right: {
          templateUrl: "/admin/edit-entity.html",
          controller: "admin:edit-entity",
        },
      },
    })
    .state("admin.users-and-entities.create-user", {
      url: "/user",
      views: {
        right: {
          templateUrl: "/admin/edit-user.html",
          controller: "admin:create-user",
        },
      },
    })
    .state("admin.users-and-entities.create-entity", {
      url: "/entity",
      views: {
        right: {
          templateUrl: "/admin/edit-entity.html",
          controller: "admin:create-entity",
        },
      },
    })
    .state("admin.users-and-entities.edit-entity-permissions", {
      url: "/edit-entity-permissions/:id",
      views: {
        right: {
          templateUrl: "/admin/edit-entity-permissions.html",
          controller: "admin:edit-entity-permissions",
        },
      },
      resolve: {
        entity: function (entities, $stateParams, Entity) {
          var entity;
          var id = $stateParams.id * 1;
          if (entities) entity = entities.find(id);
          if (!entity) return Entity.get(id);
          return entity;
        },
      },
    })
    .state("admin.emails", {
      url: "/emails",
      templateUrl: "/admin/emails.html",
      controller: "admin:emails",
      resolve: {
        emails: function (Email) {
          return Email.getList();
        },
      },
    })
    .state("admin.emails.edit", {
      url: "/:emailID",
      views: {
        right: {
          templateUrl: "/admin/email-edit.html",
          controller: "admin:email-edit",
          resolve: {
            emailIndex: function (emails, $stateParams) {
              return emails.indexOfID($stateParams.emailID * 1);
            },
            email: function (emails, emailIndex, Restangular) {
              return Restangular.copy(emails[emailIndex]);
            },
          },
        },
      },
    })
    .state("admin.emails.create", {
      url: "",
      views: {
        right: {
          templateUrl: "/admin/email-edit.html",
          controller: "admin:email-create",
        },
      },
    });
}).name;

require("./controllers/admin-init");
require("./controllers/create-entity");
require("./controllers/create-user");
require("./controllers/edit-entity");
require("./controllers/edit-entity-permissions");
require("./controllers/edit-user");
require("./controllers/edit-user-permissions");
require("./controllers/email-create");
require("./controllers/email-edit");
require("./controllers/emails");
require("./controllers/users-and-entities");

require("./directives/month-day-select");
