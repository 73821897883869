import { IAccordionConfig, IDatepickerConfig } from "angular-ui-bootstrap";
import { useEffect, useRef, useState } from "react";
import { getService } from "react-in-angularjs";
import { useOutsideClick } from "../hooks/useOutsideClick";
import angular from "angular";

interface IAngularUibDatePickerProps {
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date) => void;
  disabled?: boolean;
  datepickerOptions?: IDatepickerConfig;
}

interface ILocalScope extends angular.IScope, IAngularUibDatePickerProps {
  onClick: () => void;
  isOpen: boolean;
}

export const AngularUibDatePicker = ({ value, minDate, maxDate, onChange, disabled, datepickerOptions }: IAngularUibDatePickerProps) => {
  const $compile = getService("$compile") as angular.ICompileService;
  const $rootScope = getService("$rootScope") as angular.IRootScopeService;

  const ref = useRef<HTMLDivElement>(null);
  const [el, setEl] = useState<JQLite>();
  const [$scope, setScope] = useState<ILocalScope>($rootScope.$new() as ILocalScope);

  // disabled
  useEffect(() => {
    if ($scope && $scope.disabled !== disabled) {
      setTimeout(() => {
        $scope?.$apply(() => {
          $scope.disabled = disabled;
        });
      });
    }
  }, [disabled]);

  useEffect(() => {
    if (ref?.current && !el) {
      $scope.value = value;
      $scope.minDate = minDate;
      $scope.maxDate = maxDate;
      $scope.onChange = (date: Date) => {
        onChange(date);
      };
      $scope.disabled = disabled;
      $scope.onClick = () => {
        $scope.isOpen = true;
      };
      $scope.isOpen = false;
      $scope.datepickerOptions = datepickerOptions;
      setScope($scope);
      const element = $compile(
        `<span><input
            type="text"
            ng-model="value"
            ng-disabled="disabled"
            ng-click="isOpen = true"
            ng-change="onChange(value)"
            uib-datepicker-popup="yyyy/MM/dd"
            is-open="isOpen"
            datepicker-options="datepickerOptions"
            placeholder="Today"
          /></span>`
      )($scope);
      setEl(element);
      ref.current.appendChild(element[0]);
    }
  }, []);

  useOutsideClick(ref, function () {
    $scope?.$apply(() => {
      $scope.isOpen = false;
    });
  });

  return <span ref={ref} />;
};
