const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

angular.module("routes:admin").controller("admin:users-and-entities", function ($rootScope, $scope, $timeout, $state, users, entities, permissions) {
  store.dispatch(
    updateGlobalState({
      title: "User and Entity Manager",
      h1: "Administration",
      activePage: "admin",
      activeSection: "admin.users-and-entities",
    })
  );

  $scope.entities = entities;
  $scope.users = users;
  $scope.permissions = permissions;

  $scope.closeSidePanel = function () {
    $state.go("admin.users-and-entities");
  };

  $scope.attachUser = function (entity, userID) {
    console.log("attaching user #" + userID + " to entity #" + entity.ID);
    var IDs = entity.userIDs();
    IDs.push(userID);
    entity.putUsers({ users: IDs });
  };

  $scope.detachUser = function (entity, userID) {
    console.log("detaching user #" + userID + " from entity #" + entity.ID);
    var IDs = entity.userIDsWithout(userID);
    entity.putUsers({ users: IDs });
  };

  /**
   * Search users or entities
   * @param {str} type "users" or "entities"
   * @return {void}
   */
  function search(type) {
    if ($scope.isSearching[type]) return;

    // prevent other searches from starting
    $scope.isSearching[type] = true;

    $timeout(function () {
      // build the query
      var params = {};
      params[$scope.searchFields[type]] = $scope.searchQueries[type];
      // By default, the search request will include entityID, thereby limiting the search results.
      // That's never what we want in this admin section, so don't send entityID.
      params['entityID'] = null;

      // send the query
      var promise = $scope[type].search(params);

      // handle response
      promise.then(function (collection) {
        // so that we don't lose our ability to search, don't overwrite with an empty array
        if (collection.length) $scope[type] = collection;
        else $scope[type].splice(0, $scope[type].length);

        $scope.isSearching[type] = false;
      });
    }, 500);
  }

  $scope.isSearching = {
    users: false,
    entities: false,
  };
  $scope.searchFields = {
    users: "name",
    entities: "name",
  };
  $scope.searchQueries = {
    users: "",
    entities: "",
  };

  // search users
  $scope.$watch("searchQueries.users", function (val, oldVal) {
    if (typeof val == "undefined" || val == oldVal) return;

    search("users");
  });

  $scope.$watch("searchFields.users", function (val, oldVal) {
    if (typeof val == "undefined" || val == oldVal) return;

    search("users");
  });

  // search entities
  $scope.$watch("searchQueries.entities", function (val, oldVal) {
    if (typeof val == "undefined" || val == oldVal) return;

    search("entities");
  });

  $scope.$watch("searchFields.entities", function (val, oldVal) {
    if (typeof val == "undefined" || val == oldVal) return;

    search("entities");
  });

  $scope.sortableOptions = {
    connectWith: ".sortable-list",

    receive: function (event, ui) {
      var targetScope = ui.item.sortable.droptarget.scope();
      var senderScope = ui.sender.scope();
      var user = ui.item.sortable.moved;

      // are we dragging into an entity?
      if ("entity" in targetScope) {
        if (typeof ui.item.sortable.moved == "undefined") return false;

        // if original is not an entity, add back to original array
        if (!senderScope.entity) senderScope.users.splice(ui.item.sortable.index, 0, ui.item.sortable.moved);

        // now update the entity
        targetScope.attachUser(targetScope.entity, ui.item.sortable.moved.ID);
      }

      // we're dragging back into the list of users; make sure we don't have the same user twice
      else {
        for (var i = 0; i < targetScope.users.length; ++i) {
          if (targetScope.users[i].ID == user.ID) {
            // delete duplicate in target
            targetScope.users.splice(i, 1);
            // put new one where duplicate was
            ui.item.sortable.dropindex = i;
            break;
          }
        }

        // now update the entity
        targetScope.detachUser(senderScope.entity, ui.item.sortable.moved.ID);
      }
    },

    update: function (event, ui) {
      // if item has scope, this call to update() is for dragging into the target list
      if (typeof ui.item.scope() != "undefined") {
        var user = ui.item.scope().user;
        var entity = ui.item.sortable.droptarget.scope().entity;

        // if entity is set, we're dragging into an entity
        if (typeof entity != "undefined") {
          // if this user is already assigned to this entity, cancel the operation
          for (var i = 0; i < entity.users.length; ++i) {
            if (entity.users[i].ID == user.ID) {
              ui.item.sortable.cancel();
              return;
            }
          }
        }
      }
    },

    over: function (event, ui) {
      var placeholder = ui.placeholder;
      var scope = ui.placeholder.scope();

      placeholder.parent().addClass("dropping");
      if ("entity" in scope) {
        for (var i = 0; i < scope.entity.users.length; ++i) {
          if (scope.entity.users[i].ID == ui.item.scope().user.ID) {
            console.log("user is already there");
            placeholder.css("display", "none").parent().addClass("disabled");
            ui.item.addClass("disabled");
            return;
          }
        }
      }
    },

    out: function (event, ui) {
      jQuery(
        $("ul.dropping").each(function () {
          $(this).removeClass("dropping").removeClass("disabled");
        })
      );
    },
  };

  $scope.createUser = function () {
    $state.go("admin.users-and-entities.create-user");
  };
  $scope.createEntity = function () {
    $state.go("admin.users-and-entities.create-entity");
  };

  $scope.deleteUser = function ($index, user) {
    user.remove().then(function () {
      $scope.users.splice($index, 1);
    });
  };
});
