import { react2angular } from "react2angular";
import { getService } from "react-in-angularjs";
import { useEffect, useRef, useState } from "react";

import { NavbarLi, NavbarUl, TopNavbarSecondary } from "../../components/TopNavbarSecondary";
import { Button } from "../../design/Button";
import { store } from "../../store";
import { resetDataState, setDataSearch, toggleShowArchived, toggleType } from "../../slices/pagesSlice";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { CollectionTypes } from "../../types/CollectionTypes";
import { useAppSelector } from "../../hooks/useAppSelector";
import { withReduxProvider } from "../../services/withReduxProvider";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { API_ROOT } from "../../../app/configuration";

export const DataNavbar = () => {
  const $state = getService("$state");
  const dispatch = useAppDispatch();

  const { showArchived, selectedTypesFilter, searchString } = useAppSelector((state) => state.pages.data);
  const { entityID } = useAppSelector((state) => state.app);
  const typeRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOutsideClick(typeRef, () => setIsMenuOpen(false));

  useEffect(
    () => () => {
      dispatch(resetDataState());
    },
    []
  );

  const downloadDependencyGraph = () => {
    window.open(API_ROOT + "/mapping/collections/dependencies/image?entityID=" + entityID);
  };

  return (
    <TopNavbarSecondary title="TruTerritory Collections" noSecondaryMenu>
      <NavbarUl>
        <NavbarLi>
          <input type="text" placeholder="Search..." value={searchString ?? ""} onChange={({ target }) => store.dispatch(setDataSearch(target.value))} />
        </NavbarLi>
        <NavbarLi pullLeft>
          <div id="searchType" className="collection-search-type" ref={typeRef}>
            <div className="multiselect">
              <div className="toggle" onClick={() => setIsMenuOpen((val) => !val)}>
                {selectedTypesFilter.length === 4
                  ? "All types"
                  : selectedTypesFilter.length === 0
                  ? "None selected"
                  : selectedTypesFilter.map((t) => CollectionTypes.find(({ type }) => t === type)!.name).join(", ")}
              </div>
              <div className={`dropdown ${isMenuOpen ? "" : "ng-hide"}`}>
                {CollectionTypes.map(({ type, name }) => (
                  <div key={type} className="option" onClick={() => store.dispatch(toggleType(type))}>
                    <input type="checkbox" className={selectedTypesFilter.includes(type) ? "_checked" : ""} />
                    <label className="checkbox">
                      <div className="text">{name}</div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </NavbarLi>
        <NavbarLi>
          <Button
            className={`white ${showArchived ? "unarchive" : "archive"}`}
            onClick={() => store.dispatch(toggleShowArchived())}
            title={showArchived ? "Hide archived" : "Show archived"}
          ></Button>
        </NavbarLi>
        <NavbarLi>
          <Button
            className="white dependency-graph"
            onClick={downloadDependencyGraph}
            title="Show Dependency Graph"
          ></Button>
        </NavbarLi>
      </NavbarUl>
      <NavbarUl right>
        <NavbarLi>
          <Button className="light add-full" onClick={() => $state.go("data.create", { location: false })}>
            Add Collection
          </Button>
        </NavbarLi>
      </NavbarUl>
    </TopNavbarSecondary>
  );
};

export const AngularDataNavbar = react2angular(withReduxProvider(DataNavbar));
