angular.module("routes:admin").controller("admin:edit-entity-permissions", function ($scope, entity, $stateParams) {
  $scope.entity = entity;

  $scope.$watch(
    "entity.permissions",
    function (val, oldVal) {
      if (val == oldVal) return;

      $scope.entity.putPermissions({ permissions: val });
    },
    true
  );
});
