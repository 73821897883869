/*
 * Home-child.js controller (Dashboard entity children)
 * Called from within Home.js
 */

angular.module("routes").controller("home-child", function ($scope, $location) {
  $scope.active = {};
  $scope.allInactive = true;

  $scope.toggleActive = function (id, hasChildren) {
    if (!hasChildren) return;
    if ($scope.active[id]) {
      delete $scope.active[id];
    } else {
      $scope.active[id] = true;
      $scope.allInactive = false;
    }
    if (jQuery.isEmptyObject($scope.active)) {
      $scope.allInactive = true;
    }
  };
});
