import { skipToken } from "@reduxjs/toolkit/query";
import { useGetReportsQuery, useGetWorkspacesWithMapsQuery } from "../slices/apiSlice";
import { AvailableReport, IReport } from "../types/IReport";
import { useAppSelector } from "./useAppSelector";
import { useEffect, useMemo, useState } from "react";
import { LatLng } from "leaflet";
import { API_ROOT } from "../../app/configuration";

/**
 * Get a combined list of reports (old and new) which are available on the given map.
 * Note that "available" doesn't mean enabled. Context-specific checks will still determine
 * which of these reports to show where.
 */
export function useAvailableReports(latlng?: LatLng): { isLoading: boolean, reports: AvailableReport[]} {
  // Map (or its parent) contains the list of old-style map reports.
  const map = useAppSelector((state) => state.pages.truterritory.map);
  const { data: workspaces, isLoading: oldReportsLoading } = useGetWorkspacesWithMapsQuery(map?.isPublic ? skipToken : undefined); // Using this (big) query because it's already cached at the TruTerritory component.
  const workspace = workspaces && workspaces.find((w) => w.ID === map?.workspaceID);
  const parent = map?.parentID ? workspace?.maps.find((m) => m.ID == map.parentID) : null;

  const oldReports = parent?.reports || map?.reports || [];
  const { data: newReports, isLoading: newReportLoading } = useGetReportsQuery(parent?.ID || map?.ID || skipToken);
  const [backendEnabledOldReports, setBackendEnabledOldReports] = useState<any[]>([]);

  // If any of the old reports have a collectionID set which is not on the map, we'll have to ask the old
  // backend API for which ones to include.
  useEffect(() => {
    if (!oldReports.length || !latlng) {
      return;
    }

    const oldReportsNotCheckableByFrontend = oldReports.filter(r => r.collectionID && !map?.collectionIDs.includes(r.collectionID));
    if (!oldReportsNotCheckableByFrontend.length) {
      return;
    }

    const url = `${API_ROOT}/mapping/reports/visible/${map?.ID}/${latlng?.lat}/${latlng?.lng}?entityID=${map?.entityID}`;
    fetch(url, {credentials: 'include'}).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json();
    }).then(body => {
      setBackendEnabledOldReports(body.data);
    });
  }, [oldReports, latlng])

  return useMemo(
    () => ({
      reports: [
        ...(newReports || []).map(newReportToAvailable),
        ...oldReports.map(r => oldReportToAvailable(r, backendEnabledOldReports)),
      ],
      isLoading: oldReportsLoading || newReportLoading,
    }),
    [oldReports, newReports, backendEnabledOldReports]
  );
}

function newReportToAvailable(report: IReport): AvailableReport {
  return {
    ID: report.ID!,
    name: report.name,
    type: "new",
    interactionScope: report.interactionScope,
    collectionScope: report.collectionScope,
  };
}

function oldReportToAvailable(report: any, backendEnabledOldReports: any[]): AvailableReport {
  return {
    ID: report.ID,
    name: report.label,
    type: "old",
    interactionScope: [],
    collectionScope: report.requireGeometry && report.collectionID ? [report.collectionID] : [],
    enabled: report.enabled,
    acceptsSelection: report.acceptsSelection,
    mustDisplay: !!backendEnabledOldReports.find(r => r.ID == report.ID),
  };
}