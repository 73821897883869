/**
 * Various modals required by the mapping module
 */
angular.module("routes:mapping").factory("MappingModals", function ($uibModal, $stateParams, Session) {
  return {
    /**
     * Open the "save map" modal
     * TODO: move this to the new react map modal
     * @param  {Object} map
     * @param  {Function} save Function to call on save
     * @param  {map[]} parents An optional list of map objects which could be parents to this the one being saved.
     * @param  {string} saveAs An alternate name to give the map
     * @return {Promise}
     */
    openSaveMap(map, save, parents, saveAs, workspace) {
      // Clone it so the original isn't modified
      map = angular.copy(map);

      // By default, the new map's parent will be old map or the old map's parent, if set.
      map.parentID = map.parentID || map.ID;

      if (saveAs) map.name = saveAs;

      // Create the modal
      let modal = $uibModal.open({
        template: `<epi-edit-map-form map="map" parents="parents" workspace="workspace" on-save="save" on-cancel="$dismiss" parent-change-allowed="true" is-creating="true"></epi-edit-map-form>`,
        windowClass: "truterritory-save-map",
        controller: function ($scope, $uibModalInstance) {

          $scope.map = map;
          $scope.parents = parents;
          $scope.workspace = workspace;

          $scope.save = function (map) {
            save(map).then($uibModalInstance.close);
          };
        },
      });

      return modal.result;
    },

    /**
     * Open the "map has unsaved changes" modal
     * @param {string} msg
     * @return {Promise}
     */
    openSaveMapChanges(msg, save, saveText = "Save", cancelText = "Cancel") {
      let modal = $uibModal.open({
        templateUrl: "mapping/unsaved-changes-modal.html",
        controller: function ($scope, $uibModalInstance) {
          $scope.msg = msg;
          $scope.saving = false;
          $scope.saveText = saveText;
          $scope.cancelText = cancelText;
          $scope.save = function () {
            $scope.saving = true;
            save().then($uibModalInstance.close);
          };
          $scope.$on("enter", $scope.save);
        },
      });

      return modal.result;
    },

    /**
     * Open the "save workspace" modal
     * TODO: move this to the new react workspace modal;
     *   the only place this is still used is in the layers panel to add a workspace
     * @param  {Object} workspace
     * @param  {Function} save Function to call on save
     * @param  {string} saveAs An alternate name to give the workspace
     * @return {Promise}
     */
    openSaveWorkspace(workspace, save, saveAs) {
      // Clone it so the original isn't modified
      workspace = "clone" in workspace ? workspace.clone() : angular.copy(workspace);

      if (saveAs) workspace.name = saveAs;

      // Create the modal
      let modal = $uibModal.open({
        templateUrl: "mapping/save-workspace-modal.html",
        windowClass: "save-new-workspace",
        controller: function ($scope, $uibModalInstance) {
          $scope.workspace = workspace;
          $scope.saving = false;

          $scope.save = function () {
            $scope.saving = true;
            let form = $scope.validateForm("newWorkspace");
            if (form.isValid()) save(workspace).then($uibModalInstance.close);
          };
        },
      });

      return modal.result;
    },

    /**
     * Accept a password
     * @param {bool} first Message changes after the first time showing the modal.
     * @return {Promise}
     */
    openPassword(first = true) {
      let modal = $uibModal.open({
        backdrop: "static",
        keyboard: false,
        windowClass: "mapping-password",
        templateUrl: "mapping/password-modal.html",
        controller: function ($scope) {
          $scope.first = first;
        },
      });

      return modal.result;
    },
  };
});
