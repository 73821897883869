angular.module("common").directive("progressIndicator", function () {
  return {
    restrict: "C",
    scope: {
      displayed: "=",
      progress: "=progressDisplay",
      text: "=",
    },
    templateUrl: "/directives/progress-indicator.html",
  };
});
