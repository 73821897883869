import { LatLng } from "leaflet";
import { store } from "../../store";
import { ITruMap } from "../../types/ITruMap";
import { useEffect, useState } from "react";
import { useAvailableReports } from "../../hooks/useAvailableReports";
import { useGetMapActionsQuery } from "../../slices/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { SelectedFeatures } from "./MapSelectionActionsBar";
import { IMap } from "../../types/IMap";
import { getCollections } from "../../data/map";
import { ICollection } from "../../types/ICollection";
import { getService } from "react-in-angularjs";
import { API_ROOT } from "../../../app/configuration";
import { withReduxProvider } from "../../services/withReduxProvider";
import { AvailableReport } from "../../types/IReport";
import { setBackendActionInvocation, setReportInvocation } from "../../slices/pagesSlice";
import { point } from "@turf/helpers";
import { SelectionIDs } from "./MapBackendActions";
import { IMapAction, Interaction } from "../../types/IMapAction";
import { Box, CircularProgress } from "@mui/material";
import { useInfoRequestFeatures } from "../../hooks/useInfoRequestFeatures";

export type SectionItem = {
  name: string;
  onSelect?: (item: SectionItem, map: L.Map, latlng: LatLng) => void;
}

type Section = {
  header: string;
  items: SectionItem[];
  onSelect: (item: SectionItem, map: L.Map, latlng: LatLng) => void;
}

export interface IProps {
  latlng: LatLng;
  truMap: ITruMap;
  customOptions: SectionItem[];
  searchText?: {
    input: string;
    formatted: string;
  };
  clickedOnSearchPin: boolean;
}

const ContextMenuComponent: React.FC<IProps & JSX.IntrinsicAttributes> = ({ truMap, latlng, customOptions, searchText, clickedOnSearchPin }: IProps) => {
  const hasMapAccess = getService("hasMapAccess");

  const { map, availableCollections, selectedFeatures } = store.getState().pages.truterritory;
  const { data: features, isLoading: featuresLoading, error: featureReqError } = useInfoRequestFeatures(truMap, latlng);
  const { reports, isLoading: reportsLoading } = useAvailableReports(latlng);
  const { data: actions, isLoading: actionsLoading } = useGetMapActionsQuery(!map?.isPublic && hasMapAccess("collaborate") && map?.ID || skipToken);
  const didClickOnSelection = isWithinSelection(features, selectedFeatures, map, availableCollections);

  if (!latlng) {
    return <></>;
  }

  const angularContextMenu = getService("ContextMenu");

  const sections = [
    !didClickOnSelection && makeFeatureInfoSection(),
    makeCustomOptionsSection(),
    makeReportsSection(),
    !didClickOnSelection && makeActionsSection(),
  ].filter((s): s is Section => !!s);

  if (sections.length == 0) {
    sections.push({
      header: "",
      items: [],
      onSelect: () => {},
    });
  }

  /**
   * Handle a click on an an item in menu.
   */
  function select(section: Section, item: SectionItem, event: any) {
    event.stopPropagation();
    const func = typeof item.onSelect == "function" ? item.onSelect : section.onSelect;
    truMap.closeContextMenu();
    func(item, truMap.getLeafletMap(), latlng);
  }

  /**
   * Populate the list of layers clicked on as supplied by the tile server.
   */
  function makeFeatureInfoSection(): Section | undefined {
    if (!features?.length) {
      return;
    }

    return {
      header: "Show data:",
      items: features,
      onSelect: angularContextMenu.showFeatureProperties.bind(null, (ID: number) => availableCollections.find((c) => c.ID === ID), map?.isPublic)
    };
  }

  /**
   * Add in any options needed by the caller (like "Remove Marker").
   */
  function makeCustomOptionsSection(): Section | undefined {
    const options = {
      header: "Options:",
      items: [...customOptions],
      onSelect: () => {},
    };

    if (map?.reportsEnabled && !map.isPublic && !didClickOnSelection) {
      options.items.push({
        name: "Download Site Report",
        onSelect: (_item, _, latlng) => downloadSiteReport(map, truMap, searchText?.input, latlng),
      });
    }

    return options.items.length ? options : undefined;
  }

  /**
   * Make a section for reports (combining old and new).
   */
  function makeReportsSection(): Section | undefined {
    if (map?.isPublic) {
      return;
    }

    const collectionIDs = makeCollectionIDs();
    const items = [];

    for (const report of reports) {
      // Check if this report is enabled for the features the user clicked on.
      if (report.type == "new" && !isReportEnabled(report, collectionIDs, didClickOnSelection) ||
          report.type == "old" && !isOldReportEnabled(report, collectionIDs, didClickOnSelection)) {
        continue;
      }

      // @TODO: support circle/radius.
      // Include a point for all old reports and any new report without a collection scope.
      const sendPoint = report.type == "old" || !report.collectionScope.length;
      // Include a selection for old report which accept selection and new reports with a collection scope.
      const sendSelection = report.type == "old" && report.acceptsSelection || report.type == "new" && report.collectionScope.length;

      items.push({
        name: report.name,
        onSelect: () => store.dispatch(setReportInvocation({
          ID: report.ID,
          type: report.type,
          selection: sendSelection ? makeSelection() : undefined,
          point: sendPoint ? point([latlng.lng, latlng.lat]).geometry : undefined,
          address: searchText?.formatted || undefined,
          marker: clickedOnSearchPin ? [latlng.lng, latlng.lat] : undefined,
        }))
      })
    }

    if (!items.length) {
      return;
    }

    return {
      header: "Reports:",
      items,
      onSelect: () => {},
    };
  }

  /**
   * Make a section for applicable actions.
   */
  function makeActionsSection(): Section | undefined {
    if (!actions) {
      return;
    }

    const invoke = (action: IMapAction) => store.dispatch(setBackendActionInvocation({
      action,
      geometry: point([latlng.lng, latlng.lat]).geometry,
      input: {fields: {address: searchText?.formatted || "" }},
    }));

    const section = {
      header: "Actions:",
      items: actions
        .filter(a => a.enabled && a.scope && a.scope.includes(Interaction.CLICK))
        .map(action => ({ name: action.label || "", onSelect: () => invoke(action) })),
      onSelect: () => {},
    };

    return section.items.length ? section : undefined;
  }

  /**
   * Give a set of features clicked and/or selected, return a list of collection IDs.
   */
  function makeCollectionIDs(): number[] {
    const collections = getCollections(availableCollections, map);
    if (!collections.length) {
      return [];
    }

    if (selectedFeatures && Object.keys(selectedFeatures).length) {
      return Object.keys(selectedFeatures)
        .map(layerName => collections.find(c => c.tableName === layerName))
        .filter(c => !!c)
        .map(c => c.ID);
    }

    return features ? features.map(f => f.layerID) : [];
  }

  /**
   * Given a set of features clicked on, return a SelectionIDs object.
   */
  function makeSelection(): SelectionIDs {
    const collections = getCollections(availableCollections, map);
    if (!collections.length) {
      return {};
    }

    const selection: SelectionIDs = {};

    // Handle an intentional selection (of vectors).
    if (selectedFeatures && Object.keys(selectedFeatures).length) {
      for (const layerName in selectedFeatures) {
        selection[layerName] = Object.keys(selectedFeatures[layerName]);
      }

      return selection;
    }

    // Convert a list of features coming from the info request into a selection.
    for (const feature of features) {
      const collection = collections.find(c => c.ID === feature.layerID);
      if (!collection) {
        continue;
      }

      if (!(collection.tableName in selection)) {
        selection[collection.tableName] = [];
      }

      const featureID = collection.idColName ? feature.properties[collection.idColName] : feature.ID;
      selection[collection.tableName].push(`${featureID}`);
    }

    return selection;
  }

  return (
    <div className="menu">
      { featuresLoading || reportsLoading || actionsLoading ? (
        <Box p={1} display="flex" alignItems="center" justifyContent="center" width={"100%"}>
          <CircularProgress size={15} />
        </Box>

      ) : sections.map((section) => (
        <section key={section.header}>
          { !section.items.length && <h3 className="empty">Nothing here</h3> }
          { !!section.items.length && <h3>{section.header}</h3> }
          <ul>
            { section.items.map((item) => (
              <li key={item.name} onClick={select.bind(null, section, item)}>{item.name}</li>
            ))}
          </ul>
        </section>
      ))}
    </div>);
}

export const ContextMenu = withReduxProvider(ContextMenuComponent);


/**
 * By comparing what the server sent back for this click (everything intersecting with the points)
 * to vectors features selected, we can determine whether the user clicked inside the area of the
 * vector selection.
 */
function isWithinSelection(features: any[], selectedFeatures: SelectedFeatures | undefined, map: IMap | undefined, availableCollections: ICollection[]): boolean {
  if (!features || !features.length || !selectedFeatures) {
    return false;
  }

  const collections = getCollections(availableCollections, map);
  if (!collections.length) {
    return false;
  }

  for (const feature of features) {
    const collection = collections.find(c => c.ID === feature.layerID);
    if (!collection) continue;

    const featureID = collection.idColName ? feature.properties[collection.idColName] : feature.ID;
    const ids = selectedFeatures[collection.tableName] && Object.keys(selectedFeatures[collection.tableName]) || [];
    // == not === because it might be an integer/string comparison
    if (!!ids.find(id => id == featureID)) {
      return true;
    }
  }

  return false;
}

/**
 * Decide whether a report is enabled for the given click.
 * @param {AvailableReport} report
 * @param {string[]} collectionIDs  The list of collection IDs with which this click interacts.
 * @param {boolean} didClickOnSelection True if the the click was within an active selection (of vectors).
 */
function isReportEnabled(report: AvailableReport, collectionIDs: number[], didClickOnSelection: boolean): boolean {
  // First scenario: clicking within a selection.
  if (didClickOnSelection) {
    // Interaction scope must include "selection".
    if (!report.interactionScope.includes('selection')) {
      return false;
    }

    // Collection scope must be non-empty.
    if (!report.collectionScope.length) {
      return false;
    }
  }

  // Second scenario: clicking outside a selection or when no selection exists.
  else {
    // The report must be scoped to click.
    if (!report.interactionScope.includes('click')) {
      return false;
    }

    // If the report has no collection restrictions, it's enabled everywhere.
    if (!report.collectionScope.length) {
      return true;
    }
  }

  // Either selection or click:
  // Collection scope must overlap with the set of selected layers.
  for (const ID of collectionIDs) {
    if (report.collectionScope.includes(ID)) {
      return true;
    }
  }

  return false;
}

function isOldReportEnabled(report: AvailableReport, collectionIDs: number[], didClickOnSelection: boolean): boolean {
  // First scenario: clicking within a selection.
  if (didClickOnSelection) {
    return !!(report.enabled && report.acceptsSelection);
  }

  // Second scenario: clicking outside a selection or when no selection exists.
  if (!report.enabled || report.acceptsSelection) {
    return false;
  }

  // Does the report require clicking on a specific collection?
  if (report.collectionScope.length) {
    for (const ID of collectionIDs) {
      if (report.collectionScope.includes(ID)) {
        return true;
      }
    }

    // Backend override
    return !!report.mustDisplay;
  }

  return true;
}

/**
 * Get a site report
 */
function downloadSiteReport(map: IMap, truMap: ITruMap, searchText: string | undefined, latlng: LatLng) {
  searchText = searchText || "";
  let url = `${API_ROOT}/mapping/maps/${map.ID}/reports/${truMap.getTileLayerID()}/${latlng.lat}/${latlng.lng}?entityID=${map.entityID}&address=${searchText}`;
  window.open(url, "_blank");
}