/**
 * Common modules
 */

export default angular.module("modules", [require("./admin/index").default, require("./mapping/index").default]).name;

require("./condense-to");
require("./entity");
require("./interval");
require("./user");
