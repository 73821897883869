import { useEffect, useMemo, useRef, useState } from "react";

import { useOutsideClick } from "../hooks/useOutsideClick";
import { classNameMapper } from "../utils/classNameMapper";
import { ConfirmButton } from "./ConfirmButton";
import { IWorkspace } from "../types/IWorkspace";

interface IDropdownSelectorProps<T> {
  selection?: T;
  items: T[];
  onClick: (item: T) => void;
  onDelete: (item: T) => void;
  openWorkspaceModal: (workspace: any, cb: (workspace: IWorkspace) => void, name: string) => Promise<void>;
  createWorkspace: (workspace: IWorkspace) => void;
  maxWidth: string;
  minWidth: string;

  // the property to check on each item to determine whether it can be deleted
  restricted?: keyof T;
}

interface IDropdownSelectorItem {
  name: string;
  image?: string | null;
}

export const DropdownSelector = <T extends IDropdownSelectorItem>({
  selection,
  items: itemsList,
  onClick,
  onDelete,
  openWorkspaceModal,
  createWorkspace,
  maxWidth,
  minWidth,
  restricted,
}: IDropdownSelectorProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(rootRef, () => setIsOpen(false));

  const items = useMemo(() => {
    // sort the items by name
    const items = [...itemsList].sort((a, b) => a.name.localeCompare(b.name));

    // sort into two columns
    const half = Math.ceil(items.length / 2);
    return [items.slice(0, half), items.slice(half)];
  }, [itemsList]);

  const width = useMemo(() => {
    const [, maxWidthNum, units] = maxWidth.match(/^([0-9]+\.{0,1}[0-9]*)(.*)$/) ?? [];
    const width = (parseInt(maxWidthNum) / 2) * items.length + units;

    return width;
  }, [maxWidth, items]);

  const onAdd = () => {
    openWorkspaceModal({}, createWorkspace, "New Workspace").then(() => setIsOpen(false));
  };

  // this animates the dropdown when `isOpen` changes
  useEffect(() => {
    if (!dropdownRef.current || !rootRef.current) return;

    const dropdown = $(dropdownRef.current);
    const root = $(rootRef.current);

    if (root.hasClass("open") && !isOpen) {
      dropdown.hide("blind", null, 250);
      root.children("h6").css("width", minWidth);
      root.removeClass("open");
    } else if (!root.hasClass("open") && isOpen) {
      root.addClass("open");
      root.children("h6").css("width", width);
      dropdown.css("width", width);
      setTimeout(() => {
        dropdown.show("blind");
      });
    }
  }, [isOpen]);

  return (
    <div className="dropdown-selector" ref={rootRef}>
      <h6 onClick={() => setIsOpen((v) => !v)} title={JSON.stringify(selection, ['name', 'ID', 'default', 'userID', 'sorder', 'entityID'], 2)} style={{ width: minWidth }}>
        {selection ? selection.name : "(no selection)"}
        <span className={`caret ${classNameMapper({ up: isOpen })}`} />
      </h6>
      <button className="btn add" onClick={onAdd} />
      <div className="selector" ref={dropdownRef}>
        <div className={classNameMapper({ items: true, "single-column": !items[1] })}>
          {items.map((items, i) => (
            <div className="half" key={`dropdown-selector-half-${i}`}>
              {items.map((item, i) => (
                <div className="item" key={`${item.name}-${i}`}>
                  {item.image && <img src={item.image} />}
                  <div
                    className="text"
                    onClick={() => {
                      onClick(item);
                      setIsOpen(false);
                    }}
                    title={`Click to select '${item.name}'\n` + JSON.stringify(item, ['ID', 'name', 'default', 'userID', 'sharingEnabled', 'sorder', 'entityID', 'maps'], 2)}
                  >
                    {item.name}
                  </div>
                  <div className="controls">
                    {(!restricted || (item.hasOwnProperty(restricted) && !item[restricted]) || !item.hasOwnProperty(restricted)) && (
                      <ConfirmButton
                        type="del"
                        yes="Delete"
                        text={`Are you sure you want to remove yourself from ${item.name}?`}
                        callback={() => {
                          onDelete(item);
                          setIsOpen(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
