/**
 * Directive to wrap [nicescroll](https://github.com/inuyaksa/jquery.nicescroll)
 *   Example:
 *     <div nicescroll></div>
 *
 *  Passing options in:
 *     <div nicescroll nicescroll-options={[options]}></div>
 *
 *  Default offset:
 *    {top: 0, right: 0, left: 0, bottom: 0}
 */

angular.module("common").directive("nicescroll", function ($window) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var defaultOffset = { top: 0, right: 0, left: 0, bottom: 0 };
      var offset = angular.extend(defaultOffset, scope.$eval(attrs.nicescrollOffset) || {});
      var options = angular.extend(
        {
          cursorwidth: 10,
          cursoropacitymax: 0.7,
          dblclickzoom: false,
          bouncescroll: true,
          sensitiverail: true,
          enablescrollonselection: true,
          grabcursorenabled: true,
          enablekeyboard: true,
          railpadding: offset,
        },
        scope.$eval(attrs.nicescroll) || {}
      );

      $(element).niceScroll(options);

      $($window).resize(function () {
        $(element).getNiceScroll().resize();
      });
    },
  };
});
