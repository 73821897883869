/**
 * Service to take over the console logging and keep a running history
 */

angular
  .module("common")
  .factory("ErrorLogger", function ($window, DEV, $state) {
    var Logger = function Logger() {
      // Set the three different log levels
      this.DEBUG_LEVEL = "debug";
      this.LOG_LEVEL = "log";
      this.WARN_LEVEL = "warn";
      this.ERROR_LEVEL = "error";

      // Initizalize the history object
      this._history = {};
      this._history[this.DEBUG_LEVEL] = [];
      this._history[this.LOG_LEVEL] = [];
      this._history[this.WARN_LEVEL] = [];
      this._history[this.ERROR_LEVEL] = [];
    };

    Logger.prototype.start = function () {
      var self = this;

      $window.onerror = function (errorMsg, file, lineNumber) {
        self.logError(self.ERROR_LEVEL, errorMsg, lineNumber, file);
      };

      // If this is running in a browser without console *cough* IE,
      // make sure that console is defined
      if (!$window.console) $window.console = {};

      /**
       * Replaces a console method
       * @param  {string} method the method to be replaced
       * @return {Function} the new console function
       */
      function replaceMethod(method) {
        // Keep a reference to the original
        var original = $window.console[method];

        // Create a new function
        var replacement = function () {
          self.logError(method, arguments);

          if (original.apply) {
            // Do this for normal browsers
            if (DEV) original.apply(console, arguments);
          } else {
            // Do this for IE
            var message = Array.prototype.slice.apply(arguments).join(" ");
            if (DEV) original(message);
          }
        };

        // Return the replacement
        return replacement;
      }

      // These are the methods being replaced
      var methods = ["debug", "log", "warn", "error"];

      // Loop through each method and replace it
      for (var i = 0; i < methods.length; i++) $window.console[methods[i]] = replaceMethod(methods[i]);
    };

    Logger.prototype.logError = function (errorLevel, message, lineNumber, file) {
      var date = new Date();
      var timestamp = date.getFullYear() + "-" + date.getDate() + "-" + date.getDay() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      var error = {
        message: message,
        lineNumber: lineNumber,
        file: file,
        url: $window.location,
        state: $state.current,
        timestamp: date,
      };
      this._history[errorLevel].push(error);
    };

    Logger.prototype.getAll = function () {
      return this._history;
    };

    var newLogger = new Logger();
    $window.logger = newLogger;
    return newLogger;
  })

  // Auto-start when loaded
  .run(function (ErrorLogger) {
    ErrorLogger.start();
  });
