/**
 * Data/index.js (handles all the routing for the data pages)
 */

export default angular.module("routes:data", []).config(function ($stateProvider) {
  $stateProvider
    .state("data", {
      url: "/data/:entityID",
      templateUrl: "/data/collections.html",
      controller: "data:collections",
      parent: "init",
    })
    .state("data.create", {
      url: "/create",
      views: {
        right: {
          template: "<epi-collections-create-edit></epi-collections-create-edit>",
        },
      },
    })
    .state("data.edit", {
      url: "/:collectionID",
      views: {
        right: {
          template: "<epi-collections-create-edit></epi-collections-create-edit>",
        },
      },
    });
}).name;

require("./controllers/collections");

require("./directives/index-creator");
require("./directives/indexes-creator");
