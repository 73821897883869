/**
 * Validation directive
 */

angular.module("common").directive("epiValidate", function ($timeout, Validator) {
  return {
    restrict: "A",
    controller: function ($scope, Validator) {
      /**
       * Validate a whole form
       * @param  {string} formName Name of form
       * @return {object} The form object
       */
      $scope.validateForm = function (formName) {
        return $scope.forms[formName].validate();

        form.setValidity(true);
        for (var name in form.controls) {
          $scope._validateControl(form.controls[name]);
        }

        return form;
      };
    },
    link: function (scope, element, attrs) {
      // Legacy code to preserve backwards compatibility with forms that have epi-validate called on them:
      // @todo: cleanup and remove the epi-validate attribute and remove lines 16-25

      // If the epi-validate attribute is empty, this is called on the form
      if (!attrs.epiValidate && element.prop("tagName") == "FORM" && attrs.name) {
        var formName = toCamelCase(element.attr("name"));
        if (!("forms" in scope)) scope.forms = {};

        if (!scope.forms[formName]) scope.forms[formName] = createForm(!!attrs.epiValidateShowMessages);
        return;
      }

      // if the control doesn't have a name, throw an error
      if (!"name" in attrs || attrs.name == "" || typeof attrs.name == "undefined") throw "This " + element.prop("tagName").toLowerCase() + " does not have a name:";

      // if the control doesn't have a model, throw an error
      if (!"ngModel" in attrs || attrs.ngModel == "" || typeof attrs.ngModel == "undefined") throw "This " + element.prop("tagName").toLowerCase() + " does not have a model set:";

      // prepare objects on scope
      if (!("forms" in scope)) scope.forms = {};
      if (!("controls" in scope)) scope.controls = {};

      var control = new Control(attrs, element);
      var model = attrs.ngModel;

      // find closest parent form; if found, add this model to the named form object in scope
      var formElem = element.closest("form[name]");
      var formName = "";
      // make camelCase name
      if (formElem.length) formName = toCamelCase(formElem.attr("name"));

      // create form object if necessary
      if (formName && typeof scope.forms[formName] == "undefined") scope.forms[formName] = createForm(!!formElem.attr("epi-validate-show-messages"));

      // add control to form and watch control in form object
      if (formName) scope.forms[formName].addControl(control);
      // if no form, add to controls object so it's at least in the scope
      else scope.controls[control.name] = control;

      // watch element's value for change
      var unbind = scope.$watch(attrs.ngModel, function (newVal, oldVal) {
        control.value = newVal;

        if (newVal == oldVal) return;

        // add dirty class (will make parent form dirty too)
        control.setDirty(true);

        // run validation rules via controller (set invalid class if necessary)
        if (formName in scope.forms) scope.forms[formName]._validateControl(control);
      });

      // destructor cleanup
      scope.$on("$destroy", function () {
        unbind();
        if (formName) {
          delete scope.forms[formName].controls[control.name];
          scope.forms[formName].checkValidity();
        }
      });

      function createForm(showMessages) {
        return new Form(showMessages, $timeout, Validator, scope);
      }
    },
  };
});

function Form(showMessages, $timeout, Validator, $scope) {
  this._dirty = false;
  this._invalid = false;
  this._errors = {};
  this._showMessages = !!showMessages;
  this.$timeout = $timeout;
  this.Validator = Validator;
  this.$scope = $scope;

  this.controls = {};
}

Form.prototype = {
  /**
   * Return whether this form is valid
   * @return {boolean}
   */
  isValid: function () {
    return !this._invalid;
  },

  /**
   * Set whether this form is valid
   * @param {boolean} valid
   * @return {void}
   */
  setValidity: function (valid) {
    this._invalid = !valid;
  },

  /**
   * Reset the form
   * @return {void}
   */
  reset: function () {
    // This is wrapped in a timeout because usually this will be called right after a model change
    // If this is called right after a model change, the form will be reset,
    // *Then* the $apply will run and set everything back to invalid
    this.$timeout(
      function () {
        for (var i in this.controls) {
          this.controls[i].setDirty(false);
          this.controls[i].setValidity(true);
        }
        this._invalid = false;
      }.bind(this)
    );
  },

  /**
   * Go through all the controls and figure out if this form is valid
   * @return {void}
   */
  checkValidity: function () {
    for (name in this.controls) {
      if (!this.controls[name].isValid()) return;
    }

    // no invalid controls were found, so set to valid
    this._invalid = false;
  },

  /**
   * Return whether this form is dirty
   * @return {boolean}
   */
  isDirty: function () {
    return this._dirty;
  },

  /**
   * Set whether this form is dirty
   * @param {boolean} dirty
   * @return {void}
   */
  setDirty: function (dirty) {
    this._dirty = dirty;
  },

  /**
   * Get all errors in this form organized by name of control
   * @return {object}
   */
  getErrors: function () {
    return this._errors;
  },

  /**
   * Set errors in form by control name
   * @param {string} name
   * @param {array} errors
   * @return {void}
   */
  setErrors: function (name, errors) {
    this._errors[name] = errors;
  },

  /**
   * Remove error(s) in form by control
   * @param  {string} name
   */
  removeErrors: function (name) {
    if (name in this._errors) delete this._errors[name];
  },

  /**
   * Add a control to this form
   * @param {object} control A control object
   * @return {void}
   */
  addControl: function (control) {
    this.controls[control.name] = control;
    control.setForm(this);
  },

  /**
   * Validates the form
   * @return {Form} returns the form
   */
  validate: function () {
    this.setValidity(true);
    for (var name in this.controls) {
      this._validateControl(this.controls[name]);
    }

    return this;
  },

  /**
   * Validates a single control
   * @param  {object} control
   * @return {void}
   */
  _validateControl: function (control) {
    // get validation rules from this control
    var rules = control.rules;

    // get all the other values in case they're needed by validation
    var form = this,
      otherControls = form.controls,
      otherVals = {};

    for (var name in otherControls) {
      otherVals[name] = otherControls[name].value;
    }

    // feed validation rules and value into service
    var results = this.Validator.validate(control.value, control.rules, control.messages, otherVals, this.$scope);

    // set control to invalid if necessary (will ascend to parent form)
    if (results !== true) {
      control.setErrors(results);
      control.setValidity(false);
    } else {
      control.resetErrors();
      control.setValidity(true);
    }
  },
};

function Control(attrs, elem) {
  this._dirty = false;
  this._invalid = false;
  this._errors = {};
  this._elem = elem;
  this._form = null;
  this._showMessages = attrs.epiValidateShowMessages == "true" ? true : false;
  this._messageTemplate = '<span class="text-danger"></span>';
  this.VALID_CLASS = "valid";
  this.INVALID_CLASS = "invalid";

  this.name = attrs.name;
  this.value = null; // set by a watcher
  this.rules = attrs.epiValidate;

  if (attrs.epiValidateMessages) {
    try {
      this.messages = eval("(" + attrs.epiValidateMessages + ")");
    } catch (err) {
      throw 'Unable to evaluate custom validation messages for "' + this.name + '"!';
    }
  }
}

Control.prototype = {
  /**
   * Return whether this form is valid
   * @return {boolean}
   */
  isValid: function () {
    return !this._invalid;
  },

  /**
   * Set whether this control is valid (clear errors if valid; if invalid, make form invalid)
   * @param {boolean} valid
   * @return {void}
   */
  setValidity: function (valid) {
    this._invalid = !valid;

    // delete old error message
    this._elem.next("span.text-danger").remove();

    // if valid, clear errors, clear error class, and add valid class
    // also tell parent form to check its validity
    if (valid) {
      this._errors = [];
      this._elem.addClass(this.VALID_CLASS).removeClass(this.INVALID_CLASS);
      if (this._elem[0].nodeName == "SELECT" && this._elem.parent(".select-holder")) this._elem.parent(".select-holder").addClass(this.VALID_CLASS).removeClass(this.INVALID_CLASS);

      if (this._form) this._form.checkValidity();
    }

    if (!valid) {
      this._elem.addClass(this.INVALID_CLASS).removeClass(this.VALID_CLASS);
      if (this._elem[0].nodeName == "SELECT" && this._elem.parent(".select-holder")) this._elem.parent(".select-holder").addClass(this.INVALID_CLASS).removeClass(this.VALID_CLASS);

      // show message (assumes messages are set first)
      if (this._showMessages && this._errors.length) {
        var span = $(this._messageTemplate).html(this._errors[0]);
        this._elem.after(span);
      }

      if (this._form) this._form.setValidity(false);
    }
  },

  /**
   * Return whether this control is dirty
   * @return {boolean}
   */
  isDirty: function () {
    return this._dirty;
  },

  /**
   * Set whether this control is dirty
   * @param {boolean} dirty
   * @return {void}
   */
  setDirty: function (dirty) {
    this._dirty = dirty;
    if (this._form && dirty == true) this._form.setDirty(true);
  },

  /**
   * Get all errors in this control
   * @return {object}
   */
  getErrors: function () {
    return this._errors;
  },

  /**
   * Set all errors in this control
   * @param {array} errors
   * @return {void}
   */
  setErrors: function (errors) {
    this._errors = errors;
    if (this._form) this._form.setErrors(this.name, errors);
  },

  resetErrors: function () {
    this._errors = null;
    if (this._form) this._form.removeErrors(this.name);
  },

  /**
   * Set this control's parent form
   * @param {object} form Form object
   * @return {void}
   */
  setForm: function (form) {
    this._form = form;
    this._showMessages = form._showMessages;
  },

  /**
   * Returns the form object
   * @return {object}
   */
  getForm: function () {
    return this._form;
  },
};

function toCamelCase(str) {
  return str.replace(/([\:\-\_]+(.))/g, function (_, separator, letter, offset) {
    return offset ? letter.toUpperCase() : letter;
  });
}
