import { useState } from "react";
import { ICollection, ITheme, ThemeLegendType } from "../../types/ICollection";
import { SpectrumColorpicker } from "../../components/SpectrumColorpicker";

export const LegendEditor = ({
  legendAndOptions: { legend, showLegendHeader, customLegendHeader },
  aggregates,
  onChange,
  onChangeIndex,
}: {
  legendAndOptions: Pick<ITheme, "legend" | "showLegendHeader" | "customLegendHeader">;
  aggregates?: ICollection["aggregates"];
  onChange: (legendAndOptions: Pick<ITheme, "legend" | "showLegendHeader" | "customLegendHeader">) => void;

  /**
   * This is ONLY used for the spectrum color picker because of a weird binding issue, so the actual set method is in the parent.
   */
  onChangeIndex: (index: number, values: Partial<ITheme["legend"][0]>) => void;
}) => {
  const [legendSetting, setLegendSetting] = useState<1 | 2 | 3>(showLegendHeader ? (customLegendHeader ? 3 : 2) : 1);

  const addLegend = () => {
    onChange({
      legend: [
        ...legend,
        {
          type: ThemeLegendType["Line Color"],
          text: "",
          value: "",
          aggFormat: "",
        },
      ],
      showLegendHeader,
      customLegendHeader,
    });
  };

  const updateLegendAtIndex = (index: number, updatedLegend: Partial<ITheme["legend"][0]>) => {
    const newLegend = [...legend];
    newLegend[index] = { ...newLegend[index], ...updatedLegend };
    onChange({
      legend: newLegend,
      showLegendHeader,
      customLegendHeader,
    });
  };

  const removeLegendAtIndex = (index: number) => {
    onChange({
      legend: legend.filter((l, i) => i !== index),
      showLegendHeader,
      customLegendHeader,
    });
  };

  const isLegendColor = function (type: string) {
    return type && !!type.match(/(\-fill|\-color)$/);
  };

  return (
    <div className="legend-editor">
      <div className="legend">
        <div className="entries">
          <h4>Entries</h4>
          {legend.map((entry, i) => (
            <div className="entry" key={`legend-${i}`}>
              <div className="select-holder">
                <select onChange={({ target }) => updateLegendAtIndex(i, { type: target.value as ThemeLegendType })} value={entry.type}>
                  {Object.entries(ThemeLegendType).map((type) => (
                    <option key={type[1]} value={type[1]}>
                      {type[0]}
                    </option>
                  ))}
                </select>
              </div>
              <input type="text" value={entry.text} onChange={({ target }) => updateLegendAtIndex(i, { text: target.value })} placeholder="Legend Text" />
              {entry.type === ThemeLegendType["Line Width"] && (
                <input type="number" value={entry.value} onChange={({ target }) => updateLegendAtIndex(i, { value: target.value })} />
              )}
              {isLegendColor(entry.type) && (
                <SpectrumColorpicker
                  className="color"
                  value={entry.value}
                  format="rgb"
                  options={{ showInput: true, showInitial: true, clickoutFiresChange: true, preferredFormat: "hex", allowEmpty: true, showAlpha: true }}
                  onChange={(color) => onChangeIndex(i, { value: color })}
                />
              )}
              {entry.type === ThemeLegendType["Aggregate"] && (
                <>
                  <div className="select-holder">
                    <select value={entry.value} onChange={({ target }) => updateLegendAtIndex(i, { value: target.value })}>
                      {aggregates?.map((agg) => (
                        <option key={agg} value={agg}>
                          {agg}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input type="text" value={entry.aggFormat} onChange={({ target }) => updateLegendAtIndex(i, { aggFormat: target.value })} placeholder="Number Format" />
                </>
              )}
              <button className="btn del" onClick={() => removeLegendAtIndex(i)} />
            </div>
          ))}
          <div className="controls">
            <button className="btn add-full" onClick={addLegend}>
              Add Legend
            </button>
          </div>
        </div>
        <div className="headers">
          <h4>Headers</h4>
          <input id="show-collection-name" type="radio" name="headerSettings" checked={legendSetting === 1} value={1} onChange={() => setLegendSetting(1)} />
          <label
            htmlFor="show-collection-name"
            onClick={() => {
              onChange({ legend, showLegendHeader: false, customLegendHeader: "" });
            }}
            ng-click="theme.showLegendHeader = 0; theme.customLegendHeader = ''"
          >
            Show collection name only if other legends are present
          </label>
          <input id="always-show-collection-name" type="radio" name="headerSettings" checked={legendSetting === 2} value={2} onChange={() => setLegendSetting(2)} />
          <label
            htmlFor="always-show-collection-name"
            onClick={() => {
              onChange({ legend, showLegendHeader: true, customLegendHeader: "" });
            }}
          >
            Always show collection name
          </label>
          <input id="custom-header" type="radio" name="headerSettings" checked={legendSetting === 3} value={3} onChange={() => setLegendSetting(3)} />
          <label
            id="custom-header-label"
            htmlFor="custom-header"
            onClick={() => {
              onChange({ legend, customLegendHeader, showLegendHeader: true });
            }}
          >
            Custom header:
            <input
              type="text"
              placeholder="Custom header"
              value={customLegendHeader ?? ""}
              onChange={({ target }) => onChange({ legend, customLegendHeader: target.value, showLegendHeader })}
              disabled={legendSetting !== 3}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
