export const ImagePreview = (editor, classes) => {
  let wrapper = $(editor.getWrapperElement());

  wrapper.on("keyup", () => {
    setTimeout(createPreviewBoxes);
    setTimeout(createPreviewBoxes, 1500);
  });

  function createPreviewBoxes() {
    classes.forEach(function (classString) {
      wrapper.find(`.${classString}`).each(function () {
        let element = $(this);

        let urlBox = element.children(".image-preview");
        if (!urlBox.length) {
          urlBox = $(`<span class="image-preview box"></span>`);
          element.prepend(urlBox);
        }

        urlBox.css("background-image", `url("${element.parent().next().text()}")`);
      });
    });
  }
};
