import { Modal } from "../../components/Modal";

export const OverwriteMergeModal = ({ onCancel, onClose }: { onCancel: () => void; onClose: (merge: boolean) => void }) => {
  return (
    <Modal isOpen={true} onClose={onCancel}>
      <div className="modal-header">
        <h3>
          Records already exist in this collection. <br /> Would you like to:
        </h3>
      </div>
      <div className="modal-footer">
        <span className="btn" onClick={onCancel}>
          Cancel
        </span>
        <span className="btn" onClick={() => onClose(false)}>
          Overwrite
        </span>
        <span className="btn" onClick={() => onClose(true)}>
          Append / Merge
        </span>
      </div>
    </Modal>
  );
};
