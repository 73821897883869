import * as Yup from "yup";
import { useFormik } from "formik";

import { useGetCollectionsQuery, useSaveCollectionAsMasterMutation } from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { ICollection } from "../../types/ICollection";
import { Button } from "../../design/Button";
import { Modal } from "../../components/Modal";

export const SaveAsMasterModal = ({ onClose, collectionID }: { onClose: () => void; collectionID: ICollection["ID"] }) => {
  const [saveAsMaster] = useSaveCollectionAsMasterMutation();

  const { data: collections } = useGetCollectionsQuery();

  const { values, errors, isSubmitting, handleChange, handleSubmit } = useFormik({
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter a name for this collection")
        .test("unique", "Sorry this name is already being used", (value?: string) => {
          if (!value) return true;

          return (collections ?? [])?.every(({ name }) => name !== value);
        }),
    }),
    initialValues: { name: "" },
    onSubmit: async ({ name }, { setSubmitting }) => {
      setSubmitting(true);
      await saveAsMaster({ name, ID: collectionID }).unwrap();
      setSubmitting(false);
      onClose();
    },
  });

  return (
    <Modal isOpen={true} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h3>Save as Master</h3>
        </div>
        <div className="modal-body">
          <input
            type="text"
            placeholder="Collection name"
            name="name"
            value={values.name}
            onChange={handleChange}
            className={classNameMapper({
              invalid: Boolean(errors.name),
            })}
          />
          {errors.name && <span className="text-danger">{errors.name}</span>}
        </div>
        <div className="modal-footer">
          <span className="btn plain" onClick={onClose}>
            Cancel
          </span>
          <Button type="submit" className="btn orange" loading={isSubmitting} loadingText="Saving...">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
