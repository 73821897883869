import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Legend } from "../L.Legend";
import { IntervalSlider } from "../L.IntervalSlider";
import { getLegends, intervals } from "../../../data/map";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setMapDirty, updateMap } from "../../../slices/pagesSlice";
import HistorySlider from "../HistorySlider";
import { Moment } from "moment";
import { ICollection } from "../../../types/ICollection";

/**
 * This component controls the legend, interval slider, and history slider
 */
export const MapControls = ({ leafletMap }: { leafletMap: L.Map; }) => {
  const dispatch = useAppDispatch();

  const { map, aggregates, availableCollections } = useAppSelector((state) => state.pages.truterritory);
  const [legend, setLegend] = useState<L.Control>();
  const [intervalSlider, setIntervalSlider] = useState<L.Control>();
  const legends = useMemo(() => {
    if (!map || !availableCollections) return [];

    const collections = map?.collectionIDs
                          .map(id => availableCollections.find(c => c.ID == id))
                          .filter((c): c is ICollection => !!c)
                      || [];
    return getLegends(map, collections);
  }, [map, availableCollections]);

  useEffect(() => {
    if (legend) {
      legend.remove();
      setLegend(undefined);
    }

    if (!legends.length) return;

    const newControl = new Legend({ legends, open: true, aggregates: aggregates ?? {} } as any);

    newControl.addTo(leafletMap);

    setLegend(newControl);

    return () => {
      legend?.remove();
    };
  }, [legends, aggregates]);

  useEffect(() => {
    if (intervalSlider) {
      intervalSlider.remove();
      setIntervalSlider(undefined);
    }

    if (!map?.compEnabled) return;

    const newControl = new IntervalSlider({ intervals, interval: map.compInterval || "1 year" } as any);

    (newControl as unknown as L.Evented).on("change", (data) => {
      dispatch(updateMap({ compInterval: (data as unknown as { interval: string }).interval }));
      dispatch(setMapDirty(true));
    });

    newControl.addTo(leafletMap);

    setIntervalSlider(newControl);

    return () => {
      intervalSlider?.remove();
    };
  }, [map?.compEnabled, map?.compInterval]);

  const timeframeChanged = (range: { minDate: Moment; maxDate: Moment }) => {
    dispatch(updateMap({ dateRange: range }));
  };

  return (
    <>
      <HistorySlider leafletMap={leafletMap} onChange={timeframeChanged} />
    </>
  );
};
