import { react2angular } from "react2angular";
import { IModalProps, Modal } from "../../components/Modal";
import { IWorkspace } from "../../types/IWorkspace";
import { Formik } from "formik";
import { incrementName } from "../../utils/stringUtils";

export const WorkspaceModal = ({
  isOpen,
  onClose,
  onSave,
  workspace,
}: { workspace: IWorkspace; onSave: (workspace: IWorkspace) => Promise<void> } & Pick<IModalProps, "isOpen" | "onClose">) => {
  if (!isOpen) return <></>;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="save-new-workspace">
      <Formik
        initialValues={{ name: incrementName(workspace.name) }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const newWorkspace = { ...workspace, name: values.name };
          await onSave(newWorkspace);
          setSubmitting(false);
          onClose();
        }}
      >
        {({ values, isSubmitting, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <h4>Workspace Name:</h4>
              <input type="text" value={values.name} name="name" onChange={handleChange} />
            </div>
            <div className="modal-footer">
              <div className="controls">
                <button className="btn light-blue" type="submit" disabled={isSubmitting}>
                  Save
                </button>
                <button className="btn plain" type="button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const AngularWorkspaceModal = react2angular(WorkspaceModal);
