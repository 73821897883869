import { react2angular } from "react2angular";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../hooks/useAppSelector";
import { EditInPlace } from "../../components/EditInPlace";
import { ConfirmButton } from "../../components/ConfirmButton";
import { withReduxProvider } from "../../services/withReduxProvider";
import { ICollection, ITheme, IThemeWithID } from "../../types/ICollection";
import { useCreateThemeMutation, useDeleteThemeMutation, useUpdateThemeMutation, useValidateCSSMutation } from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { store } from "../../store";
import { closeTopPanelSections, setCurrentlyEditingTheme, updateCurrentlyEditingTheme, setMapDirty, setMapLabel } from "../../slices/pagesSlice";
import { Checkbox } from "../../design/Checkbox";
import { CodeMirrorEditor } from "../../components/CodeMirrorEditor/CodeMirrorEditor";
import { LegendEditor } from "./LegendEditor";
import { Button } from "../../design/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface ITruTerritoryThemeProps {}

export const TruTerritoryTheme: React.FC<ITruTerritoryThemeProps & JSX.IntrinsicAttributes> = () => {
  const dispatch = useAppDispatch();
  const { map, currentEditingTheme: theme, currentEditingCollection: collection } = useAppSelector((state) => state.pages.truterritory);

  const [postValidateCSS] = useValidateCSSMutation();

  const [tab, setTab] = useState<"css" | "expanded-css" | "legend">("css");

  const [saveTheme, { isLoading: isSaving }] = useUpdateThemeMutation();
  const [createTheme, { isLoading: isCreating }] = useCreateThemeMutation();
  const [deleteTheme] = useDeleteThemeMutation();

  const [editedLabel, setEditedLabel] = useState<string | undefined | null>(theme?.label);
  const [editedCustomLabel, setEditedCustomLabel] = useState<string | undefined | null>(theme?.customLabel);
  const [editedFontSize, setEditedFontSize] = useState<number | undefined>(theme?.fontSize);
  const [editedPreviewFeatures, setEditedPreviewFeatures] = useState<boolean | undefined>(theme?.previewFeatures);
  const [editedLabelFormats, setEditedLabelFormats] = useState<ITheme["labelFormats"]>(theme?.labelFormats ?? {});
  const [editedLabelZeroes, setEditedLabelZeroes] = useState<ITheme["labelZeroes"]>(theme?.labelZeroes ?? {});
  const [editedCss, setEditedCss] = useState<string | undefined>(theme?.css);
  const [parsedCss, setParsedCss] = useState<string | undefined>();
  const [cssErrors, setCssErrors] = useState<Record<number, string[]>>({});
  const [editedLegendAndOptions, setEditedLegend] = useState<Pick<ITheme, "legend" | "showLegendHeader" | "customLegendHeader">>({
    legend: theme?.legend ?? [],
    showLegendHeader: theme?.showLegendHeader ?? false,
    customLegendHeader: theme?.customLegendHeader ?? "",
  });

  const [currentProperty, setCurrentProperty] = useState<string>("");

  useEffect(() => setEditedLabel(theme?.label), [theme?.label]);
  useEffect(() => setEditedCustomLabel(theme?.customLabel), [theme?.customLabel]);
  useEffect(() => setEditedFontSize(theme?.fontSize), [theme?.fontSize]);
  useEffect(() => setEditedPreviewFeatures(theme?.previewFeatures), [theme?.previewFeatures]);
  useEffect(() => setEditedLabelFormats(theme?.labelFormats ?? {}), [theme?.labelFormats]);
  useEffect(() => setEditedLabelZeroes(theme?.labelZeroes ?? {}), [theme?.labelZeroes]);
  useEffect(() => setEditedCss(theme?.css), [theme?.css]);
  useEffect(
    () =>
      setEditedLegend({
        legend: theme?.legend ?? [],
        showLegendHeader: theme?.showLegendHeader ?? false,
        customLegendHeader: theme?.customLegendHeader ?? "",
      }),
    [theme?.legend]
  );

  if (!theme) return <></>;

  const handleNameSave = (name: string) => {
    onSave({ ...theme, name });

    if (theme.ID) {
      saveTheme({ ...theme, name });
    } else {
      createTheme({ ...theme, name })
        .unwrap()
        .then(({ ID }) => store.dispatch(updateCurrentlyEditingTheme({ ID })));
    }
  };

  const handleSave = () => {
    const newTheme: ITheme = {
      ...theme,
      label: editedLabel ?? theme.label,
      customLabel: editedCustomLabel ?? theme.customLabel,
      fontSize: editedFontSize ?? theme.fontSize,
      previewFeatures: editedPreviewFeatures ?? theme.previewFeatures,
      labelFormats: editedLabelFormats ?? theme.labelFormats,
      labelZeroes: editedLabelZeroes ?? theme.labelZeroes,
      css: editedCss ?? theme.css,
      legend: editedLegendAndOptions?.legend ?? theme.legend,
      showLegendHeader: editedLegendAndOptions?.showLegendHeader ?? theme.showLegendHeader,
      customLegendHeader: editedLegendAndOptions?.customLegendHeader ?? theme.customLegendHeader,
    };

    if (theme.ID) {
      onSave(newTheme);
      saveTheme(newTheme);
    } else {
      createTheme(newTheme)
        .unwrap()
        .then(({ ID }) => {
          store.dispatch(updateCurrentlyEditingTheme({ ID }));
          const newThemeWithID: IThemeWithID = { ...newTheme, ID };
          onSave(newThemeWithID);
        });
    }
  };

  const onSave = (newTheme: ITheme) => {
    dispatch(setCurrentlyEditingTheme(newTheme));
    dispatch(setMapDirty(true));
    if (editedLabel) {
      setMapLabel({ collectionID: newTheme.collectionID, label: newTheme.label });
    }
  };

  const onDelete = async (theme: ITheme) => {
    // send the delete request
    await deleteTheme(theme).unwrap();

    // close and apply changes
    handleClose();
    dispatch(setMapDirty(true));
  };

  const handleClose = () => {
    store.dispatch(closeTopPanelSections());
  };

  const propertyLabelShouldBeDisabled = function (prop: ICollection["properties"][0], theme: ITheme) {
    // A prop label should be disabled if the property can't be labeled and it doesn't currently have a format attached
    let hasFormat = !!theme.labelFormats[prop.name] || theme.labelFormats[prop.name] === "0";
    let formattableType = getPgTypeToJs(prop.type) === "number" || getPgTypeToJs(prop.type) === "unknown";

    return !hasFormat && !formattableType;
  };

  const validateCSS = function (css: string) {
    return postValidateCSS({ css, fontSize: theme.fontSize, label: theme.label })
      .unwrap()
      .then((data) => {
        if (data.errors && Object.keys(data.errors).length) {
          setCssErrors(data.errors ?? {});
          setParsedCss("Error(s) in CSS");
        } else {
          setParsedCss(data.parsed);
          setCssErrors({});
        }
      });
  };

  let previousTimeout: NodeJS.Timeout;
  const validateCSSOnKeystroke = function (css: string) {
    clearTimeout(previousTimeout);
    previousTimeout = setTimeout(() => {
      validateCSS(css);
    }, 700);
  };

  // const validateCSS = function (css) {
  //   return Theme.validateCSS(css, $scope.theme.label, $scope.theme.fontSize).then(function (data) {
  //     $scope.cssErrors = data.errors || {};
  //     $scope.areErrors = data.errors ? !!Object.keys($scope.cssErrors).length : false;
  //     $scope.parsedCSS = $scope.areErrors ? "Error(s) in CSS" : data.parsed;
  //   });
  // };

  // let previousTimeout;
  // $scope.validateCSSOnKeystroke = function (css) {
  //   $timeout.cancel(previousTimeout);
  //   previousTimeout = $timeout(function () {
  //     $scope.validateCSS(css);
  //   }, 700);
  // };

  return (
    <div id="truterritory-theme-editor">
      <div className="left-side">
        <EditInPlace value={theme.name} onSave={handleNameSave}>
          <h4 className="theme-name">{theme.name}</h4>
          {theme.ID && (
            <ConfirmButton
              className="delete-theme"
              type="del"
              text="Are you sure you want to delete this theme?"
              callback={() => onDelete(theme)}
              yes="Delete"
              title={`Delete '${theme.name}'`}
            />
          )}
        </EditInPlace>
        <div className="attribute">
          <label>Label</label>
          <div className="select-holder">
            <select onChange={({ target }) => setEditedLabel(target.value)} value={editedLabel ?? ""}>
              <option value="">(no label)</option>
              {collection?.properties.map((prop) => (
                <option key={prop.column} value={prop.column}>
                  {prop.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="attribute">
          <label>Custom Label</label>
          <input type="text" value={editedCustomLabel ?? ""} onChange={({ target }) => setEditedCustomLabel(target.value)} />
        </div>
        <div className="attribute">
          <label>Font Size</label>
          <input type="number" value={editedFontSize ?? ""} onChange={({ target }) => setEditedFontSize(parseInt(target.value, 10))} />
        </div>
        <div className="attribute">
          <Checkbox
            name="enablePreviewFeatures"
            id="enablePreviewFeatures"
            label="Preview Features"
            title="Enable Preview Features?"
            checked={editedPreviewFeatures}
            onChange={({ target }) => {
              setEditedPreviewFeatures(target.checked);
            }}
          />
        </div>
        <div className="format-labels">
          <label>Format Labels:</label>
          <div className="label-format-header">
            <h5 className="col-md-6">Label</h5>
            <h5 className="col-md-3">Format</h5>
            <h5 className="col-md-3 strip-zeroes">Strip Zeroes</h5>
          </div>
          <div className="label-format">
            <div className="select-holder attribute">
              <select
                value={currentProperty ?? ""}
                onChange={({ target }) => {
                  setCurrentProperty(target.value);
                  setEditedLabelFormats({ ...editedLabelFormats, [target.value]: editedLabelFormats[target.value] ?? "" });
                  setEditedLabelZeroes({ ...editedLabelZeroes, [target.value]: editedLabelZeroes[target.value] ?? false });
                }}
              >
                <option value="" disabled />
                {collection?.properties.map((prop) => (
                  <option key={prop.column} value={prop.column} disabled={propertyLabelShouldBeDisabled(prop, theme)}>
                    {prop.name}
                  </option>
                ))}
              </select>
            </div>
            <input
              className="number"
              ng-model="theme.labelFormats[editingLabelFormat]"
              type="text"
              value={currentProperty ? editedLabelFormats[currentProperty] : ""}
              onChange={({ target }) => {
                if (!currentProperty) return;

                setEditedLabelFormats({ ...editedLabelFormats, [currentProperty]: target.value });
              }}
            />
            <Checkbox
              id="labelZeroes"
              name="labelZeroes"
              checked={currentProperty ? editedLabelZeroes[currentProperty] : false}
              title="Strip zeroes from formatted labels"
              onChange={({ target }) => {
                if (!currentProperty) return;

                setEditedLabelZeroes({ ...editedLabelZeroes, [currentProperty]: !!target.checked });
              }}
            />
          </div>
        </div>
        <div className="controls">
          <button className="btn plain" onClick={handleClose}>
            Cancel
          </button>
          <Button onClick={() => handleSave()} loading={isCreating || isSaving} disabled={Object.keys(cssErrors).length > 0}>
            Save
          </Button>
          <Button
            loading={isCreating || isSaving}
            disabled={Object.keys(cssErrors).length > 0}
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Save &amp; Close
          </Button>
        </div>
      </div>
      <div className="right-side">
        <div className="tabs">
          <div className={classNameMapper({ active: tab === "css" }, "tab")} onClick={() => setTab("css")}>
            CSS
          </div>
          <div
            className={classNameMapper({ active: tab === "expanded-css" }, "tab")}
            onClick={() => {
              validateCSS(editedCss ?? "");
              setTab("expanded-css");
            }}
          >
            Expanded CSS
          </div>
          <div className={classNameMapper({ active: tab === "legend" }, "tab")} onClick={() => setTab("legend")}>
            Legend Editor
          </div>
        </div>
        <div className="tab-content">
          {tab === "css" && (
            <div className="css-editor">
              <CodeMirrorEditor
                mode="cartocss"
                value={editedCss}
                onChange={(value) => {
                  setEditedCss(value);

                  if (value) validateCSSOnKeystroke(value);
                }}
                errors={cssErrors}
                plugins={["color-picker", "image-preview", "image-uploader"]}
              />
            </div>
          )}
          {tab === "expanded-css" && parsedCss && (
            <div className="css-editor">
              <CodeMirrorEditor mode="cartocss" value={parsedCss} id="expanded-css" disabled errors={cssErrors} />
            </div>
          )}
          {tab === "legend" && (
            <LegendEditor
              legendAndOptions={editedLegendAndOptions}
              aggregates={collection?.aggregates}
              onChange={(legendAndOptions) => setEditedLegend(legendAndOptions)}
              onChangeIndex={(index, partialUpdate) => {
                setEditedLegend((prev) => ({
                  ...prev,
                  legend: prev.legend.map((l, i) => (i === index ? { ...l, ...partialUpdate } : l)),
                }));
              }}
            />
          )}
          {(tab === "css" || tab === "expanded-css") && <Help />}
        </div>
      </div>
    </div>
  );
};

export const AngularTruTerritoryTheme = react2angular(withReduxProvider(TruTerritoryTheme), []);

const Help = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="help-holder">
      <div className="help-button" onClick={() => setIsOpen((v) => !v)} />
      {isOpen && (
        <div className="help">
          <h4>Macros:</h4>
          <div className="block">
            <pre>
              <b>setMarker(filename.ext)</b>
            </pre>
            <span>— Set a custom marker image</span>
          </div>
          <div className="block">
            <pre>
              <b>setLabel(label, size, color=null, dx=0, dy=0)</b>
            </pre>
            <span>— Set an automatically scaling label</span>
          </div>
          <div className="block">
            <pre>
              <b>smartScale(propertyName, valueToBeScaled, minimumResultValue=null, maximumResultValue=null, useDefaults=false)</b>
            </pre>
            <span>
              — Scales the value of an arbitrary property based on zoom level, such as text-size, marker-size, opacity, etc. Optionally set a minimum and maximum value (such as
              minimum or maximum text-size). If useDefaults is true, then the min/max values are used when out of bounds, rather than hiding the property.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const getPgTypeToJs = function (pgType: string) {
  if (pgType === "varchar" || pgType === "text") {
    return "string";
  } else if (pgType.startsWith("int") || pgType === "numeric" || pgType.startsWith("float") || pgType.endsWith("int")) {
    return "number";
  } else if (pgType === "boolean" || pgType === "bool") {
    return "boolean";
  } else if (pgType.startsWith("timestamp")) {
    return "date";
  } else if (pgType.startsWith("json")) {
    return "json";
  } else if (pgType.endsWith("[]")) {
    return "array";
  } else {
    return "unknown";
  }
};
