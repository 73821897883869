angular.module("common").directive("epiModal", function ($uibModal, $log, $parse) {
  return {
    restrict: "C",
    transclude: true,
    templateUrl: "/directives/epi-modal.html",
    link: function (scope, elements, attrs) {
      if (typeof attrs.template == "undefined") attrs.template = "/directives/epi-modal.html";

      if (typeof attrs.keyboard == "undefined") attrs.keyboard = false;

      if (typeof attrs.background == "undefined") {
        attrs.background = "static";
      } else {
        // Otherwise evaluate the string as a boolean
        attrs.background = attrs.background === "true";
      }

      scope.epiModalOpen = function () {
        scope.epiModalInstance = $uibModal.open({
          templateUrl: attrs.template,
          backdrop: attrs.background,
          keyboard: attrs.keyboard,
          windowClass: attrs.classes,
          scope: scope,
        });

        scope.$on("enter", scope.epiModalConfirm);
      };

      scope.epiModalClose = function () {
        scope.epiModalInstance.dismiss("closed");
        scope.epiModalInstance.closed = true;
      };

      scope.epiModalConfirm = function () {
        scope.epiModalClose();
        var fn = $parse(attrs.callbackConfirm);
        fn(scope);
      };

      scope.epiModalCancel = function () {
        scope.epiModalClose();
        var fn = $parse(attrs.callbackCancel);
        fn(scope);
      };

      scope.$watch(attrs.isOpen, function (val) {
        if (val) {
          scope.epiModalOpen();
        } else if (val === false && "epiModalInstance" in scope) {
          scope.epiModalClose();
        }
      });

      scope.$on("$destroy", function () {
        if ("epiModalInstance" in scope && !scope.epiModalInstance.closed) scope.epiModalClose();
      });
    },
  };
});
