/**
 * Mark a URL as a trusted resource url. Used mainly for iFrames.
 *
 * <iframe ng-src="{{object.src | trusted}}" />
 */

angular.module("common").filter("trusted", [
  "$sce",
  function ($sce) {
    return function (url) {
      return $sce.trustAsResourceUrl(url);
    };
  },
]);
