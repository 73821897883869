export const Gravatar = function (hash: string, size: number, defaultType = "identicon") {
  let url = "https://www.gravatar.com/avatar/" + hash,
    params: { [key: string]: string | number } = {};

  if (arguments.length > 1) {
    url += "?";

    if (size) params["s"] = size;

    params["d"] = defaultType;

    url += $.param(params);
  }

  return url;
};
