/**
 * @name CondenseTo
 * @description Service that takes an array of objects and condenses it down to an array of properties
 * @ngdoc service
 * @memberOf modules
 *
 * @example
 * var toBeCondensed = [ {id: 1, name: "a"}, {id: 2, name: "b"}, {id: 3, name: "c"}, {id: 4, name: "d"}]
 * new CondenseTo(toBeCondensed, 'id').getClean() // [1,2,3,4]
 *
 */

angular.module("modules").factory("CondenseTo", function () {
  /**
   * @memberOf modules.CondenseTo
   * @param {Array} array the array of objects that needs to be condensed
   * @param {String} property the property to condense
   * @return {void}
   */
  var Condenser = function (array, property) {
    /**
     * The array of IDs
     * @type {Array}
     */
    this.array = [];

    // If the array isn't an Array, throw an error
    if (!(array instanceof Array)) throw new ReferenceError("CondenseTo: argument is not an array");

    // If the array doesn't have length, throw an error
    if (!array.length) return this;

    // Loop through the array and create the array of IDs
    for (var i = 0; i < array.length; i++) {
      this.array[i] = array[i][property];
    }
  };

  /**
   * Returns the array
   * @memberOf modules.CondenseTo
   * @return {Array} an array of objects
   */
  Condenser.prototype.getClean = function () {
    return this.array;
  };

  return Condenser;
});
