/**
 * @name redactor
 * @memberOf common
 * @ngdoc directive
 * @scope true
 * @restrict A
 * @description
 *   A directive for the Redactor WYSIWYG editor
 *
 * @attribute {Expression} change The callback
 * @example
 *   <textarea redactor change="onChange()"></textarea>
 */

angular.module("common").directive("redactor", function ($parse) {
  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      change: "&",
    },
    link: function ($scope, element, attrs, ngModel) {
      /**
       * The configuration object for Redactor
       * @type {Object}
       */
      var redactorConfig = {
        buttons: [
          "html",
          "formatting",
          "bold",
          "italic",
          "deleted",
          "underline",
          "unorderedlist",
          "orderedlist",
          "outdent",
          "indent",
          "image",
          "link",
          "alignment",
          "horizontalrule",
          "fontcolor",
          "fontsize",
          "backcolor",
          "fullscreen",
        ],
        plugins: ["fontcolor", "fontsize", "fullscreen"],

        replaceDivs: false,

        /**
         * Called before Redactor launches
         * @return {void}
         */
        startCallback: function () {
          ngModel.$setPristine();
        },

        /**
         * Called each time text changes in visual mode
         *   (the same as 'codeKeydownCallback', but for visual mode)
         * @return {void}
         */
        changeCallback: function () {
          textDidChange(this.code.get());
        },

        /**
         * Called each time text changes in HTML mode
         *   (the same as 'changeCallback', but for HTML mode)
         * @return {void}
         */
        codeKeydownCallback: function () {
          textDidChange(this.code.get());
        },
      };

      // Get the user configuration object
      var userConfig = angular.copy($parse(attrs.redactor)($scope.$parent));
      userConfig = userConfig || {};

      userConfig.addButtons = userConfig.addButtons ? userConfig.addButtons : [];
      userConfig.removeButtons = userConfig.removeButtons ? userConfig.removeButtons : [];
      userConfig.addPlugins = userConfig.addPlugins ? userConfig.addPlugins : [];
      userConfig.removePlugins = userConfig.removePlugins ? userConfig.removePlugins : [];

      redactorConfig.buttons = _.difference(redactorConfig.buttons, userConfig.removeButtons);
      redactorConfig.buttons = _.union(redactorConfig.buttons, userConfig.addButtons);
      redactorConfig.plugins = _.difference(redactorConfig.plugins, userConfig.removePlugins);
      redactorConfig.plugins = _.union(redactorConfig.plugins, userConfig.addPlugins);

      delete userConfig.addPlugins;
      delete userConfig.removePlugins;
      delete userConfig.addButtons;
      delete userConfig.removeButtons;

      redactorConfig = _.extend(redactorConfig, userConfig);

      // The model isn't available right away (it's initialized as 'NaN')
      // So watch it until it has a value, then run initialize()
      var unbindWatcher = $scope.$watch(function () {
        return ngModel.$viewValue;
      }, initialize);

      /**
       * Initializes the directive
       * @param  {string} value the HTML from ngModel
       * @return {void}
       */
      function initialize(value) {
        // Unbind the watcher
        unbindWatcher();

        // Initialize Redactor
        element.redactor(redactorConfig);

        ngModel.$render = ngModelRender;
      }

      function ngModelRender() {
        element.redactor("code.set", ngModel.$viewValue);
      }

      /**
       * Runs whenever any text changes (either visual or HTML mode)
       * @param  {string} text the HTML string
       * @return {void}
       */
      function textDidChange(text) {
        $scope.change({ $text: text });

        // Run this in an $apply
        $scope.$apply(function () {
          // And update the view value
          ngModel.$setViewValue(text);
        });
      }
    },
  };
});
