/**
 * Number format filter
 * Formats a number like '123456' and returns the correct format for the user's locale
 * I.e., for en_US it would return '123,456'
 */

angular.module("common").filter("numeral", function () {
  return function (string, format) {
    return numeral(string).format();
  };
});
