export const TopNavbarSecondary: React.FC<{ title: string; noSecondaryMenu?: boolean }> = ({ children, title, noSecondaryMenu }) => {
  return (
    <nav id="top-nav-secondary" className={`navbar navbar-default ${noSecondaryMenu ? "no-secondary-menu" : ""}`}>
      <h3>{title}</h3>
      {children}
    </nav>
  );
};

export const NavbarUl: React.FC<{ right?: boolean; id?: string }> = ({ right, children, id }) => {
  return (
    <ul className={`navbar-nav ${right ? "navbar-right" : ""}`} id={id}>
      {children}
    </ul>
  );
};

export const NavbarLi: React.FC<{ pullLeft?: boolean; className?: string }> = ({ pullLeft, children, className }) => {
  return <li className={`${pullLeft ? "pull-left" : ""} ${className ?? ""}`}>{children}</li>;
};
