/**
 * Converts a string to ISO date
 */

angular.module("common").filter("dateToISO", function () {
  return function (dateString) {
    var newDate = new Date(dateString).toISOString();
    return newDate;
  };
});
