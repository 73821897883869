/**
 * @name cmImagePreview
 * @description Provides an image preview for urls in a Code Mirror instance
 * @ngdoc directive
 * @requires common.codeMirror
 *
 * @example
 *
 * <code-mirror options="options" ng-model="model" cm-image-preview="cm-class"></code-mirror>
 */

angular.module("common").directive("cmImagePreview", function () {
  return {
    restrict: "A",
    require: "^codeMirror",
    link: function ($scope, $element, $attrs) {
      let editor = $element.children(".CodeMirror")[0].CodeMirror;
      let wrapper = $(editor.getWrapperElement());

      let classes = $attrs.cmImagePreview.split(" ");

      wrapper.on("keyup", createPreviewBoxes);
      $scope.$on("codeMirrorReady", createPreviewBoxes);

      function createPreviewBoxes() {
        classes.forEach(function (classString) {
          wrapper.find(`.${classString}`).each(function () {
            let element = $(this);

            let urlBox = element.children(".image-preview");
            if (!urlBox.length) {
              urlBox = $(`<span class="image-preview box"></span>`);
              element.prepend(urlBox);
            }

            urlBox.css("background-image", `url("${element.next().text()}")`);
          });
        });
      }
    },
  };
});
