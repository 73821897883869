export const Legend = L.Control.extend({
  includes: L.Evented.prototype,
  options: {
    legends: [],
    open: false,
    aggregates: {},
    position: document.documentElement.clientWidth < 500 ? "bottomleft" : "topright",
  },
  initialize: function (options) {
    L.setOptions(this, options);
  },
  onAdd: function (map) {
    var div = L.DomUtil.create("div", "leaflet-control-legend");

    // Create the legend toggle
    let toggle = (this._toggle = $('<div class="toggle"></div>'));
    toggle.text("Hide -");

    // On click, open or close
    toggle.click(() => {
      this.toggle();
    });

    $(div).prepend(toggle);

    // Loop over the legends and create a new legend for each
    this.options.legends.forEach(this.createLegend.bind(this, div));

    L.DomEvent.disableClickPropagation(div);

    // Temporarily put the legend on the map so that we can toggle it on or off for the
    // first time using the correct width and height.
    let corner = map._controlCorners[this.getPosition()];
    corner.appendChild(div);
    this.toggle(false, this.options.open, div);
    corner.removeChild(div);

    return div;
  },

  /**
   * Toggles the legend open or closed
   * @param  {Boolean} [animate=true] whether to animate the opening/closing
   * @param  {Boolean} [shouldOpen]
   * @param {Node} container A container div to use instead of this._container (good for on creation)
   * @return {void}
   */
  toggle: function (animate = true, shouldOpen, container) {
    container = $(container || this._container);

    if (shouldOpen != null) container[shouldOpen ? "removeClass" : "addClass"]("closed");
    else container.toggleClass("closed");

    let method = animate ? "animate" : "css";

    if (!container.hasClass("closed")) {
      this._toggle.text("Hide -");
      let initialWidth = container.outerWidth();
      let initialHeight = container.height();

      container.css({ width: "", height: "" });
      let width = container.width() + 30;
      let height = container.height() + 35;

      container.css({ width: initialWidth, height: initialHeight });
      container[method]({ width, height, margin: 10 });
      this.options.open = true;
    } else {
      this._toggle.text("Show Legend +");
      container[method]({ width: this._toggle.width() + 15, height: this._toggle.height() + 15, margin: 0 });
      this.options.open = false;
    }
  },

  /**
   * Create and append a legend
   * @param  {element} div
   * @param  {object} legend
   * @return {void}
   */
  createLegend: function (div, legend) {
    let legendElement = $('<div class="legend">' + (legend.name ? `<h5>${legend.name}</h5>` : "") + '<div class="entries"></div>');
    let aggregates = legend.collectionID in this.options.aggregates ? this.options.aggregates[legend.collectionID] : {};

    legend.entries.forEach(this.createLegendEntry.bind(this, legendElement.children(".entries"), aggregates));
    $(div).append(legendElement);
  },

  /**
   * Create and append a legend entry
   * @param  {element} div
   * @param  {object} legend
   * @return {void}
   */
  createLegendEntry: function (div, aggregates, legend) {
    let entry = $(`<div class="entry"><span class="name">${legend.text}</span></div>`);

    if (legend.type == "aggregate") {
      let value = legend.value in aggregates ? aggregates[legend.value] : null;
      if (legend.aggFormat) value = numeral(value).format(legend.aggFormat);

      entry.addClass("aggregate").append($(`<span class="value">${value}</span>`));
    } else {
      let symbol = $(`<div class="symbol ${legend.type}"></div>`);

      if (parseInt(legend.value)) symbol.append($('<div class="line">').css("width", legend.value));
      else if (legend.type == "marker-fill") symbol.css("color", legend.value);
      else symbol.css("background-color", legend.value);

      entry.prepend(symbol);
    }

    $(div).append(entry);
  },
});
