/**
 * @description Simple month - day picker
 * @name monthDaySelect
 * @scope true
 * @ngdoc directive
 */

angular.module("common").directive("monthDaySelect", function () {
  return {
    restrict: "E",
    scope: {
      model: "=ngModel",
    },
    require: "ngModel",
    templateUrl: "/directives/month-day-select.html",
    link: function ($scope, $element, $attrs, $model) {
      $scope.getMonthDays = function (month) {
        if (month) {
          return _.range(
            1,
            moment({
              month: month - 1,
              day: 1,
            })
              .endOf("month")
              .date()
          );
        }
        return [];
      };

      $scope.months = moment.months();
      $scope.days = $scope.getMonthDays($scope.model.fyMonth);
    },
  };
});
