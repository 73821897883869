/**
 * Date helper for manipulating and working with date objects
 */

angular.module("common").factory("DateHelper", function () {
  var DateHelper = function (date) {
    if (Object.prototype.toString.call(date) !== "[object Date]") throw new TypeError("date is not a valid Date object");

    this._date = date;
  };

  /**
   * "Static" method to parse a yyyy-mm-dd string into a Date object
   * @param  {string} string 'yyyy-mm-dd' date
   * @return {Date}
   */
  DateHelper.yyyymmddToDate = function (string) {
    var date = string.split("-");
    return new Date(date[0] * 1, date[1] - 1, date[2] * 1);
  };

  /**
   * Returns a Date from X days ago (with an optional date specified)
   * @param  {integer} days the number of days to offset
   * @param  {Date} [date=Date] an optional Date object
   * @return {Date}
   */
  DateHelper.daysAgo = function (days, date) {
    if (!date) date = new Date();

    // Get the number of ms offset
    var dayOffset = 24 * 60 * 60 * 1000 * days;

    return new Date(date.setTime(date.getTime() - dayOffset));
  };

  /**
   * Formats a Date object as yyyy-mm-dd
   * @return {string}
   */
  DateHelper.prototype.toString = function () {
    var year = this._date.getFullYear(),
      month = this._date.getMonth() + 1,
      date = this._date.getDate();

    return year + "-" + (month > 9 ? month : "0" + month) + "-" + (date > 9 ? date : "0" + date);
  };

  return DateHelper;
});
