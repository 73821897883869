import { MouseEvent, MouseEventHandler, RefObject, useEffect } from "react";

export function useOutsideClick(ref: RefObject<HTMLElement>, cb: () => void) {
  useEffect(() => {
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        cb();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
