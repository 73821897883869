
angular.module("modules:mapping").factory("ContextMenu", function ($rootScope, $compile, $timeout) {
  return {
    showFeatureProperties
  };

  /**
   * Make a popup showing a feature's properties
   * @param {function} findCollection
   * @param {bool} isPublicPage
   * @param  {Object} feature
   * @param  {L.Map} map
   * @param  {L.LatLng} latlng
   * @return {void}
   */
  function showFeatureProperties(findCollection, isPublicPage, feature, map, latlng) {
    var popupScope = $rootScope.$new();
    popupScope.feature = feature;
    popupScope.map = map;
    popupScope.findCollection = findCollection;
    popupScope.editable = !isPublicPage;

    var el = $compile('<span map-point-detail feature="feature" map="map" find-collection="findCollection" editable="editable"></span>')(popupScope);
    el.css({ "max-height": map.getContainer().clientHeight * 0.8 - 52 });

    var popup = L.popup({ minWidth: "250", closeButton: false });
    $timeout(function () {
      popup.setLatLng(latlng).setContent(el.get(0)).openOn(map);
    });

    // Close the popup on opening another context menu.
    map.on("contextmenu", close);
    function close() {
      map.closePopup(popup);
      map.off("contextmenu", close);
    }
  }
});
