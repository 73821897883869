/**
 * @name Interval
 * @ngdoc service
 * @description A service for interacting with and creating intervals
 * @memberOf modules
 * @example
 * Interval.getNouns('day')
 * Interval.getNouns(['day'])
 */

angular.module("modules").factory("Interval", function () {
  var Interval = function () {};

  var nouns = {
    1: "day",
    2: "week",
    3: "month",
    4: "quarter",
    5: "year",
  };

  var adverbs = {
    0: "One-Time",
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Quarterly",
    5: "Annually",
  };

  /**
   * Gets a list of interval nouns (day, week, month, quarter, etc...)
   * @memberOf modules.Interval
   * @param  {(array|string)} exclude a list of nouns to exclude
   * @return {array} the array of requested nouns
   */
  Interval.getNouns = function (exclude) {
    return excludeFromList(nouns, exclude);
  };

  /**
   * Gets a list of interval adverbs (daily, weekly, monthly, quarterly, etc...)
   * @memberOf modules.Interval
   * @param  {(array|string)} exclude a list of adverbs to exclude
   * @return {array} the array of requested adverbs
   */
  Interval.getAdverbs = function (exclude) {
    return excludeFromList(adverbs, exclude);
  };

  /**
   * Gets the value of a noun
   * @memberOf modules.Interval
   * @param  {string} noun
   * @return {Integer} the value of the noun
   */
  Interval.getNoun = function (noun) {
    return findByValue(nouns, noun);
  };

  /**
   * Gets the value of a adverb
   * @memberOf modules.Interval
   * @param  {string} adverb
   * @return {Integer} the value of the adverb
   */
  Interval.getAdverb = function (adverb) {
    return findByValue(adverbs, adverb);
  };

  function findByValue(list, search) {
    return _.invert(list)[search] * 1 || null;
  }

  function excludeFromList(list, exclude) {
    var inverted = _.invert(list);
    return _.invert(_.omit(inverted, exclude));
  }

  return Interval;
});
