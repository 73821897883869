const { setSession } = require("../../../src/slices/appSlice");
const { store } = require("../../../src/store");

/**
 * @name Session
 * @ngdoc service
 * @memberOf common
 * @description Session service
 */
angular.module("common").factory("Session", function ($http, $timeout, $q, API_ROOT) {
  var Session = function () {
    /**
     * The session attributes
     * @private
     * @type {Object}
     */
    this._attributes = {};
    this._onFetch = [];
  };

  /**
   * Fetch from server
   * @memberOf common.Session
   * @param {int} poll Seconds of poll time
   * @return {Promise}
   */
  Session.prototype.fetch = function (poll) {
    let url = API_ROOT + "/session";

    // add polling params
    if (poll) url += "?poll=" + poll + "&hash=" + (this._attributes.hash || null);

    return $http({ method: "GET", url: url, withCredentials: true }).then((response) => {
      this.setAll(response.data.data);
      this._runOnFetch();
      return this._attributes;
    });
  };

  /**
   * Register a change listener
   * @param {Function} callback
   */
  Session.prototype.onFetch = function (callback) {
    this._onFetch.push(callback);
  };

  /**
   * Unregister a change listener
   * @param {Function} callback
   */
  Session.prototype.offFetch = function (callback) {
    this._onFetch = _.without(this._onFetch, callback);
  };

  /**
   * Run the change listeners
   */
  Session.prototype._runOnFetch = function () {
    for (let i = 0; i < this._onFetch.length; i++) {
      this._onFetch[i].call(null, this._attributes);
    }
  };

  /**
   * Starts long polling for new session data, returning the first response right away
   * @param {int} time Number of seconds to wait
   * @param {bool} first Whether this is the first time running
   * @return {Promise}
   */
  Session.prototype.startPolling = function (time = 55, first = true) {
    this._polling = true;

    // If we already have data, return it
    let current = null;
    if (first && !_.isEmpty(this._attributes)) current = $q.when(this._attributes);

    // This might introduce a race condition if stopPolling() is called at just the right time...
    let next = this.fetch(first && !current ? 0 : time).then((attributes) => {
      // Start the next poll
      if (this._polling) $timeout(this.startPolling.bind(this, time, false));

      return attributes;
    });

    return current || next;
  };

  /**
   * Stop a previously-started polling
   * @return {void}
   */
  Session.prototype.stopPolling = function () {
    this._polling = false;
  };

  /**
   * Get all attributes in the session
   * @memberOf common.Session
   * @return {object}
   */
  Session.prototype.getAll = function () {
    return this._attributes;
  };

  /**
   * Get a single attribute
   * @memberOf common.Session
   * @param  {string} key
   * @return {mixed}
   */
  Session.prototype.get = function (key) {
    if (key in this._attributes) return this._attributes[key];

    return null;
  };

  /**
   * Set a single attribute
   * @memberOf common.Session
   * @param {string} key
   * @param {mixed} val
   * @param {boolean} doRequest If true, update on the server
   * @return {promise|void} If doRequest is true, a promise will be returned
   */
  Session.prototype.set = function (key, val, doRequest) {
    // set locally
    this._attributes[key] = val;

    // set remotely
    if (doRequest) {
      var data = {};
      data[key] = val;
      return $http({
        method: "PATCH",
        url: this.API_ROOT + "/session",
        data: data,
      });
    }
  };

  /**
   * Given a dictionary, set the session data
   * @memberOf common.Session
   * @param  {object} dict
   * @return {void}
   */
  Session.prototype.setAll = function (dict) {
    for (var key in dict) {
      this._attributes[key] = dict[key];
    }
    store.dispatch(setSession({ ...this._attributes })); //entityList: [this._attributes.entityList[0]]
  };

  return new Session();
});
