import { useMemo } from "react";
import { classNameMapper } from "../utils/classNameMapper";

export const Checkbox = (props: Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> & { label?: string }) => {
  const id = useMemo(() => props.id ?? `${Math.floor(Math.random() * 1000000) + 1}-checkbox`, [props.id]);

  return (
    <>
      <input type="checkbox" className={classNameMapper({ _checked: props.checked ?? false }) + (props.className ? ` ${props.className}` : "")} id={id} {...props} />
      <label className="checkbox" htmlFor={id}>
        {props.label}
      </label>
    </>
  );
};
