/**
 * Directive for a sliding panel
 *
 * @author Matthew Davies <daviesgeek@icloud.com>
 * @param {variable} visible requires this to show/hide the panel
 * @param {string} direction the direction that the panel slides
 */
angular.module("common").directive("epiSlidingPanel", function () {
  return {
    restrict: "C",
    transclude: true,
    scope: {
      visible: "=visible",
      direction: "@direction",
    },
    templateUrl: "/directives/epi-sliding-panel.html",
    controller: [
      "$scope",
      "$log",
      "$sce",
      function ($scope, $log, $sce) {
        if ($scope.visible === false) $(".sliding-panel").hide();

        $scope.$watch("visible", function (newVal, oldVal) {
          if (newVal == oldVal) return;
          if ($scope.visible === true) {
            $scope.class = "slide-" + $scope.direction;
          } else {
            $scope.class = "";
          }
        });
      },
    ],
  };
});
