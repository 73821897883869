import { react2angular } from "react2angular";

import { IEntity } from "../types/IEntity";
import { store } from "../store";
import { setEntityID } from "../slices/appSlice";
import { withReduxProvider } from "../services/withReduxProvider";
import { useAppSelector } from "../hooks/useAppSelector";

export const CompanySelector = () => {
  const { entityID, session: { entityList } = { entityList: [] } } = useAppSelector((state) => state.app);

  return (
    <>
      <div id="company" className={["blue", entityList && entityList.length > 1 ? "select-holder" : ""].join(" ")}>
        {entityList ? (
          entityList.length === 1 ? (
            <span className="company-name">{entityList[0].name}</span>
          ) : (
            <>
              <span className="company-name">{entityID && entityList ? entityList?.find((e: IEntity) => e.ID === entityID)?.name : "Select..."}</span>
              <select name="entityID" id="company-select" onChange={({ target }) => store.dispatch(setEntityID(target.value))} value={entityID}>
                <option value="" disabled>
                  Select...
                </option>
                {entityList?.map((entity: IEntity) => (
                  <option key={entity.ID} value={entity.ID}>
                    {entity.name}
                  </option>
                ))}
              </select>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const AngularCompanySelector = react2angular(withReduxProvider(CompanySelector));
