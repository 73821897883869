/**
 * Loading Indicator directive
 */

angular.module("common").directive("loadingIndicator", function () {
  return {
    restrict: "A",
    templateUrl: "/directives/loading-indicator.html",
    replace: true,
    scope: {
      width: "=?width",
      height: "=?height",
      speed: "=?speed",
      active: "=active",
      textStyle: "=?textStyle",
      lineWidth: "=?lineWidth",
      text: "=?text",
    },
    link: function (scope, element, attrs) {
      var defaultSettings = {
        width: 175,
        height: 75,
        speed: 7,
        textStyle: "",
        lineWidth: 4,
        text: "Loading",
      };
      for (var set in defaultSettings) {
        if (typeof scope[set] == "undefined" || scope[set] == "") scope[set] = defaultSettings[set];
      }

      var text = {
        dotsCounter: 0,
        dots: function () {
          var text = $(element).children(".text").text();
          $(element)
            .children(".text")
            .text($(element).children(".text").text() + ".");
          if (this.dotsCounter == 4) {
            $(element).children(".text").text(scope.text);
            this.dotsCounter = 0;
          }
          this.dotsCounter++;
        },
      };

      var top, right, left, bottom;
      top = $(element).children(".top");
      right = $(element).children(".right-side");
      left = $(element).children(".left");
      bottom = $(element).children(".bottom");

      var indicator = {
        // the current time
        time: 0,

        // approximately the speed of the animation in seconds
        end: scope.speed,

        reset: function () {
          this.time = 0;
          this.setPositions();
          top.css("width", 0);
          right.css("height", 0);
          bottom.css("width", 0);
          left.css("height", 0);

          top.css("height", scope.lineWidth);
          right.css("width", scope.lineWidth);
          bottom.css("height", scope.lineWidth);
          left.css("width", scope.lineWidth);
        },
        setPositions: function () {
          top.css({ position: "absolute", left: 0, top: 0, right: "auto" });
          right.css({ position: "absolute", top: 0, right: 0, bottom: "auto" });
          bottom.css({ position: "absolute", right: 0, bottom: 0, left: "auto" });
          left.css({ position: "absolute", bottom: 0, left: 0, top: "auto" });

          top.css("height", scope.lineWidth);
          right.css("width", scope.lineWidth);
          bottom.css("height", scope.lineWidth);
          left.css("width", scope.lineWidth);

          this.time = 0;
        },
        start: function () {
          var step = this.end / 32.5,
            percent;

          // the first loop
          if (this.time <= step * 1) {
            percent = (this.time / step) * 100;
            $(element)
              .children(".top")
              .css("width", percent + "%");
          } else if (this.time > step * 1 && this.time <= step * 2) {
            percent = (this.time / (step * 2)) * 200 - 100;
            top.css("width", "100%");
            right.css("height", percent + "%");
          } else if (this.time > step * 2 && this.time <= step * 3) {
            percent = (this.time / (step * 3)) * 300 - 200;
            bottom.css("width", percent + "%");
          } else if (this.time > step * 3 && this.time <= step * 4) {
            percent = (this.time / (step * 4)) * 400 - 300;
            bottom.css("width", "100%");
            left.css("height", percent + "%");
          }

          // the second loop (going backwards)
          if (this.time > step * 4 && this.time <= step * 5) {
            percent = ((this.time / (step * 5)) * 500 - 400) * -1 + 100;
            left.css("height", percent + "%");
          } else if (this.time > step * 5 && this.time <= step * 6) {
            percent = ((this.time / (step * 6)) * 600 - 500) * -1 + 100;
            left.css("height", 0);
            bottom.css("width", percent + "%");
          } else if (this.time > step * 6 && this.time <= step * 7) {
            percent = ((this.time / (step * 7)) * 700 - 600) * -1 + 100;
            bottom.css("width", 0);
            right.css("height", percent + "%");
          } else if (this.time > step * 7 && this.time <= step * 8) {
            percent = ((this.time / (step * 8)) * 800 - 700) * -1 + 100;
            right.css("height", 0);
            top.css("width", percent + "%");
          }

          // the third loop
          else if (this.time > step * 8 && this.time <= step * 9) {
            top.css({ right: 0, left: "auto" });
            right.css({ bottom: 0, top: "auto" });
            bottom.css({ left: 0, right: "auto" });
            left.css({ top: 0, bottom: "auto" });

            percent = (this.time / (step * 9)) * 900 - 800;
            top.css("width", 0);
            left.css("height", percent + "%");
          } else if (this.time > step * 9 && this.time <= step * 10) {
            percent = (this.time / (step * 10)) * 1000 - 900;
            bottom.css("width", percent + "%");
          } else if (this.time > step * 10 && this.time <= step * 11) {
            percent = (this.time / (step * 11)) * 1100 - 1000;
            right.css("height", percent + "%");
          } else if (this.time > step * 11 && this.time <= step * 12) {
            percent = (this.time / (step * 12)) * 1200 - 1100;
            top.css("width", percent + "%");
          }

          // the fourth (and final) loop
          else if (this.time > step * 12 && this.time <= step * 13) {
            percent = ((this.time / (step * 13)) * 1300 - 1200) * -1 + 100;
            top.css("width", percent + "%");
          } else if (this.time > step * 13 && this.time <= step * 14) {
            percent = ((this.time / (step * 14)) * 1400 - 1300) * -1 + 100;
            top.css("width", 0);
            right.css("height", percent + "%");
          } else if (this.time > step * 14 && this.time <= step * 15) {
            percent = ((this.time / (step * 15)) * 1500 - 1400) * -1 + 100;
            right.css("height", 0);
            bottom.css("width", percent + "%");
          } else if (this.time > step * 15 && this.time <= step * 16) {
            percent = ((this.time / (step * 16)) * 1600 - 1500) * -1 + 100;
            bottom.css("width", 0);
            left.css("height", percent + "%");
          } else if (this.time > step * 16 && this.time <= step * 17) {
            this.setPositions();
            left.css("height", 0);
          }

          this.time += 0.01;
        },
      };

      indicator.setPositions();
      setInterval(function () {
        if (scope.active == true) {
          indicator.start();
        } else {
          indicator.time = 0;
          indicator.reset();
        }
      }, 10);

      setInterval(function () {
        if (scope.active == true) {
          text.dots();
        }
      }, 250);
    },
  };
});
