export default angular.module("routes:mapping", []).config(function ($stateProvider) {

  $stateProvider.state("mapping", {
    url: "/mapping/:entityID/?collectionID",
    template: "<epi-truterritory-v2></epi-truterritory-v2>",
    parent: "init",
  });
}).name;

require("./services/mapping-modals");
require("./services/has-map-access");

require("./directives/L.History-slider");
require("./directives/map-reports");
require("./directives/sql-editor");
