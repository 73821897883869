const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

angular.module("routes:admin").controller("admin:init", function ($scope, $state) {
  store.dispatch(updateGlobalState({ activeSection: "" }));

  // Auto navigate to user & entities
  $scope.$on("$stateChangeSuccess", function (e, toState) {
    if (toState.name == "admin") $state.go("admin.users-and-entities");
  });
});
