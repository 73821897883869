angular.module("modules:mapping").factory("Feature", function (Restangular, Validator) {
  var Feature = Restangular.all("mapping/collections/features");

  // add methods to single collections
  Restangular.addElementTransformer("mapping/collections/features", false, function (feature) {
    if (!("one" in feature)) return feature;

    var validationRules = {};

    for (var key in feature.properties) {
      if (typeof feature.properties[key] == "number") validationRules[key] = "decimal";
      else validationRules[key] = "";
    }
    feature.validationRules = validationRules;

    feature.saveProperties = function (collectionID, properties, mapID, workspaceID) {
      for (var key in properties) {
        if (validationRules[key] == "decimal") properties[key] = parseFloat(properties[key]);
      }

      // Don't send the original feature; clone it, & set the properties
      var toSend = feature.clone();
      toSend.properties = properties;
      return feature.customPUT(toSend, "../../" + collectionID + "/features" + (mapID && workspaceID ? `?mapID=${mapID}&workspaceID=${workspaceID}` : ""));
    };

    /**
     * Get this feature's properties as CSV
     * @param  {array} columns Array of header,column tuples to put the columns in the right order
     * @return {string}
     */
    feature.toCSV = function (columns) {
      let headers = [],
        values = [];

      for (let i = 0; i < columns.length; i++) {
        let [header, field] = columns[i];
        if (field in this.properties) {
          headers.push(header);
          values.push(this.properties[field]);
        }
      }

      return makeCSV(headers) + "\n" + makeCSV(values);
    };

    function makeCSV(properties) {
      var csv = "",
        value;
      for (var i = 0; i < properties.length; i++) {
        value = properties[i] === null ? "" : properties[i].toString();
        value = value.replace(/"/g, '""');
        if (value.search(/("|,|\n)/g) >= 0) value = '"' + value + '"';
        if (i > 0) csv += ",";
        csv += value;
      }

      return csv;
    }

    return feature;
  });

  // add a custom methods to collections of collections
  Restangular.addElementTransformer("mapping/collections/features", true, function (features) {
    // don't try to work on uninitialized collection
    if (!("one" in features)) return features;

    return features;
  });

  Feature.restangularize = function (feature) {
    Restangular.restangularizeElement(null, feature, "mapping/collections/features");
  };

  return Feature;
});
