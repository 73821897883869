import queryString from "query-string";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getService } from "react-in-angularjs";
import { GridOptions } from "ag-grid-community";

import { ISession } from "../types/ISession";
import { IApiResponse, IApiResponseWithPagination } from "../types/IApiResponse";
import { IFaq } from "../types/IFaq";

import config from "../../app/configuration";
import { RootState, store } from "../store";
import { setError } from "./appSlice";
import { ICollection, ICollectionsMeta, ITheme, IThemeWithID } from "../types/ICollection";
import { IPagination } from "../types/IPagination";
import { IFilter, IMap, IUpdateMap, ICreateMap, IMapAnnotationsResponse, IMapWithCollections } from "../types/IMap";
import { IWorkspace, IWorkspaceUpdate } from "../types/IWorkspace";
import { IUser, IUserPreferences } from "../types/IUser";
import { IMapAction } from "../types/IMapAction";
import { IMapActionType } from "../types/IMapActionType";
import { ActionInvokeRequest, ActionInvokeResponse, ActionPreflightRequest, ActionPreflightResponse } from "../pages/TruTerritory/MapBackendActions";
import { PreflightMap } from "../data/map";
import moment from "moment";
import { removeMapTheme } from "./pagesSlice";
import { IDataSource } from "../types/IDataSource";
import { IReportTemplate } from "../types/IReportTemplate";
import { IReport } from "../types/IReport";
import { RunReportRequest, RunReportResponse } from "../pages/TruTerritory/ReportDownloader";
import { POLL_TIME } from "../../app/constants";

const IGNORED_STATUS_CODES = [422];

// this base query automatically adds the entity id as a query param to each request
//  except if passed `skipEntityId: true` in the params of the query
// it also unwraps the `data`, `meta`, and `status` properties of the response
const baseQueryWithEntityId: BaseQueryFn<string | (FetchArgs & { skipUnwrap?: boolean; skipEntityId?: boolean }), unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // pull the entityID from the app's root state, or set to undefined if skip entity id is set
  const entityID = typeof args !== "string" && args.skipEntityId ? undefined : (api.getState() as RootState).app.entityID;

  // if the entity id is missing, throw a 400 error instead of sending a bad request
  if (!entityID && !(typeof args !== "string" && args.skipEntityId)) {
    return {
      error: {
        status: 400,
        statusText: "No entity selected",
        data: { message: "No entity selected", exception: "NoEntitySelected" },
      },
    };
  }

  // construct the new args with the additional entityID param
  const adjustedArgs = typeof args === "string" ? { url: args, params: { entityID } } : { ...args, params: { entityID, ...(args.params ?? {}) } };

  // make sure credentials include is true
  const result = await fetchBaseQuery({
    baseUrl: config.API_ROOT,
    paramsSerializer: (params) => {
      return queryString.stringify(params, { arrayFormat: "bracket" });
    },
  })({ ...adjustedArgs, credentials: "include" }, api, extraOptions);

  if (typeof args !== "string" && args.skipUnwrap) return result;

  if (result.error) {
    const errorStatus = (result.error.data as IApiResponse<void>).status;
    if (!IGNORED_STATUS_CODES.includes(errorStatus.code)) api.dispatch(setError(errorStatus));
    return result;
  } else {
    // unwrap .data
    return { ...result, data: (result.data as IApiResponse<unknown>).data };
  }
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithEntityId,
  tagTypes: [
    "Session",
    "Faqs",
    "CollectionFeatures",
    "Collections",
    "CollectionAggregates",
    "Workspaces",
    "Maps",
    "MapActions",
    "MapActionTypes",
    "ReportTemplates",
    "DataSources",
    "Reports",
  ],
  endpoints: (builder) => ({
    getSession: builder.query<ISession, void>({
      query: () => "/session",
      providesTags: [{ type: "Session", id: "LIST" }],
    }),

    /**
     * Set a user's preferences (formerly "meta")
     */
    updateUserPreferences: builder.mutation<void, { ID: number; preferences: IUserPreferences }>({
      query: ({ ID, preferences }) => ({ method: "PUT", url: `users/${ID}/preferences`, body: preferences }),
      invalidatesTags: [{ type: "Session", id: "LIST" }],
    }),

    getFaqs: builder.query<IFaq[], void>({
      query: () => ({ url: "/faq", skipEntityId: true }),
      providesTags: [{ type: "Faqs", id: "LIST" }],
    }),

    getCollection: builder.query<ICollection, { ID: ICollection["ID"]; with?: "themes,themes.rules" }>({
      query: ({ ID, with: withParam }) => `mapping/collections/${ID}${withParam ? "?with=" + withParam : ""}`,
      providesTags: (_r, _e, { ID, with: withParam }) => [{ type: "Collections", id: ID + (withParam ? "-" + withParam : "") }],
    }),
    getCollectionFeatures: builder.query<IApiResponseWithPagination<Record<string, string>[]>, { id: ICollection["ID"]; filters?: IFilter[] } & IPagination>({
      query: ({ id, filters, ...params }) => ({
        url: `mapping/collections/${id}/features`,
        skipUnwrap: true,
        params: {
          ...params,
          ...(filters ? filtersToQueryStringObject(filters) : {}),
        },
      }),
      providesTags: (_r, _e, { id, ...params }) => [{ type: "CollectionFeatures", id: id + JSON.stringify(params) }],
    }),
    getCollectionColumnDefs: builder.query<GridOptions["columnDefs"], string | number>({
      query: (collectionId) => `mapping/collections/${collectionId}`,
      transformResponse: (r: { properties: { name: string; column: string; type: string }[] }) => {
        return r.properties.map(({ name, column }) => ({ headerName: name, field: column }));
      },
      providesTags: (_r, _e, id) => [{ type: "Collections", id }],
    }),
    getCollections: builder.query<ICollection[], { with?: ("themes" | "themes.rules")[] } | void>({
      query: (params) => ({
        url: `mapping/collections`,
        params: { with: params?.with ? params.with.join(",") : undefined },
      }),

      providesTags: (_r, _e) => [{ type: "Collections", id: "LIST" }],
    }),
    getCollectionsMeta: builder.query<ICollectionsMeta, void>({
      query: () => ({ url: `mapping/collections`, skipUnwrap: true }),
      transformResponse: (r: { meta: ICollectionsMeta }) => r.meta,
      providesTags: [{ type: "Collections", id: "META" }],
    }),
    deleteCollection: builder.mutation<void, ICollection["ID"]>({
      query: (id) => ({ method: "delete", url: `mapping/collections/${id}` }),
      invalidatesTags: (_r, _e, id) => [
        { type: "Collections", id: "LIST" },
        { type: "Collections", id },
      ],
    }),
    updateCollection: builder.mutation<void, Partial<ICollection> & Pick<ICollection, "ID">>({
      query: ({ ID, ...body }) => ({ method: "PATCH", url: `mapping/collections/${ID}`, body }),
      invalidatesTags: (_r, _e, { ID }) => [
        { type: "Collections", id: "LIST" },
        { type: "Collections", id: ID },
      ],
    }),
    saveCollection: builder.mutation<ICollection, Partial<ICollection> & { files?: string[] }>({
      queryFn: async (body, _api, _options, fetch) => {
        const url = body.ID ? `mapping/collections/${body.ID}` : `mapping/collections`;
        const method = body.ID ? "PATCH" : "POST";
        const { data, error } = (await fetch({ url, method, body })) as { data: { requestID: string }; error: any };

        // then poll the collection until it's ready
        if (!error && data.requestID) {
          const poll = async (requestID: string) => {
            const { data: status, error } = await fetch({
              url: `mapping/collections/status`,
              params: {
                requestID,
                poll: POLL_TIME,
              },
            });
            if (error) return { error };
            if (status === null) await poll(requestID);
            return { data: status as ICollection };
          };

          return await poll(data.requestID);
        }
        return { error };
      },
      invalidatesTags: (_r, _e, body) =>
        body.ID
          ? [
              { type: "Collections", id: "LIST" },
              { type: "Collections", id: body.ID },
            ]
          : [{ type: "Collections", id: "LIST" }],
    }),
    testCollectionSql: builder.mutation<{ explain: string[] }, string>({
      query: (sql) => ({ method: "POST", url: `mapping/collections/test`, body: { sql } }),
    }),
    saveCollectionAsMaster: builder.mutation<ICollection, Pick<ICollection, "ID" | "name">>({
      query: ({ ID, name }) => ({ method: "POST", url: `mapping/collections/${ID}`, body: { name } }),
      invalidatesTags: () => [{ type: "Collections", id: "LIST" }],
    }),

    getCollectionAggregates: builder.query<{ ID: number; ids: any[] }, { [key: string]: any }>({
      query: ({ ID, ids }) => ({ method: "POST", url: `mapping/collections/${ID}/aggregate`, body: { ids } }),
      providesTags: (_r, _e, body) => [{ type: "CollectionAggregates", id: `${body.ID}-${body.ids.join("-")}` }],
    }),

    /**
     * Get the complete list of workspaces available to a user with maps nested in them.
     */
    getWorkspacesWithMaps: builder.query<IWorkspace[], void>({
      query: () => `mapping/workspaces?createDefault=1&with=maps,maps.views,maps.reports,users`,
      providesTags: [{ type: "Workspaces", id: "LIST" }],
    }),

    getWorkspace: builder.query<IWorkspace & { collections: ICollection[] }, IWorkspace["ID"] | { ID: IWorkspace["ID"]; with: string }>({
      query: (params) => (typeof params === "number" ? `mapping/workspaces/${params}` : `mapping/workspaces/${params.ID}${"with" in params ? "?with=" + params.with : ""}`),
      providesTags: (_r, _e, params) =>
        typeof params === "number"
          ? [{ type: "Workspaces", id: params }]
          : [
              { type: "Workspaces", id: `${params.ID}-${params.with}` },
              { type: "Workspaces", id: params.ID },
            ],
    }),

    createWorkspace: builder.mutation<IWorkspace, Partial<IWorkspace> & { name: string }>({
      query: (body) => ({ method: "POST", url: "mapping/workspaces", body, params: { with: "all" } }),
      invalidatesTags: [{ type: "Workspaces", id: "LIST" }],
    }),

    createWorkspaceClone: builder.mutation<IWorkspace, Pick<IWorkspace, "name" | "ID">>({
      query: ({ name, ID }) => ({ method: "POST", url: `mapping/workspaces/${ID}`, params: { with: "all" }, body: { name } }),
      invalidatesTags: [{ type: "Workspaces", id: "LIST" }],
    }),

    removeUserFromWorkspace: builder.mutation<void, { ID: IWorkspace["ID"]; userID: IWorkspace["userID"] }>({
      query: ({ ID, userID }) => ({ method: "DELETE", url: `mapping/workspaces/${ID}/users/${userID}` }),
      invalidatesTags: (_r, _e, { ID: id }) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id },
      ],
    }),
    removeCollectionFromWorkspace: builder.mutation<void, { ID: IWorkspace["ID"]; collectionID: ICollection["ID"] }>({
      query: ({ ID, collectionID }) => ({ method: "DELETE", url: `mapping/workspaces/${ID}/collections/${collectionID}` }),
      invalidatesTags: (_r, _e, { ID: id }) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id },
      ],
    }),
    updateUsersForWorkspace: builder.mutation<void, { ID: IWorkspace["ID"]; users: IUser["ID"][] }>({
      query: ({ ID, users }) => ({ method: "PUT", url: `mapping/workspaces/${ID}/users`, body: users }),
      invalidatesTags: (_r, _e, { ID: id }) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id: `${id}-all` },
        { type: "Workspaces", id },
      ],
    }),

    /**
     * Perform a partial update on a workspace.
     */
    updateWorkspace: builder.mutation<void, IWorkspaceUpdate>({
      query: (body) => ({ method: "PATCH", url: `mapping/workspaces/${body.ID}`, body }),
      invalidatesTags: (resp, err, body) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id: body.ID },
      ],
      // Apply this update to the cached data immediately so that the UI updates.
      async onQueryStarted({ ID, ...body }, { dispatch, queryFulfilled }) {
        const localResult = dispatch(
          baseApi.util.updateQueryData("getWorkspacesWithMaps", undefined, (workspaces) => workspaces.filter((w) => w.ID == ID).forEach((w) => Object.assign(w, body)))
        );
      },
    }),

    /**
     * Perform a partial update on a map.
     */
    updateMap: builder.mutation<void, IUpdateMap>({
      query: (body) => ({ method: "PATCH", url: `mapping/maps/${body.ID}`, body }),
      invalidatesTags: (resp, err, body) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id: body.workspaceID },
        { type: "MapActions", id: `mapID-${body.ID}` },
        { type: "MapActionTypes", id: `mapID-${body.ID}` },
      ],
      // Apply this update to the map as cached by the getWorkspacesWithMaps query (immediately, so that the UI updates).
      async onQueryStarted({ ID, ...body }, { dispatch, queryFulfilled }) {
        const localResult = dispatch(
          baseApi.util.updateQueryData("getWorkspacesWithMaps", undefined, (workspaces) =>
            workspaces
              .filter((w) => w.ID == body.workspaceID)
              .flatMap((w) => w.maps)
              .filter((m) => m.ID == ID)
              .forEach((m) => Object.assign(m, body))
          )
        );
      },
    }),

    /**
     * Create a new map.
     */
    createMap: builder.mutation<IMap, ICreateMap>({
      query: (body) => ({ method: "POST", url: "mapping/maps", body }),
      invalidatesTags: (resp, err, body) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id: body.workspaceID },
      ],
    }),

    /**
     * Delete a map.
     */
    deleteMap: builder.mutation<void, IMap>({
      query: (body) => ({ method: "DELETE", url: `mapping/maps/${body.ID}` }),
      invalidatesTags: (_r, _e, body) => [
        { type: "Workspaces", id: "LIST" },
        { type: "Workspaces", id: body.workspaceID },
      ],
    }),

    /**
     * Get a map by UUID
     */

    getMapByUUID: builder.query<IMapWithCollections, { UUID: string; mapOptions?: { o?: string[] } }>({
      query: ({ UUID, mapOptions }) => ({
        url: `mapping/maps/${UUID}`,
        params: mapOptions,
        skipEntityId: true,
      }),
      providesTags: (_r, _e, { UUID }) => [{ type: "Maps", id: UUID }],
    }),

    /**
     * Get the actions associated with a map.
     */
    getMapActions: builder.query<IMapAction[], number>({
      query: (mapID) => `mapping/actions?mapID=${mapID}`,
      providesTags: (_r, _e, mapID) => [{ type: "MapActions", id: `mapID-${mapID}` }],
    }),

    /**
     * Get the possible action types for a map.
     */
    getMapActionTypes: builder.query<IMapActionType[], number[]>({
      query: (collectionIDs) => `mapping/action-types?collectionIDs=${collectionIDs.join(",")}`,
      providesTags: (_r, _e, mapID) => [{ type: "MapActionTypes", id: `mapID-${mapID}` }],
    }),

    /**
     * Get any input requirements or validation results before running an action.
     */
    getMapActionPreflight: builder.query<ActionPreflightResponse, ActionPreflightRequest>({
      query: (req) => ({ method: "POST", url: `mapping/actions/${req.actionID}/preflight`, body: req }),
    }),

    /**
     * Invoke a map action.
     */
    invokeMapAction: builder.mutation<ActionInvokeResponse, ActionInvokeRequest>({
      query: (req) => ({ method: "POST", url: `mapping/actions/${req.actionID}/invoke`, body: req }),
    }),

    /**
     * Update a theme.
     */
    updateTheme: builder.mutation<void, ITheme>({
      query: (body) => ({ method: "PUT", url: `mapping/themes/${body.ID}`, body }),
      invalidatesTags: (_r, _e, body) => [
        { type: "Collections", id: "LIST" },
        { type: "Collections", id: body.collectionID },
        { type: "Workspaces", id: body.workspaceID },
      ],
    }),

    /**
     * Create a new theme.
     */
    createTheme: builder.mutation<IThemeWithID, ITheme>({
      query: (body) => ({ method: "POST", url: "mapping/themes", body }),
      invalidatesTags: (_r, _e, body) => [
        { type: "Collections", id: "LIST" },
        { type: "Workspaces", id: body.workspaceID },
      ],
    }),

    /**
     * Delete a map.
     */
    deleteTheme: builder.mutation<void, ITheme>({
      query: (body) => ({ method: "DELETE", url: `mapping/themes/${body.ID}` }),
      invalidatesTags: (_r, _e, body) => [
        { type: "Collections", id: "LIST" },
        { type: "Collections", id: body.collectionID },
        { type: "Workspaces", id: body.workspaceID },
      ],
      async onQueryStarted({ ID, ...body }, { dispatch }) {
        // Remove this theme from the loaded map, if present.
        ID && dispatch(removeMapTheme(ID));
      },
    }),

    /**
     * Search for users by name
     */
    searchUsers: builder.query<IUser[], string>({
      query: (name) => ({ url: `users/search`, params: { name } }),
    }),

    /**
     * Retrieve all report templates.
     */
    getReportTemplates: builder.query<IReportTemplate[], void>({
      query: (dataType) => `reporting/templates`,
      providesTags: [{ type: "ReportTemplates", id: `LIST` }],
    }),

    /**
     * Retrieve all datasources.
     */
    getDataSources: builder.query<IDataSource[], string>({
      query: (dataType) => `reporting/datasources?type=${dataType}`,
      providesTags: (_r, _e, dataType) => [{ type: "DataSources", id: `LIST-${dataType}` }],
    }),

    /**
     * Download all reports for the given map.
     */
    getReports: builder.query<IReport[], number>({
      query: (mapID) => `reporting/reports?mapID=${mapID}`,
      providesTags: (_r, _e, mapID) => [{ type: "Reports", id: `LIST-${mapID}` }],
    }),

    /**
     * Create a new report.
     */
    createReport: builder.mutation<IReport, IReport>({
      query: (body) => ({ method: "POST", url: "reporting/reports", body }),
      invalidatesTags: (resp, err, body) => [
        { type: "Reports", id: "LIST" },
        { type: "Reports", id: `LIST-${body.mapID}` },
      ],
    }),

    /**
     * Update an existing report.
     */
    updateReport: builder.mutation<IReport, IReport>({
      query: (body) => ({ method: "PUT", url: `reporting/reports/${body.ID}`, body }),
      invalidatesTags: (resp, err, body) => [
        { type: "Reports", id: "LIST" },
        { type: "Reports", id: `LIST-${body.mapID}` },
      ],
    }),

    /**
     * Delete an existing report.
     */
    deleteReport: builder.mutation<void, IReport>({
      query: (report) => ({ method: "DELETE", url: `reporting/reports/${report.ID}` }),
      invalidatesTags: (resp, err, report) => [
        { type: "Reports", id: "LIST" },
        { type: "Reports", id: `LIST-${report.mapID}` },
      ],
    }),

    /**
     * Run a report
     */
    runReport: builder.mutation<RunReportResponse, RunReportRequest>({
      query: (body) => ({ method: "POST", url: "reporting/report-runs", body }),
    }),

    validateCSS: builder.mutation<{ parsed?: string; errors?: Record<number, string[]> }, { css: string; label: string | null; fontSize: number }>({
      query: ({ css, ...variables }) => ({
        method: "POST",
        url: "mapping/themes/validate",
        body: {
          css,
          variables,
        },
      }),
    }),

    sendRenderingPreflight: builder.mutation<{ aggregates: Record<string, Record<string, string>> }, PreflightMap>({
      queryFn: async (preflight, api, options, fetch) => {
        try {
          const INSTANCE_ID = getService("INSTANCE_ID");
          const url = `/mapping/maps/preflight/${INSTANCE_ID}/${preflight.UUID}`;

          const { data, error } = await fetch({
            url,
            skipEntityId: preflight.UUID ? true : false,
            params: preflight.UUID ? { entityID: preflight.entityID } : {},
            method: "POST",
            body: {
              map: preflight,
              password: preflight.password,
            },
          });

          if (error) return { error };
          return { data: data as { aggregates: Record<string, Record<string, string>> } };
        } catch (error) {
          console.log({ error });
          return { error: { status: 500, data: error } };
        }
      },
    }),

    getAnnotations: builder.query<IMapAnnotationsResponse, { id: IMap["ID"]; after?: moment.Moment }>({
      query: ({ after, id }) => ({
        url: `mapping/maps/${id}/annotations`,
        params: after ? { after: after.format() } : {},
      }),
    }),
    addAnnotation: builder.mutation<IMapAnnotationsResponse, { id: IMap["ID"]; feature: GeoJSON.Feature }>({
      query: ({ id, feature }) => ({
        url: `mapping/maps/${id}/annotations`,
        method: "POST",
        body: feature,
      }),
    }),
    editAnnotations: builder.mutation<IMapAnnotationsResponse, { id: IMap["ID"]; features: GeoJSON.Feature[] }>({
      query: ({ id, features }) => ({
        url: `mapping/maps/${id}/annotations`,
        method: "PUT",
        body: features,
      }),
    }),
    deleteAnnotations: builder.mutation<IMapAnnotationsResponse, { id: IMap["ID"]; ids: number[]; after: moment.Moment }>({
      query: ({ id, ids, after }) => ({
        url: `mapping/maps/${id}/annotations`,
        method: "DELETE",
        paramsSerializer: () => {
          return "";
        },
        params: {
          ids,
          after: after.format(),
        },
      }),
    }),
    fetchExpiringUUID: builder.mutation<string, { ID: IMap["ID"]; expiresAt: Date }>({
      query: ({ expiresAt, ID }) => ({
        method: "POST",
        url: `mapping/maps/${ID}/expiring-uuid`,
        body: {
          expiresAt: expiresAt.toISOString(),
        },
      }),
    }),
    getNewUUID: builder.mutation<string, IMap["ID"]>({
      query: (ID) => ({
        method: "POST",
        url: `mapping/maps/${ID}/uuid`,
      }),
      invalidatesTags: (_r, _e, ID) => [
        { type: "Maps", id: ID },
        { type: "Workspaces", id: "LIST" },
      ],
    }),
    generatePassword: builder.mutation<string, void>({
      query: (ID) => ({
        method: "GET",
        url: `phrase`,
      }),
    }),
    getAnnotationsOption: builder.query<string, string>({
      query: (date) => ({
        method: "GET",
        url: `hash?in=annotations=on_${date}`,
      }),
    }),
    saveMapPassword: builder.mutation<void, { ID: IMap["ID"]; password: string | null }>({
      query: ({ ID, password }) => ({
        method: "PATCH",
        url: `mapping/maps/${ID}`,
        body: {
          password,
        },
      }),
      invalidatesTags: (_r, _e, body) => [
        { type: "Maps", id: body.ID },
        { type: "Workspaces", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useUpdateUserPreferencesMutation,
  useGetFaqsQuery,
  useGetCollectionQuery,
  useLazyGetCollectionQuery,
  useGetCollectionFeaturesQuery,
  useLazyGetCollectionFeaturesQuery,
  useGetCollectionColumnDefsQuery,
  useGetCollectionsQuery,
  useLazyGetCollectionsQuery,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
  useSaveCollectionAsMasterMutation,
  useGetCollectionAggregatesQuery,
  useGetWorkspacesWithMapsQuery,
  useCreateWorkspaceMutation,
  useCreateWorkspaceCloneMutation,
  useRemoveUserFromWorkspaceMutation,
  useDeleteMapMutation,
  useRemoveCollectionFromWorkspaceMutation,
  useUpdateUsersForWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useGetWorkspaceQuery,
  useLazyGetWorkspaceQuery,
  useUpdateMapMutation,
  useCreateMapMutation,
  useSearchUsersQuery,
  useUpdateThemeMutation,
  useCreateThemeMutation,
  useValidateCSSMutation,
  useGetMapActionsQuery,
  useGetMapActionTypesQuery,
  useGetMapActionPreflightQuery,
  useGetReportTemplatesQuery,
  useGetDataSourcesQuery,
  useGetReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useRunReportMutation,
  useInvokeMapActionMutation,
  useSendRenderingPreflightMutation,
  useLazyGetAnnotationsQuery,
  useAddAnnotationMutation,
  useEditAnnotationsMutation,
  useDeleteAnnotationsMutation,
  useDeleteThemeMutation,
  useFetchExpiringUUIDMutation,
  useGetNewUUIDMutation,
  useGeneratePasswordMutation,
  useGetAnnotationsOptionQuery,
  useSaveMapPasswordMutation,
  useGetMapByUUIDQuery,
  useLazyGetMapByUUIDQuery,
  useGetCollectionsMetaQuery,
  useSaveCollectionMutation,
  useTestCollectionSqlMutation,
} = baseApi;

const filtersToQueryStringObject = (filters: IFilter[]) => {
  const filterObject: { [key: string]: string } = {};
  filters.forEach(({ column, operator, value }, index) => {
    filterObject[`filters[${index}][operator]`] = operator;
    filterObject[`filters[${index}][column]`] = column;
    filterObject[`filters[${index}][value]`] = value.toString();
  });
  return filterObject;
};

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}
