const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

/**
 * Emails.js controller
 */

angular.module("routes:admin").controller("admin:emails", function ($rootScope, $scope, $log, $state, emails, $timeout) {
  store.dispatch(
    updateGlobalState({
      title: "Emails",
      activeSection: "admin.emails",
    })
  );

  $scope.emails = emails;

  $scope.savedEmail = "";

  /**
   * Closes the right side panel
   * @return {void}
   */
  $scope.closeRightSide = function () {
    $state.go("admin.emails");
  };

  /**
   * Makes a request to delete an email
   * @param  {object} email
   * @return {void}
   */
  $scope.deleteEmail = function (email) {
    email.remove().then(function () {});
  };

  /**
   * Archives an email
   * @param  {object} email
   * @return {void}
   */
  $scope.archiveEmail = function (email) {};

  /**
   * Unarchives an email
   * @param  {object} email
   * @return {void}
   */
  $scope.unarchiveEmail = function (email) {};

  $scope.newEmail = function () {
    $state.go("admin.emails.create");
  };

  /**
   * Sets "savedEmail" (used to show a highlight on the email that was changed)
   * @param {integer} ID
   */
  $scope.setSavedEmail = function (ID) {
    $scope.savedEmail = ID;
  };

  /**
   * Watches savedEmail, when it changes, reset it in 1500 ms
   *  This makes the saved email have a brief highlight after being saved;
   *  A bit hacky, and probably needs to become a directive instead
   */
  $scope.$watch("savedEmail", function (savedEmail, oldVal) {
    if (savedEmail == oldVal) return;

    $timeout(function () {
      $scope.setSavedEmail("");
    }, 1500);
  });
});
