/**
 * Top nav controller
 */

const { setEntityID } = require("../../../src/slices/appSlice");
const { store } = require("../../../src/store");

angular.module("routes").controller("top-nav", function ($rootScope, $scope, $state, $stateParams, Session, Restangular) {
  store.subscribe(() => {
    const {
      app: { entityID },
    } = store.getState();
    if (entityID !== $rootScope.entityID) {
      setTimeout(() => {
        $scope.$apply(() => {
          $scope.entityIDDidChange(entityID);
        });
      });
    }
  });

  $rootScope.stateWatch = function (event, toState, toParams) {
    var id;
    if (toParams && "entityID" in toParams) id = toParams.entityID;

    if (!id) id = $stateParams.entityID * 1;

    $scope.entityIDDidChange(id);
  };

  $scope.entityIDDidChange = function (id) {
    if (!id) return;

    var baseState;

    if ($rootScope.entityID && id != $rootScope.entityID && $state.current) baseState = $state.current.name.split(".").slice(0, 2).join(".");

    $rootScope.entityID = parseInt(id);
    Restangular.setDefaultRequestParams({ entityID: id });
    store.dispatch(setEntityID(id));

    if (baseState == "lists.list") baseState = "lists";
    else if (baseState == "dashboard.dashboard") baseState = "dashboard";
    else if (baseState == "mapping.layers") baseState = "mapping";

    if (baseState && baseState != "init.home") {
      if ($scope.menu.access.hasMenuAccess("entity." + id + "." + baseState)) $state.go(baseState, { entityID: id });
      else $state.go("init.home", { entityID: id });
    }

    // fire an event to notify of something that could change permissions
    $rootScope.$broadcast("entitySelected", {});
  };

  $rootScope.$on("$stateChangeStart", $rootScope.stateWatch);
  $rootScope.$on("$stateChangeSuccess", $rootScope.stateWatch);
  $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
    console.error(error);
  });
  $scope.$watch("entityID", $scope.entityIDDidChange);

  $rootScope.$watch("entityID", function (id, oldVal) {
    if (id == oldVal && id != $scope.entityID) return;

    $scope.entityID = id;
  });

  $scope.action = function () {
    Restangular.one("entities").getList();
  };

  $scope.session = Session.getAll();
});
