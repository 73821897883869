angular.module("common").factory("httpInterceptor", function ($rootScope, $location, $q, $injector, API_ROOT, promiseTracker, $timeout) {
  return {
    request: function (config) {
      if (typeof $rootScope.promises == "undefined") $rootScope.promises = [];

      if (typeof $rootScope.deferred == "undefined") $rootScope.deferred = [];

      var deferred = $q.defer();
      $rootScope.deferred.push(deferred);
      $rootScope.promiseTracker = promiseTracker();
      $rootScope.promiseTracker.addPromise(deferred.promise);

      return config;
    },

    response: function (response) {
      $rootScope.deferred.splice(0, 1)[0].resolve();
      return response;
    },

    responseError: function (response) {
      $rootScope.deferred.splice(0, 1);

      if (!response.data) return $q.reject(response);

      //if the code is a 422 (unprocessable entity) return the data and let the form handle it
      if ("code" in response.data.status && response.data.status.code == "422") return $q.reject(response);

      // Create a timeout for the error handler that can be
      //  caught later on and canceled with $timeout.cancel()
      response.modalTimeout = $timeout(
        function () {
          var error = response.data;
          error.currentState = $injector.get("$state").current;
          $rootScope.$broadcast("httpError", { error });
        }.bind(this)
      );

      return $q.reject(response);
    },
  };
});
