import { POLL_TIME } from "../../../constants";

angular.module("modules:mapping").factory("Collection", function (Restangular, $q, $window, $http, Theme, API_ROOT) {
  var Collection = Restangular.all("mapping/collections");

  // add methods to single collections
  Restangular.addElementTransformer("mapping/collections", false, function (collection) {
    if (!("one" in collection)) return collection;

    collection.getFeatures = function (filters, paginated, UUID) {
      var parameters = {},
        url;
      if (filters) parameters.filters = filters;
      if (paginated) parameters.limit = 10;
      if (UUID) {
        url = "../" + UUID + "-" + collection.ID + "/features?";
      } else {
        url = "features?";
      }
      return collection.customGETLIST(url + $.param(parameters));
    };

    collection.getFeaturesPaginated = function (parameters) {
      // parameters.filters = collection.filters
      return collection.customGETLIST("features?" + $routeParams(parameters));
    };

    /**
     * Gets the collection theme either from the collection or gets the default theme
     * @param  {object}  map              the map object
     * @param  {boolean} [preferMapLabel=true] whether to prefer the map label over the theme label
     * @return {object}                   the theme
     */
    collection.findTheme = function (map, preferMapLabel = true) {
      var theme = _.find(this.themes, { ID: map.themes[this.ID] });

      // If a theme isn't set on the map, grab the collection's first
      if (!theme && this.themes) theme = this.themes[0];

      // still nothing? use defaults
      if (!theme) theme = Theme.getDefaultTheme(this, map.workspaceID, "Default");

      // Copy the theme, so the original isn't modified
      theme = Restangular.copy(theme);

      // If there's a label set at the map level,
      // change the label, and return the modified theme
      if (typeof map.labels[this.ID] != "undefined" && preferMapLabel) theme.label = map.labels[this.ID];

      return theme;
    };

    /**
     * Toggle whether this import is archived
     * @return {void}
     */
    collection.toggleArchived = function () {
      this.archived = !this.archived;
      return this.patch({ archived: this.archived });
    };

    /**
     * Save a collection by posting or patching. The request is queued for processing on the backend,
     * so we poll to get the result.
     * @param  {array} fileIDs
     * @return {objects} a promise
     */
    collection.save = function (fileIDs) {
      if (fileIDs && fileIDs.length) collection.files = fileIDs;

      var promise;

      if (!("ID" in collection)) promise = collection.post();
      else promise = collection.patch();

      // Repeatedly ask for an update
      function poll(requestID) {
        var params = { requestID: requestID, poll: POLL_TIME };

        return Collection.one("status")
          .get(params)
          .then(function (response) {
            if (response == null) return poll(requestID);

            return response;
          });
      }

      return promise.then(function (response) {
        return poll(response.requestID);
      });
    };

    if (collection.themes) {
      for (var i = 0; i < collection.themes.length; i++) {
        Restangular.restangularizeElement(null, collection.themes[i], "mapping/themes");
      }
    }

    return collection;
  });

  // add a custom methods to collections of collections
  Restangular.addElementTransformer("mapping/collections", true, function (collections) {
    // don't try to work on uninitialized collection
    if (!("one" in collections)) return collections;

    collections.addRestangularMethod("search", "getList", "search");

    return collections;
  });

  Collection.types = require("../../../../src/types/CollectionTypes").CollectionTypes;
  Collection.userDefinedPropertyTypes = [{'label':'Text', 'type':'text'}, {'label':'Integer', 'type':'int8'}, {'label':'Decimal', 'type':'numeric'}];

  Object.freeze(Collection.types);

  Collection.create = function (entityID) {
    var collection = {
      type: "master",
      indexes: [],
      entityID: entityID,
      sql: "",
      aggSql: "",
    };

    Restangular.restangularizeElement(null, collection, Collection.route);
    return collection;
  };

  Collection.saveAsMaster = function (collection, name) {
    if (collection.type != "transform") throw new TypeError(`${collection.name} is not a transformation layer`);

    return Collection.customPOST({ name }, `${collection.ID}`);
  };

  Collection.testSql = function (sql, entityID) {
    return $http.post(API_ROOT + "/" + Collection.route + "/test", { sql: sql }, { params: { entityID: entityID } }).then(function (response) {
      return response.data.data;
    });
  };

  return Collection;
});
