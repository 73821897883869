/**
 * @name Entity
 * @ngdoc service
 * @description A Restangular service for entities
 * @memberOf modules
 */
angular.module("modules").factory("Entity", function (Restangular) {
  var Entity = Restangular.all("entities");

  Entity.addRestangularMethod("getSummary", "getList", "summary");

  // add a custom users method
  Restangular.addElementTransformer("entities", false, function (entity) {
    if (!("one" in entity)) return entity;

    // assign users
    entity.addRestangularMethod("putUsers", "put", "users");

    // set permissions
    entity.addRestangularMethod("putPermissions", "put", "permissions");

    // get user IDs
    entity.userIDs = function () {
      var IDs = [];
      for (var i = 0; i < this.users.length; ++i) {
        IDs.push(this.users[i].ID);
      }

      return IDs;
    };

    entity.userIDsWithout = function (ID) {
      var IDs = [];
      for (var i = 0; i < this.users.length; ++i) {
        if (this.users[i].ID != ID) IDs.push(this.users[i].ID);
      }

      return IDs;
    };

    /**
     * Get email addresses from which an entity can send email
     * @param  {bool} activated Limit to activated addresses
     * @return {object} promise
     */
    entity.getEmailList = function (activated) {
      var params = { entityID: entity.ID };
      if (typeof activated != "undefined") params.activated = activated;

      var promise = entity.customGET("emails", params);
      promise.then(function (response) {
        Restangular.restangularizeElement(null, response, "entities/" + entity.ID + "/emails");
        return response;
      });
      return promise;
    };

    return entity;
  });

  // add a custom methods to collection
  Restangular.addElementTransformer("entities", true, function (collection) {
    // don't try to work on uninitialized collection
    if (!("one" in collection)) return collection;

    collection.addRestangularMethod("search", "getList", "search");

    return collection;
  });

  Entity.getEmailList = function (entityID, activated) {
    // Create a new entity with just the entityID
    var entity = Restangular.restangularizeElement(null, { ID: entityID * 1 }, "entities");

    // Then return the email list (promise)
    return entity.getEmailList(activated);
  };

  return Entity;
});
