const { updateGlobalState } = require("../../../../src/slices/appSlice");
const { store } = require("../../../../src/store");

/**
 * Create a new entity
 */

angular.module("routes:admin").controller("admin:create-entity", function ($rootScope, $scope, $log, $state, Entity) {
  store.dispatch(updateGlobalState({ title: "Create Entity" }));
  $scope.entity = {};
  $scope.processing = false;

  $scope.update = function (entity) {
    var form = $scope.validateForm("entityForm");
    if (form.isValid()) {
      $scope.processing = true;
      Entity.post(entity)
        .then(
          function (response) {
            $scope.entities.push(response);
            $state.go("^");
          },
          function (errors) {
            $scope.errors = response.data.meta.errors;
          }
        )
        .finally(function () {
          $scope.processing = false;
        });
    }
  };
});
