import React from "react";
import { ICollection } from "../../types/ICollection";
import { useGetCollectionAggregatesQuery } from "../../slices/apiSlice";
import { SelectedFeatures } from "./MapSelectionActionsBar";

interface MapSelectionSummaryProps {
    selectedFeatures: SelectedFeatures;
    collections: ICollection[];
}

interface LayerSummaryProps {
  selection: {[key: string]: any};
  collection: ICollection;
}

const LayerSummary: React.FC<LayerSummaryProps & JSX.IntrinsicAttributes> = ({collection, selection}: LayerSummaryProps ) => {
  const ids = Object.keys(selection);
  const { data: values } = useGetCollectionAggregatesQuery({ID: collection.ID, ids});

  return <>
    <h3>{collection.name} ({ids.length})</h3>
    <table>
      <tbody>
      {(collection.aggregates || []).map(field => typeof field == 'string' ? {name: field, column: field, type: ''} : field).map(field => (
        <tr key={field.column}>
          <td>{field.name}:</td>
          <td>{values && values[field.column]}</td>
        </tr>
      ))}
      </tbody>
    </table>
  </>;
}

export const MapSelectionSummary: React.FC<MapSelectionSummaryProps & JSX.IntrinsicAttributes> = ({selectedFeatures, collections}) => {
  
  return <>
    <div className="selection-summary">
    {collections.filter(c => c.tableName in selectedFeatures).map(c => (
      <LayerSummary key={c.ID} collection={c} selection={selectedFeatures[c.tableName]} />
    ))}
    </div>
  </>;
};