export default angular.module("common").factory("Menu", function ($rootScope, Session, PermissionManager) {
  var routes = [
    // array of routes
    {
      id: "mapping",
      name: "TruTerritory",
      sections: [],
    },
    {
      id: "data",
      name: "Data",
      sections: [],
    },
    {
      id: "admin",
      name: "Admin",
      visible: false,
      sections: [
        {
          text: "Manage",
          routes: [
            {
              id: "admin.users-entities",
              sref: "admin.users-and-entities",
              name: "Users & Entities",
            },
            {
              id: "admin.emails",
              sref: "admin.emails",
              name: "System Emails",
            },
          ],
        },
      ],
    },
  ];

  return new Menu(routes, new PermissionManager(Session.getAll().permissions), $rootScope.entityID);
});

var Menu = function (routes, access, entityID) {
  this._routes = routes;
  this.setPermissionsAndEntity(access, entityID);
};

Menu.prototype = {
  get: function (parent) {
    var routes = [];

    // get the top-level menu
    if (!parent) routes = this._routes;
    // OR get the sections under a top-level item (parent)
    else {
      for (var i = 0; i < this._routes.length; i++) {
        if (this._routes[i].id == parent) {
          routes = this._routes[i].sections;
          break;
        }
      }
    }

    return routes;
  },

  getEnabled(parent) {
    return _.filter(this.get(parent), { enabled: true });
  },

  /**
   * Finds the sref for a given permission
   * @param  {string} id the full permission string (i.e., "entity.1.dashboard.mapping")
   * @return {null|object}
   */
  findSref: function (id) {
    // Split the permission on ".",
    //  find the section that it belongs to
    var fullPermission = id.split("."),
      permission = fullPermission.slice(2, fullPermission.length).join("."),
      routeSection = _.find(this._routes, { id: fullPermission[2] });

    // If this is a top level section (dashboard, data, tools, etc…return the route)
    if (fullPermission.length == 3) {
      return routeSection;
    }

    // Loop through all the sections in each parent section and find the route!
    for (var i = routeSection.sections.length - 1; i >= 0; i--) {
      var route = _.find(routeSection.sections[i].routes, { id: permission });
      if (route) return route;
    }
    return null;
  },

  setPermissionsAndEntity: function (access, entityID) {
    this.access = access;
    this._entityID = entityID;

    // rebuild menu (i.e., set 'enabled' on each item)
    this._applyPermissions();
  },

  getFirstRoute: function () {
    var returnRoute = {
      route: "dashboard",
      params: { entityID: this._entityID },
    };

    for (var i = 0; i < this._routes.length; i++) {
      if (("visible" in this._routes[i] && !this._routes[i].visible) || !this._routes[i].enabled) continue;

      var route = this._routes[i];
      for (var j = 0; j < route.sections.length; j++) {
        var section = route.sections[j];
        for (var k = 0; k < section.routes.length; k++) {
          if (!section.routes[k].enabled) continue;

          returnRoute.route = section.routes[k].sref;
          return returnRoute;
        }
      }

      returnRoute.route = route.id;
      return returnRoute;
    }

    returnRoute.params.noAccess = true;
    return returnRoute;
  },

  _applyPermissions: function () {
    // for each item, check granted access
    var primary, section, secondary;
    for (var i = 0; i < this._routes.length; i++) {
      primary = this._routes[i];
      primary.enabled = this.access.hasMenuAccess("entity" + "." + this._entityID + "." + primary.id); //this._hasAccess(primary.id);

      // go through secondary menus as well
      for (var j = 0; j < primary.sections.length; j++) {
        section = primary.sections[j];
        for (var k = 0; k < section.routes.length; k++) {
          secondary = section.routes[k];
          secondary.enabled = this.access.hasMenuAccess("entity" + "." + this._entityID + "." + secondary.id); //this._hasAccess(secondary.id);
        }
      }
    }
  },
};
