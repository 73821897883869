angular.module("common").directive("offClick", function ($document, $parse) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var offClick = $parse(attrs.offClick);

      function clickHandler(event) {
        if (event.target != element.get(0) && !element.find(event.target).length) {
          scope.$apply(function () {
            offClick(scope);
          });
        }
      }

      $document.bind("click", clickHandler);

      // When the scope is destroyed, unbind the listener
      scope.$on("$destroy", function () {
        $document.unbind("click", clickHandler);
      });
    },
  };
});
