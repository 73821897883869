/// <reference path="../../../node_modules/@types/jquery/JQuery.d.ts" />

import angular, { IScope } from "angular";

angular.module("common").directive("clipboard", function () {
  return {
    restrict: "A",
    transclude: true,
    replace: true,
    templateUrl: "/directives/clipboard.html",
    scope: {
      content: "=clipboard",
      rows: "@",
    },
    link: function (scope: IScope & { rows?: number; content: string; showUnsupportedCopy?: boolean }, element: JQuery) {
      scope.rows = typeof scope.rows == "undefined" ? 5 : scope.rows;

      const showFallbackCopy = () => {
        scope.$apply(() => {
          scope.showUnsupportedCopy = !scope.showUnsupportedCopy;
          if (scope.showUnsupportedCopy) element.children("textarea").trigger("focus").select();
        });
      };

      const showSuccessfulTooltip = () => {
        element.find(".tooltip").addClass("in");
        setTimeout(function () {
          element.find(".tooltip").removeClass("in");
        }, 1000);
      };

      element.children("[ng-transclude]").css({ width: 0, height: 0 });
      element
        .children("[ng-transclude]")
        .children()
        .first()
        .on("click", () => {
          if (navigator.clipboard) {
            navigator.clipboard.writeText(scope.content).then(showSuccessfulTooltip, showFallbackCopy);
          } else {
            showFallbackCopy();
          }
        });
    },
  };
});
