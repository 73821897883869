/**
 * Service for geocoding addresses
 */

angular.module("common").factory("GoogleGeocoder", function ($http, $q, GEOCODE_KEY) {
  var Geocoder = {};

  Geocoder.getLatLong = function (address) {
    return makeRequest(address)
      .then(resp => ({location: resp.data.results[0].geometry.location, address: resp.data.results[0].formatted_address}));
  };

  function makeRequest(address) {
    return $http.get("https://maps.googleapis.com/maps/api/geocode/json", { params: { address: address, key: GEOCODE_KEY }, withCredentials: false });
  }

  return Geocoder;
});
