/**
 * @description Directive for editing SQL
 *
 * @ngdoc directive
 * @name  sqlEditor
 * @memberOf routes:mapping
 * @restrict C
 * @scope true
 * @param {string} sql-model An SQL string model
 * @param {object} sql-results An object to receive validation results.
 * @param {array} sql-results.properties Will put a listing of properties (column names, types, etc) here.
 * @param {array} sql-results.featureColumns Will put a listing of just column names here (overlaps with properties)
 * @param {boolean} sql-results.success True if the SQL passed validation, false if it didn't.
 *
 * @example <div class="sql-editor" sql-model="someSQL" sql-results="someObjectYouWantResultsIn"></div>
 */

angular.module("routes:mapping").directive("sqlEditor", function ($stateParams, $timeout, Collection) {
  return {
    restrict: "C",
    templateUrl: "/truterritory/sql-editor.html",
    scope: {
      sql: "=sqlModel",
      results: "=sqlResults",
    },
    controller: function ($scope) {
      if (!$scope.sql) $scope.sql = "";

      $scope.results = { success: true };
      $scope.codeMirrorOptions = { mode: "text/x-pgsql" };
      $scope.testing = false;

      $scope.test = function () {
        $scope.testing = true;
        return Collection.testSql($scope.sql, $stateParams.entityID)
          .then(
            function (data) {
              $scope.errors = null;
              $scope.results.success = $scope.showSuccess = true;
              $scope.results.explain = data.explain;
              $timeout(function () {
                $scope.showSuccess = false;
              }, 5000);
            },
            function (error) {
              $scope.results.success = false;
              $scope.results.explain = null;
              $scope.errors = error.data.status.message;
            }
          )
          .finally(function () {
            $scope.testing = false;
          });
      };
    },
  };
});
